import { useState } from "react";

const planMap = {
    "free": "免費",
    "basic": "基本",
    "enterprise": "企業",
}

export default function Payment({currentPlan}) {

    return (
        <div
            className="px-4 py-5 sm:p-6"
        >
            <div
                className="flex justify-between"
            >
                <h3 className="text-base font-semibold leading-6 text-gray-900">計費</h3>
                <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                    修改
                </button>
            </div>
            <br></br>
            {/* current plan */}
            <div
                className="flex justify-between"
            >
                <h3 className="text-sm font-semibold leading-6 text-gray-900 ">目前計劃</h3>
                <span className="text-sm text-gray-500">{planMap[currentPlan]}</span>
            </div>


        </div>
    )
}