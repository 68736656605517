import { ChevronRightIcon } from '@heroicons/react/20/solid'
import ApplicationShell from '../../Components/ApplicationShell';
import { useState, useEffect, useCallback } from 'react';
import GlobalVars from '../../Config';
import SlideOvers from '../../Components/SlideOvers';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function LocationList({handleLocationEdit, locations, setLocations, setHeadingMsg}) {
    // set page title to 用戶
    document.title = '地點';
    
    // pagination 
    const [page, setPage] = useState(1);

    // detect scroll to bottom and load more data
    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop + 10 >= document.documentElement.offsetHeight) {
            setPage(prevPage => prevPage + 1);
        }
    }, [setPage]);

    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 200);
        window.addEventListener('scroll', debouncedHandleScroll);
        return () => {
            window.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, [handleScroll]);

    // fetch data from backend
    useEffect(() => {
        QueryLocations({setlocations: setLocations, page});
    }, [page]);
    
    // handle location deletion 
    const handleLocationDeletion = (locationID) => {
        // prompt user for confirmation
        let confirmation = window.confirm("確定要刪除此地點嗎？");
        if (confirmation === false) {
            return;
        }

        // create DELETE request to backend
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/locations?locationID=' + locationID;
        
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': sessionStorage.getItem('idToken'),
            },
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                alert("刪除成功");
                // query locations again
                QueryLocations({setlocations: setLocations});
            })
            .catch((error) => {
                alert("刪除失敗");
                console.error('Error:', error);
            });

    }

    const handleLocationStatusChange = (locationID, location) => {
        


        // prompt user for confirmation
        let confirmation = window.confirm("確定要變更此地點的啟用狀態嗎？");
        if (confirmation === false) {
            return;
        }

        // create PUT request to backend
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/locations/manage-status';

        let submitBody = {
            locationID: locationID,
        };

        // check if location already has active status or not 
        console.log(!location.hasOwnProperty('isActive'));
        console.log(location.hasOwnProperty('isActive') && location.isActive);
        if (!location.hasOwnProperty('isActive') || (location.hasOwnProperty('isActive') && location.isActive)) {
            submitBody['isActive'] = false;
        } else {
            submitBody['isActive'] = true;
        }
        
        fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': sessionStorage.getItem('idToken'),
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(submitBody)})
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                alert("變更成功");
                // query locations again
                QueryLocations({setlocations: setLocations});
            })
            .catch((error) => {
                alert("變更失敗");
                console.error('Error:', error);
            });


    }
  

    return (
        <ul role="list" className="divide-y divide-gray-100 dynamic-comp">
            {locations.map((location) => (
                <li key={location.id} className="skip-trans flex items-center justify-between gap-x-6 py-5">
                <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900 skip-trans">{location.name}</p>
                    {/* <p
                        className={classNames(
                        statuses[location.status],
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                        )}
                    >
                        {location.status}
                    </p> */}
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500 dynamic-comp">
                        {/* <p className="whitespace-nowrap skip-trans">
                            {location._id}
                        </p> */}
                        <p>
                            {(location.hasOwnProperty('isActive') && !location.isActive) ? 
                                (<span
                                className="text-red-500"
                                >已停用</span>)
                            : 
                                (<span
                                className="text-green-500"
                                >啟用</span>)}
                        </p>
                    </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                    {/* <a
                    href={location.href}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                    >
                    View location<span className="sr-only">, {location.name}</span>
                    </a> */}
                    <Menu as="div" className="relative flex-none">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className=" dynamic-comp absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {/* <Menu.Item>
                            {({ active }) => (
                            <a
                                href="#"
                                className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                            >
                                Edit<span className="sr-only">, {location.name}</span>
                            </a>
                            )}
                        </Menu.Item> */}
                        <Menu.Item>
                            {({ active }) => (
                            <a
                                onClick={() => handleLocationEdit(location._id)}
                                className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                )}
                            >
                                修改<span className="sr-only">, {location.name}</span>
                            </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                            <a
                                onClick={() => handleLocationStatusChange(location._id, location)}
                                className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                )}
                            >
                                啟用/停用<span className="sr-only">, {location.name} </span>
                            </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                            <button
                                href="#"
                                onClick={() => handleLocationDeletion(location._id)}
                                className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                                )}
                            >
                                刪除<span className="sr-only">, {location.name}</span>
                            </button>
                            )}
                        </Menu.Item>
                        </Menu.Items>
                    </Transition>
                    </Menu>
                </div>
                </li>
            ))}
        </ul>
    );
}

// fetch location data from backend 
function QueryLocations({setlocations, page}) {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/locations?organizationID=' + sessionStorage.getItem("organizationId");

    if (page) {
        url += '&page=' + page;
    }

    fetch(url, {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
        .then(response => response.json())  
        .then(data => {
            // add show/hide status to each location with default show
            data['locations'].forEach((location) => {
                location['show'] = true;
            });
            setlocations(data['locations']);
        });
}

// query for users latest status
// function QueryUserLatestStatus({setlocations}, locations) {
//     let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users/latest-status?organizationID=' + sessionStorage.getItem("organizationId");

//     fetch(url)
//         .then(response => response.json())  
//         .then(data => {
//             let statuses = data['statuses'];

//             // update locations state by matching assignedTo in statuses and _id in locations
//             locations.forEach((location) => {
//                 statuses.forEach((status) => {
//                     if (location['_id'] === status['assignedTo']) {
//                         location['location'] = status['locationName'];
//                         location['lastSeenDateTime'] = status['latestStatus']['changedAt'];

//                         // if it's within 3 hours, show difference in hours, but if it's within 1 hour, show difference in minutes
//                         let now = new Date();
//                         let lastSeen = new Date(location['lastSeenDateTime']);
//                         let diff = now - lastSeen;
//                         let diffHours = Math.round(diff / 1000 / 60 / 60);
//                         let diffMinutes = Math.round(diff / 1000 / 60);
//                         console.log(diffHours, diffMinutes);
//                         if (diffHours <= 3 && diffHours >= 1) {
//                             location['lastSeen'] = diffHours + ' 小時前';
//                         } else if (diffMinutes <= 60) {
//                             location['lastSeen'] = diffMinutes + ' 分鐘前';
//                         } else {
//                             location['lastSeen'] = null;
//                         }
                       
//                     }

//                 });
//             });

//             // update locations state
//             setlocations(locations);
//         });
// }

// new location form
function NewLocation({name, address, setName, setAddress, addressDetail, setAddressDetail}) {

    return (
        <>
            <label 
            htmlFor="locationName"
            className='block text-sm font-medium text-gray-700'
            >
                地點名稱*
                :</label>
            <input 
            type="text" 
            id="locationName" 
            value={name} 
            onChange={(e) => setName(e.target.value)}
            className={`mt-1 selection:marker:block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required 
            />

            <label 
            htmlFor="address"
            className='block text-sm font-medium text-gray-700 mt-4'
            >詳細地址:
            </label>
            <input 
            type="text" 
            id="address" 
            value={address} 
            onChange={(e) => setAddress(e.target.value)}
            className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
            required />
            <label 
                htmlFor="tcName"
                className='block text-sm font-medium text-gray-700 mt-4'
            >中文名稱:
            </label>
            <input 
                type="text" 
                id="tcName" 
                value={addressDetail['tc']}
                onChange={(e) => setAddressDetail({...addressDetail, tc: e.target.value})}
                className={`mt-1 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-insering-gray-300 placeholder:text-gray-300 text-gray-900 focus:ring-gray-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ring-gray-500`}
                required 
            />
        </>
    )
}

// Debounce function
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

// main function 
export default function LocationListPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarTitle, setSidebarTitle] = useState('新增地點');
    const [newName, setNewName] = useState('');
    const [newAddress, setNewAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState({
        tc: ''
    });
    const [locationList, setLocationList] = useState([{
        name: 'Leslie Alexander',
        email: 'leslie.alexander@example.com',
        role: 'Co-Founder / CEO',
        imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        href: '#',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    }]);
    const [headingMsg, setHeadingMsg] = useState('');

    // edit mode 
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);

    // open sidebar and populate info and set editMode
    const handleLocationEdit = (locationID) => {       
        // set sidebar title
        setSidebarTitle('修改地點');
        setEditMode(true);
        setEditId(locationID);

        // get location info
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/locations?locationID=' + locationID;

        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
            .then(response => response.json())  
            .then(data => {
                let location = data['location'];
                setNewName(location['name']);

                let address = ""
                
                if (location.hasOwnProperty('meta')) {
                    if (location['meta'].hasOwnProperty('address')) {
                        address = location['meta']['address'];
                    }
                }
                setNewAddress(address);

                let chiName = "";
                if (location.hasOwnProperty('langVar')) {
                    if (location['langVar'].hasOwnProperty('tc')) {
                        chiName = location['langVar']['tc'];
                    }
                }
                setAddressDetail({
                    ...address, 
                    tc: chiName
                });

            });

        // open sidebar
        setSidebarOpen(true);
        
    }

    // check for url argument of id and invoke handleLocationEdit with id
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const locationID = urlParams.get('id');
        if (locationID && locationID !== "") {
            handleLocationEdit(locationID);
        }
    }, []);


    // submit new location
    const submitNewLocation = (e) => {
        e.preventDefault();

        // get organization id
        const organizationID = sessionStorage.getItem("organizationId");

        // create submittion data
        let apiMethod = 'POST';
        let data = {};
        if (editMode) {
            apiMethod = 'PUT';
            data = {
                locationID: editId,
                name: newName,
                meta: {
                    address: newAddress
                },
                langVar: {
                    tc: addressDetail.tc
                },
            }

        } else {
            data = {
                name: newName,
                meta: {
                    address: newAddress
                },
                organizationID: organizationID,
                langVar: {
                    tc: addressDetail.tc
                },
            }

        }

        // post data to backend
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/locations';
        fetch(url, {
            method: apiMethod,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('idToken'),
            },
            body: JSON.stringify(data),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                if (editMode) {
                    alert("修改成功");
                } else {
                    alert("新增成功");
                }
                
                setSidebarOpen(false);
                
                // query locations again
                QueryLocations({setlocations: setLocationList});
            })
            .catch((error) => {
                alert("新增失敗");
                console.error('Error:', error);
            });
    }

    // filter existing locations 
    const [queryText, setQueryText] = useState('');
    const queryAction = (txt) => {
        let results = 0;
        // Create a new array with updated locations
        const updatedLocations = locationList.map((location) => {
            if (location.name.includes(txt)) {
                results++;
                return {...location, show: true};
            } else {
                return {...location, show: false};
            }
        });

        // update locationList state
        setLocationList(updatedLocations);

        console.log("results: ", results);
    };

    return (
        <>
            <ApplicationShell queryText={queryText} setQueryText={setQueryText} queryAction={queryAction} >
                <div className="md:flex md:items-center md:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        地點列表
                        </h2>
                    </div>
                    <div className="mt-4 flex md:ml-4 md:mt-0">
                        {/* <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                        Edit
                        </button> */}
                        <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                            setSidebarOpen(true)
                            setSidebarTitle('新增地點');
                            
                        }}
                        >
                        新增
                        </button>
                    </div>
                </div>
                <div className="md:flex md:items-center md:justify-between">
                    <p className="text-sm text-gray-600 mt-1">{headingMsg}</p>
                </div>
                <LocationList handleLocationEdit={handleLocationEdit} locations={locationList} setLocations={setLocationList} setHeadingMsg={setHeadingMsg} />
            </ApplicationShell>
            <SlideOvers setOpen={setSidebarOpen} open={sidebarOpen} title={sidebarTitle} content={<NewLocation name={newName} address={newAddress} setName={setNewName} setAddress={setNewAddress} addressDetail={addressDetail} setAddressDetail={setAddressDetail} />} submitAction={submitNewLocation} />
        </>
        
    )
}
