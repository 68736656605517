// import libraries
import PreviewSelectedOptions from "../PreviewSelectedOption";
import { useEffect, useState, useRef } from "react";
import Select from "react-select";

export default function JobTypeComponent({
    typeList,
    types,
    setTypes,
    setIsUsedAISuggestion
}) {
    const [searchResult, setSearchResult] = useState([]);
    const searchResultRef = useRef(searchResult);

    const filterOptions = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    const [displayTypeList, setDisplayTypeList] = useState([]);
    useEffect(() => {
        // group typeList by type. If type is undefined, group it as "其他"
        const grouped = typeList.reduce((acc, curr) => {
            const groupName = curr.type || "其他";
            
            // Find existing group or create new one
            let group = acc.find(g => g.label === groupName);
            if (!group) {
                group = { label: groupName, options: [] };
                acc.push(group);
            }
            
            // Add current item to group's options
            group.options.push({
                ...curr,
                value: curr.id || curr.value,
                label: curr.name || curr.label
            });
            
            return acc;
        }, []);

        setDisplayTypeList(grouped);
    }, [typeList]);

    

    return (
        <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
                工作要求
            </label>
            <p
            className='text-xs text-red-500'
            >必填欄</p>
            <div className="mt-2">
                <Select
                    closeMenuOnSelect={false}
                    options={displayTypeList}
                    isMulti
                    className="text-sm cursor-pointer"
                    value={types}
                    onChange={(value) => {
                        setTypes(value);
                        setIsUsedAISuggestion(false);
                    }}
                    placeholder="選擇工作"
                    filterOption={filterOptions}
                />
                {/* info tag that show how many seleceted */}
                <p className="text-xs text-gray-500 mt-1 flex items-center">
                <div
                    className='ml-1'
                >
                    <PreviewSelectedOptions options={types} />
                </div>
                </p>
            </div>
            <p className="mt-2 text-sm text-red-500 hidden" >
                不能為空
            </p>
        </div>
    );
}