import { useEffect, useState } from "react"
import GlobalVars from "../../../../Config";
export default function SubModuleSelection({typeID}) {
    const [modules, setModules] = useState([]);

    useEffect(() => {
        // query for modules available for this organization
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules?organizationID=" + localStorage.getItem("organizationId");
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: sessionStorage.getItem("idToken"),
            },
        })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Something went wrong');
            }
        })
        .then((data) => {
            setModules(data);
            console.log(data);

            // query for modules associated with this type
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/type-module-association?typeID=" + typeID;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("idToken"),
                },
            })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Something went wrong');
                }
            })
            .then((data) => {
                console.log(data);
                setModules(prevModules => 
                    prevModules.map(module => {
                        if (data.includes(module['_id'])) {
                            return { ...module, associated: true };
                        }
                        return { ...module, associated: false };
                    })
                );
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        })
        .catch((error) => {
            console.error('Error:', error);
        });

    }, [])

    const handleModuleSelection = (e) => {
        let moduleId = e.target.getAttribute('data-module-id');
        let selected = e.target.checked;

        // set the state
        setModules(prevModules => 
            prevModules.map(module => {
                if (module['_id'] === moduleId) {
                    return { ...module, associated: selected };
                }
                return module;
            })
        );

        // select methods
        let method = "POST";
        if (!selected) {
            method = "DELETE";
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/type-module-association";
        fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: sessionStorage.getItem("idToken"),
            },
            body: JSON.stringify({
                typeID: typeID,
                moduleID: moduleId,
                organizationID: localStorage.getItem("organizationId")
            })
        })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Something went wrong');
            }
        })
        .then((data) => {
            console.log(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }


    return (
        <>
            <label
                htmlFor="submodule"
                className="block text-sm font-medium text-gray-700"
            >子模組</label>
            <p
                className='text-xs text-gray-500 mb-2'
            >選擇需要的子模組, 模組將會在工作資料頁面顯示</p>
            <fieldset className="border-b border-gray-200">
                <legend className="sr-only">Notifications</legend>
                <div className="divide-y divide-gray-200">
                    {modules.map((module) => {
                        return (
                            <div className="relative flex items-start pb-4 pt-3.5">
                                <div className="min-w-0 flex-1 text-sm leading-6">
                                <label htmlFor="comments" className="font-medium text-gray-900">
                                    {module['langVar']['tc'] ? module['langVar']['tc'] : module['name']}
                                </label>
                                <p id="comments-description" className="text-gray-500">
                                    {module['desc']}
                                </p>
                                </div>
                                <div className="ml-3 flex h-6 items-center">
                                <input
                                    data-module-id={module['_id']}
                                    id="comments"
                                    name="comments"
                                    type="checkbox"
                                    aria-describedby="comments-description"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    onClick={handleModuleSelection}
                                    checked={module['associated']}
                                />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </fieldset>
        </>
    )
  }


  