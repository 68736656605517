import { useState } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

// components
import Leave from './Components/Leave'
import LeaveAction from './Components/LeaveAction'

export default function CalendarSlideOver({title, open, setOpen}) {

  const options = [
    {"key": "請假", "value": "leave"}
  ]
  const [selectedComponent, setSelectedComponent] = useState(options[0].value);
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">{title}</DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    {/* dropdown menu for selecting component */}
                    <DropdownMenu
                      selectedComponent={selectedComponent}
                      setSelectedComponent={setSelectedComponent}
                      options={options}
                    />
                    <hr className="my-4" />
                    {/* body component */}
                    <BodyCompo 
                      selectedComponent={selectedComponent}
                      setSelectedComponent={setSelectedComponent}
                    />
                  </div>
                </div>
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                  >
                    取消
                  </button>
                  <LeaveAction 
                    setOpen={setOpen}
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

function BodyCompo({selectedComponent, setSelectedComponent}) {
  if (selectedComponent === 'leave') {
    return <Leave />
  }
}


function DropdownMenu({selectedComponent, setSelectedComponent, options}) {
  return (
    <div className="">
      <label htmlFor="component" className="block text-sm font-medium leading-6 text-gray-900">日程類別</label>
      <select 
        value={selectedComponent} 
        onChange={(e) => setSelectedComponent(e.target.value)}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.key}</option>
        ))}
      </select>
    </div>
  )
}
