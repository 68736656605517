import GlobalVars from '../../../../Config';
import { useState, useEffect } from 'react';
import Select from 'react-select';

export default function Filters({filters, setFilters}) {
    const [staff, setStaff] = useState(null);

    // query staff
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + localStorage.getItem("organizationId") + "&status=active"
        fetch(url)
            .then(response => response.json())
            .then(rawData => {
                let data = rawData['users'].map(item => ({
                    value: item._id,
                    label: item.displayName
                }))
                setStaff(data);
            })
            .catch(error => {
                console.error('Error fetching staff:', error);
            })
    }, [])


    return (
        <div
            className="flex items-center w-full"
        >
            <Select
                options={staff}
                className="w-50 text-sm max-w-50"
                onChange={(selectedOption) => setFilters({...filters, userIDs: selectedOption.map(item => item.value)})}
                isMulti={true}
                placeholder="選擇員工..."
            />
            <Select
                options={[]}
                className="ml-2 w-50 text-sm max-w-50"
                isMulti={true}
                placeholder="選擇員工組別..."
            />
        </div>
    )
}