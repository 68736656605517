// import library
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
    InformationCircleIcon
} from '@heroicons/react/24/outline';


export default function PreviewSelectedOptions({options}) {

    return (
      <Menu as="div" className="relative text-left flex">
        <div>
          <MenuButton className="flex items-center rounded-full bg-white text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span>按此查看已選項目</span>
            <InformationCircleIcon aria-hidden="true" className="h-5 w-5" />
          </MenuButton>
        </div>
        
        <MenuItems
          transition
          className="overflow-y-scroll max-h-[150px] absolute left-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {options.length === 0 ? (
              <MenuItem>
                <span
                  className='px-4 py-2 text-sm text-gray-700 block'
                >未有選擇</span>
              </MenuItem>
            ) : (
              options.map((option, index) => (
                <MenuItem key={index}>
                  <span
                    className="px-4 py-2 text-sm text-gray-700 block"
                  >
                    {option.label}
                  </span>
                </MenuItem>
              ))
            )}
          </div>
        </MenuItems>
      </Menu>
    )
  }