import { useState, useEffect, useRef } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import Datepicker from 'react-tailwindcss-datepicker';
import GlobalVars from "../../Config";
import Select from "react-select";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ExportJobListDialog ({exportDialog, setExportDialog}) {
    const [selectedExportJobType, setSelectedExportJobType] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [exportBtnText, setExportBtnText] = useState("匯出");
    const [exportBtnDisabled, setExportBtnDisabled] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [mainTypeList, setMainTypeList] = useState([]);
    const [selectedMainType, setSelectedMainType] = useState(null);
    const [statusNameList, setStatusNameList] = useState([
        {"status": "所有工作", "color": "white"},
        { "status": "回公司", "color": "yellow" },
        { "status": "新工作", "color": "white" },
        { "status": "待分配", "color": "white" },
        { "status": "待確認", "color": "pink" },
        { "status": "未開始", "color": "gray" },
        { "status": "進行中", "color": "green" },
        { "status": "完成", "color": "orange" },
        { "status": "拒絕", "color": "red" },
        { "status": "取消", "color": "red" },
        { "status": "中斷", "color": "red" },
        { "status": "待續", "color": "yellow" },
        { "status": "待檢查", "color": "blue" },
        { "status": "待批准", "color": "blue" },
        { "status": "延期", "color": "yellow" },
        { "status": "緊急", "color": "red" },
        { "status": "維修中", "color": "yellow" },
        { "status": "需要額外部件", "color": "white" },
        { "status": "需要專家幫助", "color": "white" }
      ]);
  
    // fetch jobType from backend 
    const [jobTypeList, setJobTypeList] = useState([]);
    useEffect(() => {
      fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/jobtypes?organizationID=' + sessionStorage.getItem('organizationId'), {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
        .then(response => response.json())
        .then(rawData => {
          let data = rawData['jobTypes'];
          
          // set name to be langVar.tc
          for (let i = 0; i < data.length; i++) {
            data[i].name = data[i].itemCode.toString() + "-" + data[i].langVar.tc + "(" + data[i].name + ")";
            data[i].label = data[i].itemCode.toString() + "-" + data[i].langVar.tc + "(" + data[i].name + ")";
            data[i].value = data[i]._id;
          }
  
          // sort data by name
          data.sort((a, b) => a.name.localeCompare(b.name));
  
          // set jobTypeList
          setJobTypeList(data);
        })
        .catch(error => console.log(error))
    }, [])
  
    // handle export button click
    const [submittedExport, setSubmittedExport] = useState(false); // true if user has submitted export request
    const [fileAvailable, setFileAvailable] = useState(false); // true if file is available for download
    const [downloadLink, setDownloadLink] = useState(null); // download link for file
  
    const exportDialogRef = useRef(exportDialog);
    useEffect(() => {
      exportDialogRef.current = exportDialog;
    }, [exportDialog]);
  
    const handleExport = () => {
  
  
      if (submittedExport) {
        return;
      } else {
        setSubmittedExport(true);
      }
  
      // update button text
      setExportBtnText("匯出中...");
  
      // update button disabled
      setExportBtnDisabled(true);
  
      let postJobType = [];
      if (selectedExportJobType && selectedExportJobType.length > 0) {
        postJobType = selectedExportJobType.map(item => item.value);
      }
  
  
      // convert selectedStatus to a list of status value
      let postStatus = [];
      if (selectedStatus.length > 0) {
        postStatus = selectedStatus.map(item => item.value);
      }
  
      let postStaff = []
      if (selectedStaff !== null) {
        postStaff = selectedStaff.map(item => item.value);
      }
  
      let postMainType = []
      if (selectedMainType !== null) {
        postMainType = selectedMainType.map(item => item.value);
      }
  
      let submitData = {
        query: {
          date: selectedDate,
          typeID: postJobType,
          statusList: postStatus,
          staffList: postStaff,
          mainTypeID: postMainType
        },     
        organizationID: sessionStorage.getItem('organizationId')
      }
  
      console.log(submitData)
  
      // fetch request to /api/v1/jobs/export
      fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/reports/generate-report-for-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
      })
        .then(response => response.json())
        .then(rawData => {
          let reportId = rawData['reportId'];
  
          // set button text
          setExportBtnText("匯出");
          // set button disabled
          setExportBtnDisabled(false);
  
          var reportInterval = setInterval(() => {
            
            // stop if exportDialog is false, stop interval
            if (!exportDialogRef.current) {
              clearInterval(reportInterval);
              console.log("Export dialog is closed")
              // set button text
              setExportBtnText("匯出");
              // set button disabled
              setExportBtnDisabled(false);
  
              // reset submitExport
              setSubmittedExport(false);
              return;
            }
  
            // fetch request to /api/v1/reports to get url link
            fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/reports?reportID=' + reportId)
              .then(response => response.json())
              .then(rawData => {
                let data = rawData['report'];
  
                // check if url field is available
                if (data['url'] !== undefined) {
                  console.log(data['url']);
  
                  // set download link
                  setDownloadLink(data['url']);
  
                  // set file available to true
                  setFileAvailable(true);
  
                  // clear interval
                  clearInterval(reportInterval);
                }
              })
              .catch(error => console.log(error))
          }, 1000);
  
  
        })
        .catch(error => {
          console.log(error);
          
          // set button text
          setExportBtnText("匯出");
          // set button disabled
          setExportBtnDisabled(false);
  
          // reset submitExport
          setSubmittedExport(false);
        })
  
    };
  
    // create available status from statusList by converting to a list with label and value and filter out 所有工作 and 回公司
    const [availableStatusList, setAvailableStatusList] = useState([]); // list of available status
    useEffect(() => {
      let availableStatus = statusNameList.filter(item => item.status !== "所有工作" && item.status !== "回公司");
      let statusListOptions = availableStatus.map(item => {
        return {
          label: item.status,
          value: item.status
        }
      })
  
      setAvailableStatusList(statusListOptions);
      
    }, [])
  
    // fetch staff from backend
    useEffect(() => {
      fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + sessionStorage.getItem("organizationId"), {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
        .then((res) => res.json())
        .then((rawData) => {
            let data = rawData['users']
  
          // set name to be langVar.tc
          for (let i = 0; i < data.length; i++) {
            data[i].label = data[i]['displayName']
            data[i].value = data[i]._id;
          }
          
          // sort data by label
          data.sort((a, b) => a.label.localeCompare(b.label));
  
          // set staffList
          setStaffList(data);
        })
        .catch(error => console.log(error))
    }, [])
  
    // fetch main type from backend
    useEffect(() => {
      fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/maintypes?organizationID=' + sessionStorage.getItem('organizationId'), {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
        .then(response => response.json())
        .then(rawData => {
          let data = rawData['types'];
  
          // set name to be langVar.tc
          for (let i = 0; i < data.length; i++) {
            data[i].label = data[i].langVar.tc;
            data[i].value = data[i]._id;
          }
  
          // sort data by label
          data.sort((a, b) => a.label.localeCompare(b.label));
  
          // set mainTypeList
          setMainTypeList(data);
  
        })
        .catch(error => console.log(error))
    }, [])
        
  
    // clear filter
    const clearFilter = () => {
      setSelectedDate("");
      setSelectedExportJobType(null);
      setSelectedStatus([]);
      setSelectedStaff(null);
      setSelectedMainType(null);
    }
  
    return (
      <>
        <div
          className={classNames(
            exportDialog ? 'block' : 'hidden',
            'bg-black fixed left-0 top-0 sm:min-w-[400px] w-full h-full z-50 flex items-center justify-center bg-opacity-50')}
        >
          <div className="bg-white shadow rounded sm:rounded-lg  max-w-[90vw]">
            {submittedExport ? (
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">匯出報表</h3>
                <div className="mt-2 ">
                  <p
                    className='mb-2'
                  >匯出報表請求已送出</p>
                  
                  {fileAvailable ? (
                    <>
                      <a 
                      href={downloadLink} 
                      download
                      className="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >按此下載檔案
                      </a>
                    </>
                    ) : (
                      <p>
                        <ArrowPathIcon className="animate-spin h-5 w-5 inline-block" />
                        正在生成報表
                      </p>
                    )}
  
                  <button
                    onClick={() => {
                      setExportDialog(false)
                      setSubmittedExport(false)
                      setFileAvailable(false)
                    }}
                    type="button"
                    className="items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 mr-3"
                  >
                    關閉
                  </button>
                </div>
              </div>
            ) : (
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">匯出報表</h3>
                <div className="mt-2 ">
                  {/* sets of filter (e.g. date, mainType, typeID*/}
                  <div className="mt-2 sm:grid sm:grid-cols-3 sm:gap-5">
                    <div>
                      <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                        接單日期
                      </label>
                      <div className="mt-2">
                        {/* <select
                          name="date"
                          id="date"
                          value={selectedDate}
                          onChange={(event) => setSelectedDate(event.target.value)}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                          <option value="">請選擇</option>
                          <option value="today">今天</option>
                          <option value="currentMonth">當前月份</option>
                          <option value="last7Days">最近7天</option>
                          <option value="lastMonth">上個月</option>
                        </select> */}
                        <Datepicker
                          showShortcuts={true}
                          value={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          placeholder='選擇日期'
                          inputClassName="text-sm rounded dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full"
                        />
                      </div>
                    </div>
                    {/* main type filter */}
                    <div
                      className='mt-4 sm:mt-0'
                    >
                      <label htmlFor="mainType" className="block text-sm font-medium text-gray-700">
                        工作類型
                      </label>
                      <div className="mt-2">
                        <Select
                          options={mainTypeList}
                          closeMenuOnSelect={false}
                          onChange={(selectedOption) => setSelectedMainType(selectedOption)}
                          value={selectedMainType}
                          isMulti
                        />
                      </div>
                    </div>
                    {/* job type filter */}
                    <div
                      className='mt-4 sm:mt-0'
                    >
                      <label htmlFor="mainType" className="block text-sm font-medium text-gray-700">
                        工作要求
                      </label>
                      <div className="mt-2">
                        {/* <SearchableMenu 
                          list={jobTypeList}
                          selectedItem={selectedExportJobType}
                          setSelectedItem={setSelectedExportJobType}
                          /> */}
                          <Select
                            options={jobTypeList}
                            closeMenuOnSelect={false}
                            onChange={(selectedOption) => setSelectedExportJobType(selectedOption)}
                            value={selectedExportJobType}
                            isMulti
                          />
                      </div>
                    </div>
                    {/* a staff list using react-select */}
                    <div className="mt-4 sm:mt-0 sm:col-span-2 ">
                      <label htmlFor="staff" className="block text-sm font-medium text-gray-700">
                        施工同事
                      </label>
                      <div className="mt-2">
                        <Select
                          options={staffList}
                          closeMenuOnSelect={false}
                          onChange={(selectedOption) => setSelectedStaff(selectedOption)}
                          value={selectedStaff}
                          isMulti
                        />
                      </div>
                    </div>
                    {/* job status filter using react-select */}
                    <div className="mt-4 sm:mt-0">
                      <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                        工作狀態
                      </label>
                      <div className="mt-2">
                        <Select
                          options={availableStatusList}
                          closeMenuOnSelect={false}
                          onChange={(selectedOption) => setSelectedStatus(selectedOption)}
                          isMulti
                          value={selectedStatus}
                        />
                      </div>
                    </div>
                    
                  </div>
                  <div className="mt-5">
                    <button
                      onClick={clearFilter}
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 mr-3"
                    >
                      清除篩選
                    </button>
                  </div>
                  <div className="mt-5">
                    <button
                      onClick={() => setExportDialog(false)}
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 mr-3"
                    >
                      取消
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      onClick={handleExport}
                      disabled={exportBtnDisabled}
                    >
                      {exportBtnText}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
}