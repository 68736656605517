import React, { Fragment, useState, useRef, useEffect } from 'react'
import { 
    Dialog, 
    Transition, 
    Menu, 
    Listbox,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Label,
    MenuButton,
} from '@headlessui/react'
import { 
    XMarkIcon,
    PaperAirplaneIcon,
    UserIcon,
    ViewColumnsIcon,
    DocumentDuplicateIcon,
    ClockIcon, 
    PlusIcon,
    ChevronUpDownIcon,
    CheckIcon,
    ExclamationCircleIcon,
    ArrowPathIcon,
    PhotoIcon,
    TrashIcon,

} from '@heroicons/react/24/outline'
import GlobalVars from '../../Config'
import {createRoot} from 'react-dom/client'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './ChatInterfaceSlideOver.css'

const defaultMessage = [{
    "role": "assistant",
    "content": "你好, 我們有什麼可以幫助你的?"
}] 

export default function ChatInterfaceSlideOver({
    open, 
    setOpen, 
    msgId = null, 
    setMsgId,
    fixedChatInterface = false,
    setFixedChatInterface = null
}) {
    const [msgs, setMsgs] = useState(defaultMessage)  // store the conversation to be displayed in the chat
    // scroll to the bottom of the chat
    const chatContainer = useRef(null);

    useEffect(() => {
        if (!open) {
            return;
        }

        // check if msgId is null, if not retrieve the conversation
        if (msgId !== null) {
            fetch(`${GlobalVars.ASSISTANT_DOMAIN}/api/v1/main/manage-conversation?chatId=${msgId}`)
                .then(response => response.json())
                .then(data => {
                    setMsgs(data.response);
                    console.log(data.response)

                    // scroll to the bottom of the chat
                    const chat = chatContainer.current;
                    chat.scrollTop = chat.scrollHeight;

                });
        } else {
            // clear the chat
            setMsgs([{
                "role": "assistant",
                "content": "Hello, how can I help you?"
            }])
        }
    }, [open])

    const [userMsg, setUserMsg] = useState(""); // store user's message to be sent to the backend
    const [userImg, setUserImg] = useState(null); // store user's image for gpt4o model
    const modelList = [
        {"name": "一般助理", "id": 1, 'model': 'groq', 'url': "/api/v1/groq/main-assistant"},
        {"name": "網上助理", "id": 2, 'model': 'perplexity', 'url': "/api/v1/perplexity/main-assistant"},
        {"name": "知識助理", "id": 3, 'model': 'knowledge', 'url': "/api/v1/knowledge-base/generate-response"},
        // {"name": "高級助理", "id": 4, 'model': 'msazure', 'url': "/api/v1/ms-azure/gpt4o-assistant"},
    ]
    const [selectedModel, setSelectedModel] = useState(modelList[0]);
    const [showErrorMsg, setShowErrorMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [sources, setSources] = useState([])
    const [selectedGeneralModel, setSelectedGeneralModel] = useState(null);
    // useEffect(() => {
    //     console.log(selectedGeneralModel)
    // }, [selectedGeneralModel])


    const handleSendMessage = () => {
        
        // skip if user message is empty
        if (userMsg === "" && userImg === null) {
            return;
        }

        // add userImg if exist
        if (userImg != null) {
            setMsgs([...msgs, {
                "role": "user",
                "content": userImg.name
            }])
        }

        // add user message to the chat
        setMsgs([...msgs, {
            "role": "user",
            "content": userMsg
        }])

        setIsLoading(true);

        if (userImg != null) {
            // send fetch request to upload img and retrieve filename 
            let url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/ms-azure/image-signed-url";

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "type": "upload",
                    "fileName": userImg.name,
                    "fileType": userImg.type
                })
            })
            .then(response => {
                if (!response.ok) {
                    alert("獲取上載檔案連結失敗, 請重試")
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(data => {
                let url = data.url;
                let fileName = data.fileName;
                // upload image to gcp blob storage
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': userImg.type
                    },
                    body: userImg
                })
                .then(response => {
                    if (!response.ok) {
                        alert("檔案上載失敗, 請重試")
                        throw new Error("Network response was not ok");
                    }
                    return response.text();
                })
                .then(data => {
                    console.log("file name", fileName)
                    // send message to backend
                    SendMsgToBackend({
                        msgId,
                        selectedModel,
                        userMsg,
                        setMsgId,
                        setMsgs,
                        setIsLoading,
                        setShowErrorMsg,
                        sources,
                        setSources,
                        fileName,
                        fileType: userImg.type
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                    setShowErrorMsg(true);
                    setIsLoading(false);
                });
            })
            .catch(error => {
                console.error('Error:', error);
                setShowErrorMsg(true);
                setIsLoading(false);
            });

        } else {
            SendMsgToBackend({
                msgId,
                selectedModel,
                userMsg,
                setMsgId,
                setMsgs,
                setIsLoading,
                setShowErrorMsg,
                sources,
                setSources,
            });
        }
        

        // clear user message
        setUserMsg("");

        // clear user image
        setUserImg(null);
    }

    // clear message when users selected a new model
    useEffect(() => {
        setMsgs(defaultMessage);
        setMsgId(null);
    }, [selectedModel])


    // store user preference for fixed chat interface
    const handleFixedChatInterface = () => {
        setFixedChatInterface(!fixedChatInterface);
        localStorage.setItem("fixedChatInterface", !fixedChatInterface);

        if (!fixedChatInterface) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

    }
    // retrieve for user preference for fixed chat interface
    useEffect(() => {
        if (setFixedChatInterface === null) {
            return;
        }

        const fixedChatInterfacePref = localStorage.getItem("fixedChatInterface");
        if (fixedChatInterfacePref === "true") {
            setFixedChatInterface(fixedChatInterfacePref === "true");
        }
    }, [])

    // store user preference for open chat interface in session 
    useEffect(() => {
        if (open) {
            sessionStorage.setItem("openChatInterface", "true");
        } else {
            sessionStorage.setItem("openChatInterface", "false");
            console.log("set fixed chat interface to false")
            setFixedChatInterface(false);
        }
    }, [open])

    // retrieve user preference for open chat interface
    useEffect(() => {
        const openChatInterfacePref = sessionStorage.getItem("openChatInterface");
        if (openChatInterfacePref !== null) {
            setOpen(openChatInterfacePref === "true");
            console.log("open chat interface", openChatInterfacePref)

            // if open chat interface is true, check if fixed chat interface is true
            if (openChatInterfacePref === "true") {
                // check if fixed chat interface from local storage is true
                const fixedChatInterfacePref = localStorage.getItem("fixedChatInterface");
                if (fixedChatInterfacePref === "true") {
                    setFixedChatInterface(fixedChatInterfacePref === "true");
                }
                console.log("fixed chat interface", fixedChatInterfacePref)
            }

        }
    }, [])

    // create a new a chat 
    const createNewChat = () => {
        setMsgId(null);
        setMsgs([{
            "role": "assistant",
            "content": "Hello, how can I help you?"
        }]);
        setUserMsg("");
    }

    return (
        <div
            className="h-full"
        >
        {!fixedChatInterface ? (
            <Transition.Root show={open} as={Fragment}>
            <Dialog className="relative z-50" onClose={setOpen}>
                {/* <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child> */}
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                            <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                <div className="px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                        AI助手
                                    </Dialog.Title>
                                    <div className="ml-3 flex h-7 items-center">
                                    <div>
                            
                                        <HistoryDropdown setMsgId={setMsgId} setMsgs={setMsgs} customButtonClassName={"text-gray-400 flex items-center rounded-full"} />
                                        <button
                                            type="button"
                                            className="mx-3 relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={handleFixedChatInterface}
                                        >
                                            <span className="absolute -inset-2.5" />
                                            <span className="sr-only">Close panel</span>
                                            <ViewColumnsIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                        <button
                                            type="button"
                                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="absolute -inset-2.5" />
                                            <span className="sr-only">Close panel</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div>
                                    <SourceContainer sources={sources} />
                                </div>
                                <div 
                                    className="relative mt-6 flex-1 ">
                                    <ChatMessageContent
                                        userMsg={userMsg}
                                        setUserMsg={setUserMsg}
                                        handleSendMessage={handleSendMessage}
                                        msgs={msgs}
                                        chatContainer={chatContainer}
                                    />
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </div>
            </Dialog>
            </Transition.Root>
            ):(
                open && (
                    <div
                        className="h-full"
                    >
                        <div
                            className='flex justify-between px-2 items-center'
                        >   
                            <div
                                className="flex items-center"
                            >
                                <h2
                                    className="text-lg font-semibold mr-1"
                                >AI助手</h2>
                                <ModelDropdown modelList={modelList} selected={selectedModel} setSelected={setSelectedModel} />
                            </div>
                            <div>
                                <button
                                    onClick={createNewChat}
                                    className="text-gray-700 mr-2 hover:text-gray-600 hover:bg-gray-200 duration-300 rounded"
                                >
                                    <PlusIcon className="h-6 w-6" />
                                </button>
                                <HistoryDropdown setMsgId={setMsgId} setMsgs={setMsgs} />
                                <button
                                    onClick={handleFixedChatInterface}
                                    className="ml-2 text-gray-700 hover:text-gray-600 hover:bg-gray-200 duration-300 rounded"
                                >
                                    <ViewColumnsIcon className="h-6 w-6" />
                                </button>
                                <button
                                    onClick={() => {
                                        setOpen(false);
                                        setFixedChatInterface(false);
                                    }}
                                    className="ml-2 text-gray-700 hover:text-gray-600 hover:bg-gray-200 duration-300 rounded"
                                >
                                    <XMarkIcon className="h-6 w-6" />
                                </button>
                            </div>
                        </div>
                        {/* <div
                            className="mt-2 px-2"
                        >
                            {
                                selectedModel['model'] === "groq" && (
                                    <GeneralModelDropdown 
                                        selectedModel={selectedGeneralModel}
                                        setSelectedModel={setSelectedGeneralModel}
                                    />
                                )
                            }
                        </div> */}
                        <div>
                            <SourceContainer sources={sources} />
                        </div>
                        <ChatMessageContent
                            userMsg={userMsg}
                            setUserMsg={setUserMsg}
                            handleSendMessage={handleSendMessage}
                            msgs={msgs}
                            chatContainer={chatContainer}
                            showErrorMsg={showErrorMsg}
                            setShowErrorMsg={setShowErrorMsg}
                            isLoading={isLoading}
                            selectedModel={selectedModel}
                            userImg={userImg}
                            setUserImg={setUserImg}
                        />
                    </div>
                )
            )}
        </div>
    )
}

// fetch request to send message to backend service
function SendMsgToBackend({
    msgId,
    selectedModel,
    userMsg,
    setMsgId,
    setMsgs,
    setIsLoading,
    setShowErrorMsg,
    sources,
    setSources,
    fileName=null,
    fileType=null
}) {
    if (msgId === null || selectedModel['model'] === "knowledge") {
        let body = {
            "userID": localStorage.getItem("userid"),
            message: userMsg,
            "organizationId": localStorage.getItem("organizationId")
        }

        // if userImg is not null, add it to the body
        if (fileName !== null) {
            body['image'] = fileName;
            body['fileType'] = fileType;
        }

        // get message id from backend
        let fetchUrl = `${GlobalVars.ASSISTANT_DOMAIN}${selectedModel['url']}`;
        fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json()
        })
        .then(data => {
            console.log(data);
            setMsgId(data.chatId);
            setMsgs(data.messages)
            setIsLoading(false);
            setSources(data.docIds);
        })
        .catch(error => {
            console.error('Error:', error);
            setShowErrorMsg(true);
            setIsLoading(false);
        })
    } else {
        // send message to backend 
        let fetchUrl = `${GlobalVars.ASSISTANT_DOMAIN}${selectedModel['url']}`;
        fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "chatId": msgId,
                "message": userMsg,
                "userID": localStorage.getItem("userid"),
            })
        })
        .then(response => response.json())
        .then(data => {
            setMsgs(prevMsgs => [...prevMsgs, data.message])
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error:', error);
            setShowErrorMsg(true);
            setIsLoading(false);
        });

    }
}

// utility function to combine classnames
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// chat message content 
function ChatMessageContent({
    userMsg, 
    setUserMsg,  
    handleSendMessage,
    msgs,
    chatContainer,
    showErrorMsg,
    setShowErrorMsg,
    isLoading,
    selectedModel,
    userImg,
    setUserImg
}) {
    return (
        <>
            <div className="h-full">
                <div className="mt-6 flex-1 px-4 sm:px-6 overflow-y-scroll h-full">
                    <ChatMessageContainer 
                        userMsg={userMsg} 
                        setUserMsg={setUserMsg}  
                        handleSendMessage={handleSendMessage}
                        msgs={msgs}
                        chatContainer={chatContainer}
                        showErrorMsg={showErrorMsg}
                        setShowErrorMsg={setShowErrorMsg}
                        isLoading={isLoading}
                        selectedModel={selectedModel}
                        userImg={userImg}
                        setUserImg={setUserImg}
                    />
                </div>
            </div>
        </>
    )
}

// chat messaging container
function ChatMessageContainer({
    userMsg, 
    setUserMsg, 
    handleSendMessage, 
    msgs, 
    chatContainer,
    showErrorMsg,
    setShowErrorMsg,
    isLoading,
    selectedModel,
    userImg, 
    setUserImg,
}) {
    const messageRef = useRef(null);

    const [inputCompWidth, setInputCompWidth] = useState(0);
    const [inputCompLeft, setInputCompLeft] = useState(0);
    // based on the width and left of the messageRef, set the width and left of the input component
    useEffect(() => {
        // return if messageRef is null
        if (messageRef === null || messageRef.current === null) {
            return;
        }
        
        setInputCompWidth(messageRef.current.offsetWidth);
    }, [])

    return (
        <div
            className="relative flex flex-col h-full"
            id="message-container"
        >   
            <div
                ref={messageRef}
            >
                <Messages 
                    
                    msgs={msgs}
                    chatContainer={chatContainer}
                />
            </div>
            <div
                className={`fixed bottom-0  ${inputCompLeft}`}
                style={{width: `${inputCompWidth}px`}}
            >   
                <AlertComponent show={showErrorMsg} setShow={setShowErrorMsg} />
                {(selectedModel?.name == '高級助理' && userImg) && (
                    <ImageFileDisplay file={userImg} setFile={setUserImg} />
                )}
                <InputComp 
                    userMsg={userMsg} 
                    setUserMsg={setUserMsg} 
                    handleSendMessage={handleSendMessage} 
                    isLoading={isLoading}
                    selectedModel={selectedModel}
                    setUserImg={setUserImg}
                />
            </div>
        </div>
    )
}

// Image File Display
function ImageFileDisplay({file, setFile}) {
    return (
        <div className="rounded-md bg-green-50 p-4">
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    <img src={URL.createObjectURL(file)} className="h-10 w-10" />
                </div>
                <div className="ml-3">
                <p className="text-sm font-medium text-green-800">圖片準備上載</p>
                </div>
                <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                    <button
                        type="button"
                        className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                        onClick={
                            () => setFile(null)
                        }
                    >
                        <span className="sr-only">Dismiss</span>
                        <TrashIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                </div>
                </div>
            </div>
        </div>
    )

}

// chat messages
function Messages({msgs, chatContainer}) {
    
    useEffect(() => {
        const chat = chatContainer.current;
        chat.scrollTop = chat.scrollHeight;
    }, [msgs])


    // handle copy message content to clipboard
    const handleCopyMessage = (content) => {
        const markdownContent = content.toString();
        navigator.clipboard.writeText(markdownContent);
    }

    return (
        <div
            className="flex-grow overflow-auto mb-[150px]"
            ref={chatContainer}
        >
            {msgs.map((msg, index) => {
                return (
                    <>
                    <div 
                        key={index} 
                        className={`px-2 py-3  ${msg.role === "assistant" ? "bg-gray-200" : ""}`}
                    >
                        <div className="">
                            <div className="flex items-center justify-between">
                                <div
                                    className="flex items-center"
                                >
                                    <UserIcon className="h-4 w-4" />
                                    <div className="ml-2 text-sm font-semibold text-gray-900">
                                        {msg.role === "assistant" ? "AI助手" : "You"}
                                    </div>
                                </div>
                                <button
                                    className='hover:bg-gray-300 p-1 rounded-full duration-300'
                                    onClick={() => handleCopyMessage(msg.content)}
                                >
                                    <DocumentDuplicateIcon className="h-4 w-4" />
                                </button>
                            </div>
                            <div className="text-sm text-gray-500 mt-3">
                                <Markdown remarkPlugins={[remarkGfm]}>{msg.content}</Markdown>
                            </div>
                        </div>
                        
                    </div>
                    <hr
                        className="border-gray-300 w-full"
                    ></hr>
                    </>
                )
            })}
        </div>
    )
}

// input component
function InputComp({
    userMsg, 
    setUserMsg, 
    handleSendMessage, 
    isLoading,
    selectedModel,
    setUserImg,
}) {
    const textareaRef = useRef(null);

    const handleUserKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
            // Set the textarea rows back to 1
            const textarea = textareaRef.current;
            textarea.rows = 1;
        }
    }

    const handleUserInputChange = (e) => {
        setUserMsg(e.target.value);
    }

    useEffect(() => {
        const textarea = textareaRef.current;
        const listener = () => {
            textarea.rows = 1;
            const rows = Math.min(textarea.scrollHeight / 20, 5);
            textarea.rows = rows;
        };
        textarea.addEventListener('input', listener);
        return () => textarea.removeEventListener('input', listener);
    }, []);


    return (
        <div
            className='bg-white p-4 px-0 flex justify-between items-end'
        >
            <textarea 
                type="text"
                className="w-full p-2 px-3 rounded-2xl border-0.5 border-gray-300 text-sm"
                value={userMsg}
                onKeyDown={handleUserKeyPress}
                onChange={handleUserInputChange}
                ref={textareaRef}
                rows={"1"}
            ></textarea>
            <div
                className="flex items-center"
            >
                {selectedModel?.name == '高級助理' && (
                    <>
                        <label
                            className='mx-4 text-gray-800 p-2 rounded hover:bg-gray-200 duration-300 cursor-pointer'
                            htmlFor='chat-img-input'
                        >
                            <PhotoIcon className="h-5 w-5" />
                        </label>
                        <input
                            id="chat-img-input"
                            type="file"
                            className="hidden"
                            accept='image/*'
                            onChange={(e) => {
                                const file = e.target.files[0];
                                setUserImg(file);
                            }}
                        />
                    </>
                )}
                <button 
                    className=" text-gray-800 p-2 rounded hover:bg-gray-200 duration-300"
                    onClick={handleSendMessage}
                >   
                    {!isLoading ? (
                        
                        <PaperAirplaneIcon className="h-5 w-5" />
                        
                    ):(
                        <ArrowPathIcon className="h-5 w-5 animate-spin" />
                    )}   
                </button>
            </div>
        </div>
    )
}

// history dropdown
function HistoryDropdown({setMsgId, setMsgs, customButtonClassName=""}) {

    const [pastConversation, setPastConversation] = useState([]);
    useEffect(() => {
        fetchConvo({setPastConversation});
    }, [])

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className={`${customButtonClassName ? customButtonClassName : "text-gray-700 hover:text-gray-600 hover:bg-gray-200 duration-300 rounded"}`}>
                    <span className="sr-only">Open options</span>
                    <ClockIcon className="h-6 w-6" aria-hidden="true" />
                </MenuButton>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-50 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    {/* <Menu.Item>
                    {({ active }) => (
                        <a
                        href="#"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                        )}
                        >
                        Account settings
                        </a>
                    )}
                    </Menu.Item> */}
                    {/* last 10 conversation from pastConversation variable */}
                    {pastConversation.slice(0, 10).map((convo) => (
                        <Menu.Item key={convo._id}>
                            {({ active }) => (
                                <button
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm w-full text-left'
                                    )}
                                    onClick={() => {
                                        setMsgId(convo._id);
                                        fetch(`${GlobalVars.ASSISTANT_DOMAIN}/api/v1/main/manage-conversation?chatId=${convo._id}`)
                                            .then(response => response.json())
                                            .then(data => {
                                                setMsgs(data.response);
                                            });
                                    }}
                                >
                                    {convo.message.substring(0, 37)}
                                </button>
                            )}
                        </Menu.Item>
                    ))}
                </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

const fetchConvo = ({setPastConversation}) => {
    fetch(GlobalVars.ASSISTANT_DOMAIN + "/api/v1/main/manage-conversation?userID=" + localStorage.getItem("userid"))
        .then(response => response.json())
        .then(data => {
            setPastConversation(data.response);
        });
}

// model dropdown 
function ModelDropdown({modelList, selected, setSelected}) {

    return (
        <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
            <>
            <div className="relative">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{selected.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
                </Listbox.Button>

                <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ">
                    {modelList.map((item) => (
                    <Listbox.Option
                        key={item.id}
                        className={({ active }) =>
                        classNames(
                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                        }
                        value={item}
                    >
                        {({ selected, active }) => (
                        <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {item.name}
                            </span>

                            {selected ? (
                            <span
                                className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                            >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                            ) : null}
                        </>
                        )}
                    </Listbox.Option>
                    ))}
                </Listbox.Options>
                </Transition>
            </div>
            </>
        )}
        </Listbox>
    )
}

// alert component
function AlertComponent({show, setShow}) {
    const handleDismiss = () => {
        console.log("dismiss alert")
        setShow(false);
    }
    useEffect(() => {
        if (!show) {
            return;
        }

        // automatically close after 10 seconds
        setTimeout(() => {
            setShow(false);
        }, 10000)
    }, [show])

    return (
        <div className={`rounded-md bg-yellow-50 p-4 ${!show && "hidden"}`}>
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-yellow-800">Something went wrong. Please try again.</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                    type="button"
                    className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                    onClick={handleDismiss}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )
}

// source container: Displaying the source when users interact with knowledge assistant
function SourceContainer({sources}) {
    const [sourceContent, setSourceContent] = useState([]);
    useEffect(() => {
        
        // return if sources is not defined, or empty list 
        if (sources == undefined || sources.length == 0) {
            return 
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/knowledge-base/retrieve-source-list";
        let submitBody = {
            ids:sources,
            organizationID: localStorage.getItem("organizationId")
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitBody)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then(data => {
            console.log(data.documents);
            setSourceContent(data.documents);
        })
        .catch(error => {
            console.error('Error:', error);
        })
    }, [sources])


    return (
        <>
            {sourceContent.length > 0 && (
                <div
                    className="my-2 bg-slate-200 p-2 rounded-md mx-2 overflow-x-scroll"
                >
                    <h1
                        className="text-sm font-semibold text-gray-700"
                    >
                        資料來源
                    </h1>
                    <div
                        className="flex items-center h-[80px] mt-2 "
                    >
                    {sourceContent.map((source, index) => (
                        <a
                            href={"/knowledge-base/create-new-document?docId=" + source._id}
                            target="_blank"
                            className="h-full bg-slate-500  p-1 rounded-md  my-1 w-[200px] text-xs font-semibold text-white mx-2 cursor-pointer hover:underline hover:bg-slate-400 duration-300"
                        >
                            {source.title}
                        </a>
                    ))}
                    </div>
                </div>
            )}
        </>
    )
}

// general model dropdown 
function GeneralModelDropdown({
    selectedModel,
    setSelectedModel
}) {
    const [availableModels, setAvailableModels] = useState([{
        "name": "groq",
        "description": "Quick response model"
    }, {
        "name": "gpt4o",
        "description": "OpenAI's GPT-4 model. Can accept image and text as input "
    }]);
    // const [selectedModel, setSelectedModel] = useState(availableModels[0]);
    const [query, setQuery] = useState('');

    useEffect(() => {
        // preselect the first model
        setSelectedModel(availableModels[0]);
    }, [])

    return (
        <Combobox
          as="div"
          value={selectedModel}
          className="flex items-center justify-between"
          onChange={(item) => {
            setQuery('')
            setSelectedModel(item)
          }}
        >
          <Label className="block text-sm font-medium leading-6 text-gray-900 mr-2 ">AI模型</Label>
          <div className="relative">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(event) => setQuery(event.target.value)}
              onBlur={() => setQuery('')}
              displayValue={(item) => item?.name}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>
    
            {availableModels.length > 0 && (
              <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {availableModels.map((item) => (
                  <ComboboxOption
                    // key={person.id}
                    value={item}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                  >
                    <span className="block truncate group-data-[selected]:font-semibold">{item.name}</span>
    
                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}
          </div>
        </Combobox>
    )
}

