import ApplicationShell from '../../Components/ApplicationShell/ApplicationShell';
import { Fragment } from 'react'
import { 
  Menu, 
  MenuButton,
  MenuItems,
  MenuItem,
  Transition, 
  Combobox, 
  ComboboxInput, 
  ComboboxButton, 
  ComboboxOption, 
  ComboboxOptions 
} from '@headlessui/react'
import { 
  EllipsisVerticalIcon,
  DocumentIcon,
  PlusIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  CheckIcon,
  BriefcaseIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import SlideOvers from '../../Components/SlideOvers';
import { useState, useEffect, useCallback } from 'react'
import GlobalVars from '../../Config';
import Select from 'react-select'
import {MultiSelect} from 'react-multi-select-component';
import NewJobForm from '../../Components/NewJobForm';
import "./IssueReporting.css";
import debounce from 'lodash/debounce';

const serviceType = [
  { "label": "CABD 公共天線系統", "value": "CABD" },
  { "label": "CCTV 閉路電視", "value": "CCTV" },
  { "label": "Data Switch 數據交換機", "value": "Data Switch" },
  { "label": "Door Lock 門鎖", "value": "Door Lock" },
  { "label": "E-Casual 電子便捷", "value": "E-Casual" },
  { "label": "Email 電子郵件", "value": "Email" },
  { "label": "Firewall 防火牆", "value": "Firewall" },
  { "label": "Front Office/Property Management System (PMS) 酒店前台管理系統", "value": "Front Office System/Property Management System (PMS)" },
  { "label": "Internet Gateway 網絡閘道", "value": "Internet Gateway" },
  { "label": "Kiosk 自助服務機", "value": "Kiosk" },
  { "label": "Network 網絡", "value": "Network" },
  { "label": "NSS Server 通知系統", "value": "NSS Server" },
  { "label": "POS 餐飲系統", "value": "POS" },
  { "label": "PC 個人電腦", "value": "PC" },
  { "label": "PABX 電話系統", "value": "PABX" },
  { "label": "Telephone Set 電話機", "value": "Telephone Set" },
  { "label": "TV 電視", "value": "TV" },
  { "label": "UPS 不間斷電源", "value": "UPS" },
  { "label": "Vending Machine 自助售貨機", "value": "Vending Machine" },
  { "label": "Voice Logger 語音記錄器", "value": "Voice Logger" },
  { "label": "Website 網站", "value": "Website" },
  { "label": "WiFi/AP 無線網絡", "value": "WiFi/AP" },
  { "label": "Voice Mail System 語音信箱系統", "value": "i-Message Voice Mail System" },
  { "label": "iPanel/iSignage 電子廣告板", "value": "i-Panel" },
  { "label": "Call Accounting System 計費系統", "value": "i-interface Call Accounting System" },
  { "label": "Other Products & Services 其他產品及服務", "value": "Other Products & Services" }
]

const statuses = {
    Pending: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    Resolved: 'text-green-700 bg-green-50 ring-green-600/20',
    'In Progress': 'text-red-600 bg-red-50 ring-red-500/10',
    Canceled: 'text-gray-800 bg-gray-50 ring-gray-600/20',
    "Cancel Requested": 'text-gray-800 bg-gray-50 ring-gray-600/20',
  }

const statusDisplayNamePair = {
    Pending: '待處理',
    Resolved: '已解決',
    'In Progress': '處理中',
    Canceled: '已取消',
    "Cancel Requested": '客戶提出工單取消',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function IssueReporting() {
    // set page title to be 事件紀錄
    document.title = "事件紀錄";

    const [openForm, setOpenForm] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [title, setTitle] = useState("新加事件");
    const [remarks, setRemarks] = useState("");
    const [statusUpdate, setIssueUpdateDesc] = useState("");
    const [requestId, setRequestId] = useState("");
    const [locationList, setLocationList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedNotifyUser, setSelectedNotifyUser] = useState([]);
    const [files, setFiles] = useState([]);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [editItemInfo, setEditItemInfo] = useState({});
    const [filterObj, setFilterObj] = useState({
      "productType": {"value": "", "label": "所有產品"},
      "location": {"_id": "all", "name": "所有地點"},
    });

    const [newJobForm, setNewJobForm] = useState(false);
    const [jobDetail, setJobDetail] = useState({});
    const [issueDetail, setIssueDetail] = useState({
      "meta": {},
      "contactInfo": {
        "contactList": []
      }
    });
    useEffect(() => {
      // clear job detail when newJobForm is closed
      if (!newJobForm) {
        setJobDetail({});
      }
    }, [newJobForm]);

    const [issueList, setIssueList] = useState([]);
    const [page, setPage] = useState(1);  // page for issueList
    useEffect(() => {
        retrieveIssueList({setIssueList, page});
    }, []);

    // retrieve extra issue list when scroll to bottom of page
    useEffect(() => {
      // add window scroll event listener to load more issues
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
    , [page]);

    // handle scroll event
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        
        let newPage = page + 1;
        console.log("bottom of page")
        retrieveIssueList({setIssueList, page:newPage, filterObj});
        setPage(newPage);

        
      }
    }

    // update data when users update filterObj
    useEffect(() => {
      retrieveIssueList({setIssueList, page, filterObj});
    }, [filterObj]);

    // boolean for prompting users to complete issue related job 
    const [relatedJobId, setRelatedJobId] = useState("");
    const [promptCompleteIssueRelatedJob, setPromptCompleteIssueRelatedJob] = useState(false);

    // clear form
    const ClearForm = () => {
      setRemarks("");
      setRequestId("");

      // clear selected location
      setSelectedLocation(null);

      // clear files
      setFiles([]);

      // clear current files
      setCurrentFiles([]);

      // set issueUpdateDesc to default
      setIssueUpdateDesc("");

      // clear editId and editItemInfo
      setIsEdit(false);
      setEditId(null);
      setEditItemInfo(null);

      // setissueDetail to default
      setIssueDetail({
        "meta": {},
        "contactInfo": {
          "contactList": []
        }
      });
    }
    
    return (
        <>
            <ApplicationShell>
                <PageHeading 
                  setOpen={setOpenForm} 
                  setIsEdit={setIsEdit} 
                  setTitle={setTitle} 
                  setRemarks={setRemarks}
                  setRequestId={setRequestId}
                  setSelectedLocation={setSelectedLocation}
                  setFiles={setFiles}
                  setCurrentFiles={setCurrentFiles}
                  setIssueUpdateDesc={setIssueUpdateDesc}
                  setIssueDetail={setIssueDetail}
                  ClearForm={ClearForm}
                />
                <SearchBarContainer 
                  filterObj={filterObj}
                  setFilterObj={setFilterObj}
                />
                <IssueList  
                  setIsEdit={setIsEdit} 
                  setEditId={setEditId} 
                  setOpen={setOpenForm} 
                  issueList={issueList} 
                  setIssueList={setIssueList} 
                  setNewJobForm={setNewJobForm}
                  setJobDetail={setJobDetail}
                  setEditItemInfo={setEditItemInfo}
                  filterObj={filterObj}
                  setPromptCompleteIssueRelatedJob={setPromptCompleteIssueRelatedJob}
                  setRelatedJobId={setRelatedJobId}
                />
            </ApplicationShell>
            <IssueFormContainer 
              open={openForm} 
              setOpen={setOpenForm}  
              isEdit={isEdit} 
              editId={editId} 
              title={title}
              setTitle={setTitle}
              remarks={remarks}
              setRemarks={setRemarks}
              requestId={requestId}
              setRequestId={setRequestId}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              files={files}
              setFiles={setFiles}
              locationList={locationList}
              setLocationList={setLocationList}
              userList={userList}
              setUserList={setUserList}
              selectedNotifyUser={selectedNotifyUser}
              setSelectedNotifyUser={setSelectedNotifyUser}
              currentFiles={currentFiles}
              setCurrentFiles={setCurrentFiles}
              setIssueList={setIssueList}
              statusUpdate={statusUpdate}
              setIssueUpdateDesc={setIssueUpdateDesc}
              setNewJobForm={setNewJobForm}
              setJobDetail={setJobDetail}
              editItemInfo={editItemInfo}
              issueDetail={issueDetail}
              setIssueDetail={setIssueDetail}
              
            />
            <NewJobForm state={newJobForm} setEditState={setNewJobForm} jobPrefill={jobDetail}  />
            <PromptCompleteIssueRelatedJob show={promptCompleteIssueRelatedJob} setShow={setPromptCompleteIssueRelatedJob} relatedJobId={relatedJobId} />
        </>
    )
}

// new issue or edit issue container
function IssueFormContainer({
  open, 
  setOpen, 
  isEdit, 
  editId, 
  title, 
  setTitle,
  remarks,
  setRemarks,
  requestId,
  setRequestId,
  selectedLocation,
  setSelectedLocation,
  files,
  setFiles,
  locationList,
  setLocationList,
  userList,
  setUserList,
  selectedNotifyUser,
  setSelectedNotifyUser,
  currentFiles,
  setCurrentFiles,
  setIssueList,
  statusUpdate,
  setIssueUpdateDesc,
  setNewJobForm,
  setJobDetail,
  editItemInfo,
  issueDetail,
  setIssueDetail
}) {
    
    
    const [manualNotifyUser, setManualNotifyUser] = useState([]);
    

    const handleSubmission = () => {
        let confirmText = "確定要新增事件嗎？";
        if (title.includes("編輯事件")) {
            confirmText = "確定要編輯事件嗎？";
        }

        // error if location is not selected
        if (!selectedLocation) {
          alert("請選擇地點");
          return;
        }

        // prompt user for confirmation
        if (window.confirm(confirmText)) {
            // submit form
            console.log("submitting form");

            let submitData = {
                "userID": localStorage.getItem("userid"),
                "organizationID": localStorage.getItem("organizationId"),
                "remarks": remarks,
                "requestID": requestId,
                "locationID": selectedLocation.value,
                "notifyUserIDs": selectedNotifyUser.map((item) => item.value),
                "contactInfo": issueDetail.contactInfo,
                "meta": issueDetail.meta,
                "statusUpdate": statusUpdate,
                "productType": issueDetail.productType,
                "customerPhoneForEnquiry": issueDetail.customerPhoneForEnquiry,
                "fromJobManagement": true, // set to true to indicate that the issue is created from job management system 
            }

            let apiMethod = "POST";
            if (title.includes("編輯事件")) {
                apiMethod = "PUT";
                submitData["issueID"] = editId;

                // remove userID from submitData
                delete submitData["userID"];

                // delete fromJobManagement from submitData
                delete submitData["fromJobManagement"];
            } else {
                submitData["createdBy"] = localStorage.getItem("userid");
            }
            
            // create POST request to submit issue
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issues";
            let body = submitData;
            let headers = {
              "Content-Type": "application/json"
            };
            fetch(url, 
                {
                    method: apiMethod,
                    body: JSON.stringify(body),
                    headers: headers
                }
            ).then(response => {
                if (response.status === 200) {
                    response.json().then(rawData => {
                      let data = rawData['issueID']
                      
                      // check if there are files to be uploaded
                      if (files.length > 0) {
                        
                        // get signed urls and upload files
                        getSignedUrls(files, data);

                      } else {
                        // close slide over
                        setOpen(false);

                        // refetch issue list
                        retrieveIssueList({setIssueList});

                        // check if it's edit mode
                        if (title.includes("編輯事件")) {
                          alert("工單編輯成功!");
                        } else {
                          if(window.confirm("工單新增成功, 你希望新增工作嗎？")) {
                            

                            let jobData = {
                              "issueID": data,
                              "locationName": selectedLocation ? selectedLocation.label : "",
                              "assignedTo": localStorage.getItem("userid"),
                              "meta": {
                                "clientRemark": remarks + " (工單編號: " + rawData['humanReadableId'] + ")",
                                "estimatedDate": GetTodayDateStr(),
                              }
                            }

                            if (selectedLocation) {
                              jobData["locationIDs"] = [selectedLocation.value];
                            }

                            setJobDetail(jobData);


                            // open new job form
                            setNewJobForm(true);
                          }
                        }
                        
                      }

                    });

                    // close slide over
                    // setOpen(false);
                    // // reload page
                    // window.location.reload();
                } else {
                    alert("新增事件失敗");
                }
            }).catch(error => {
                console.log(error);
                alert("新增事件失敗");
            });

        }
    }

    // fetch for location list
    useEffect(() => {
      // create a fetch request with a given id
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/locations?organizationID=" + localStorage.getItem("organizationId");
      fetch(url, {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
      .then(response => {
          if (response.status === 200) {
              response.json().then(rawData => {
                  let data = rawData['locations'];

                  // convert data to react-select format and add traditional chinese name if available
                  data = data.map((item) => {
                      return {
                          value: item._id,
                          label: `${item.name}${item.langVar?.tc ? ` (${item.langVar.tc})` : ''}`
                      };
                  });
                  
                  setLocationList(data);
              });
          }
      }).catch(error => {
          console.log(error);
          alert("獲取地點列表失敗")
      });
    }, []);

    // fetch for user list 
    useEffect(() => {
      // create a fetch request with a given id
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + localStorage.getItem("organizationId");
      fetch(url, {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
      .then(response => {
          if (response.status === 200) {
              response.json().then(rawData => {
                  let data = rawData['users'];

                  // convert data to react-select format
                  data = data.map((item) => {
                      return {
                          value: item._id,
                          label: item.displayName
                      }
                  });
                  
                  setUserList(data);
              } 
              );
          }
      }).catch(error => {
          console.log(error);
          alert("獲取用戶列表失敗")
      });
    }, []);


    // auto populate when edit mode is on
    useEffect(() => {
        if (isEdit) { 
            // reset input 
            setRemarks("");
            setRequestId("");
            setSelectedLocation(null);  // clear selected location
            setFiles([]);  // clear files
            setCurrentFiles([]);  // clear current files
            setIssueDetail({
              "meta": {},
              "contactInfo": {
                "contactList": []
              }
            });
            setIssueUpdateDesc("");

            console.log("edit mode on");
            // create a fetch request with a given id
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issues?issueID=" + editId;
            fetch(url)
            .then(response => {
                if (response.status === 200) {
                    response.json().then(rawData => {
                        setOpen(true);
                        let data = rawData['issue']

                        // populate empty contact info if contactInfo is not present or contactList not present within contactInfo
                        if (!data.hasOwnProperty("contactInfo")) {
                          data.contactInfo = {
                            "contactList": [],
                          }
                        } else if (!data.contactInfo.hasOwnProperty("contactList")) {
                          data.contactInfo.contactList = [];
                        }

                        // populate medium if not present
                        if (!data.hasOwnProperty("contactInfo")) {
                          data.contactInfo = {
                            "medium": "sms"
                          };
                        } else if (!data.contactInfo.hasOwnProperty("medium")) {
                          data.contactInfo.medium = "sms";
                        }

                        setIssueDetail(data);

                        let title = "編輯事件";
                        if (data.hasOwnProperty("humanReadableId")) {
                          title += " (編號: " + data.humanReadableId + ")";
                        }
                        
                        setTitle(title);
                        setRemarks(data.remarks);

                        setIssueUpdateDesc(data.statusUpdate);

                        // set location
                        if (data.hasOwnProperty("locationID")) {
                          // from locationList, find the location with the same id
                          for (let i = 0; i < locationList.length; i++) {
                            if (locationList[i].value === data.locationID) {
                              setSelectedLocation(locationList[i]);
                              break;
                            }
                          }
                        }

                        if (data.hasOwnProperty("requestID") && data.requestID) {
                            setRequestId(data.requestID);
                        } else {
                            setRequestId("");
                        }

                        // if notifyUserIDs is not empty
                        if (data.hasOwnProperty("notifyUserIDs") && data.notifyUserIDs.length > 0) {
                          // from userList, find the user with the same id  
                          let notifyUserIDs = data.notifyUserIDs;
                          let selectedNotifyUser = [];
                          for (let i = 0; i < notifyUserIDs.length; i++) {
                            for (let j = 0; j < userList.length; j++) {
                              if (userList[j].value === notifyUserIDs[i]) {
                                selectedNotifyUser.push(userList[j]);
                                break;
                              }
                            }
                          }
                          setSelectedNotifyUser(selectedNotifyUser);
                        } else {
                          setSelectedNotifyUser([]);
                        }
                        
                        // get signed urls for file download
                        GetSignedUrlsForDownload(editId);
                    });
                }
            }).catch(error => {
                console.log(error);
                alert("獲取事件失敗")
            });

            setTitle("編輯事件");
            
        } else {
            setTitle("新加事件");
            setRemarks("");
            setRequestId("");
            
            // clear selected location
            setSelectedLocation(null);

            // clear files
            setFiles([]);

            // clear current files
            setCurrentFiles([]);

            // set issueDetail to default
            setIssueDetail({
              "meta": {},
              "contactInfo": {
                "contactList": []
              }
            });

            // set issueUpdateDesc to default
            setIssueUpdateDesc("");

            // clear selected notify user
            setSelectedNotifyUser([]);

            // clear manual notify user
            setManualNotifyUser([]);
        }

    }, [editId]);   

    // get signed urls for file download 
    
    function GetSignedUrlsForDownload(editId) {
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/signed-url-for-file-upload?issueID=" + editId;
      fetch(url)
        .then(response => {
          if (response.status === 200) {
              response.json().then(rawData => {
                  let data = rawData['attachments'];

                  setCurrentFiles(data);
              });
          }
      }).catch(error => {
          console.log(error);
          // alert("獲取檔案失敗敗")
      });
    }

    // option signed urls to upload files
    function getSignedUrls(files, issueID) {
      // convert files to array
      let filesArray = Array.from(files).map(file => ({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified
      }));
    
      // create a fetch request with a given id
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/signed-url-for-file-upload";
      let body = {
        "files": filesArray,
        "issueID": issueID,
        "organizationID": localStorage.getItem("organizationId"),
      };
      let headers = {
        "Content-Type": "application/json"
      };
      fetch(url, 
          {
              method: "POST",
              body: JSON.stringify(body),
              headers: headers
          }
      ).then(response => {
          if (response.status === 200) {
              response.json().then(rawData => {
                  let data = rawData['url'];
                  
                  // upload files using signed urls
                  UploadFiles(files, data);
              });
          }
      }).catch(error => {
          console.log(error);
          alert("獲取簽名失敗")
      });
    }

    // upload files with signed urls and file list
    function UploadFiles(files, signedUrls) { 

      // upload files using signed urls
      Array.from(files).forEach((file, index) => {
        let signedUrl = signedUrls[index];
        let headers = {
          "Content-Type": file.type
        };
        fetch(signedUrl, 
            {
                method: "PUT",
                body: file,
                headers: headers
            }
        ).then(response => {
            if (response.status === 200) {
                console.log("file uploaded");

                if (index == files.length - 1) {
                  alert("上載成功, 現在重新載入頁面");

                  // close slide over
                  setOpen(false);
                  // reload page
                  window.location.reload();
                }
            }
        }).catch(error => {
            console.log(error);
            alert("上載失敗")
        });
      });
    }

    return(
        <>
            <SlideOvers 
                title={title} 
                open={open} 
                setOpen={setOpen} 
                content={<IssueForm 
                    remarks={remarks} 
                    setRemarks={setRemarks} 
                    requestId={requestId} 
                    setRequestId={setRequestId} 
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    files={files}
                    setFiles={setFiles}
                    locationList={locationList}
                    setLocationList={setLocationList}
                    userList={userList}
                    selectedNotifyUser={selectedNotifyUser}
                    setSelectedNotifyUser={setSelectedNotifyUser}
                    currentFiles={currentFiles}
                    setManualNotifyUser={setManualNotifyUser}
                    issueDetail={issueDetail}
                    setIssueDetail={setIssueDetail}
                    statusUpdate={statusUpdate}
                    setIssueUpdateDesc={setIssueUpdateDesc}
                    setNewJobForm={setNewJobForm}
                    setJobDetail={setJobDetail}
                    editId={editId}
                    editItemInfo={editItemInfo}
                  />} 
                submitAction={handleSubmission} />
        </>
    )
}

// new issue or edit issue form
function IssueForm({
  remarks, 
  setRemarks, 
  requestId, 
  setRequestId, 
  selectedLocation, 
  setSelectedLocation,
  files,
  setFiles,
  locationList,
  userList,
  selectedNotifyUser,
  setSelectedNotifyUser,
  currentFiles,
  setManualNotifyUser,
  issueDetail,
  setIssueDetail,
  statusUpdate,
  setIssueUpdateDesc,
  setNewJobForm,
  setJobDetail,
  editId,
  editItemInfo
}) {
    
    // verify requestId 
    const [correctRequestId, setCorrectRequestId] = useState(false);
    useEffect(() => {
        if (requestId !== "") {
            // create a fetch request with a given id
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?jobID=" + requestId;
            fetch(url,{
                headers: {
                    "Authorization": sessionStorage.getItem("idToken")
                }
            })
            .then(response => {
                if (response.status === 200) {
                    setCorrectRequestId(true);
                } else {

                }
            }).catch(error => {
                console.log(error);
                setCorrectRequestId(false);
            });
        }
    }, [requestId]);

    // handle file upload 
    // const [files, setFiles] = useState([]);
    const [fileRecognized, setFileRecognized] = useState(false);
    const handleFileUpload = (e) => {
      if (e.target.files.length > 0) {
        console.log(e.target.files);
        setFiles(e.target.files);
      }
    }

    // handle element drag over
    const [dragOver, setDragOver] = useState(false);
    const handleDragOver = (e) => {
      e.preventDefault();
      setDragOver(true);
        
    }

    // handle element drag leave
    const handleDragLeave = (e) => {
      e.preventDefault();
      setDragOver(false);
    }


    // handle file drop
    const handleFileDrop = (e) => {
      e.preventDefault();
      setDragOver(false);

      // get file list
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        console.log(files);
        setFiles(files);
      }

    }

    // set file received if files more than 0
    useEffect(() => {
      if (files.length > 0) {
        setFileRecognized(true);
      } else {
        setFileRecognized(false);
      }
    }, [files]);

    //remove file from file list
    const removeFile = (index) => {
      let newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);
    }

    const [staffName, setStaffName] = useState("");
    useEffect(() => {
      // return if userList not valid 
      if (!userList || !issueDetail) {
        return;
      }

      // use userList and issueDetail.staffID to find staff name
      if (issueDetail.hasOwnProperty("staffID")) {
        for (let i = 0; i < userList.length; i++) {
          if (userList[i].value === issueDetail.staffID) {
            setStaffName(userList[i].label);
            break;
          }
        }
      }
    }, [issueDetail, userList]);

    // state for issue description rows
    const [issueDescRows, setIssueDescRows] = useState(3);

    // state for status update rows
    const [statusUpdateRows, setStatusUpdateRows] = useState(3);

    return (
        <>
            <div className="space-y-6 pb-5 pt-6">
                <div>
                    <label
                    htmlFor='status-update'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    事件狀態更新
                    </label>
                    <p
                      className="text-sm text-gray-500"
                    >
                      供工作人員用於解釋事件的狀態更新，例如事件處理進度、事件處理結果等
                    </p>
                    <div className="mt-2">
                      <textarea
                        type="text"
                        name='status-update'
                        value={statusUpdate}
                        onChange={(e) => setIssueUpdateDesc(e.target.value)}
                        rows={statusUpdateRows}
                        onFocus={() => setStatusUpdateRows(8)}
                        onBlur={() => setStatusUpdateRows(3)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 duration-300 transition-height"
                      >
                      </textarea>
                    </div>
                    <IssueStatusUpdateSuggestion 
                      setIssueUpdateDesc={setIssueUpdateDesc}
                      remarks={remarks}
                      productType={issueDetail.productType}
                      locationID={selectedLocation ? selectedLocation.value : ""}
                    />
                </div>
                <hr></hr>
                {/* location list */}
                <div>
                    <label
                    htmlFor='location'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    地點
                    </label>
                    <div className="mt-2">
                        {editId && !selectedLocation && (
                          <span
                            className="text-sm text-gray-500 bg-gray-100 w-full p-1 rounded-md"
                          ><span className="font-semibold mr-1">{issueDetail['locationID']}</span>(未能配對現有地點, 顧客手動輸入地點)</span>
                        )}
                        <Select
                          options={locationList}
                          value={selectedLocation}
                          onChange={setSelectedLocation}
                          className="mt-2"
                        />
                    </div>
                </div>
                {/* 產品類別 */}
                <div>
                    <label
                    htmlFor='issue-desc'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    產品類別
                    </label>
                    <div className="mt-2">
                        {/* <input
                            type="text"
                            name='issue-desc'
                            value={issueDetail.productType}
                            onChange={(e) => setIssueDetail({...issueDetail, productType: e.target.value})}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            disabled
                        /> */}
                        {/* use serviceType to create a dropdown menu */}
                        <Select
                          key={issueDetail.productType}
                          options={serviceType}
                          defaultValue={serviceType.find(option => option.value === issueDetail.productType)}
                          onChange={(option) => setIssueDetail({...issueDetail, productType: option.value})}
                        />
                    </div>
                </div>
                <div>
                    <label
                    htmlFor='issue-desc'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    事件描述
                    </label>
                    <div className="mt-2">
                        <textarea
                            type="text"
                            name='issue-desc'
                            value={remarks}
                            rows={issueDescRows}
                            onFocus={() => setIssueDescRows(8)}
                            onBlur={() => setIssueDescRows(3)}
                            onChange={(e) => setRemarks(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                
                {/* a date and time input indicating Required visit time (指定工作時間) */}
                <div>
                    <label
                    htmlFor='visit-time'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    指定工作時間
                    </label>
                    <div className="mt-2">
                        <input
                            type="datetime-local"
                            name='visit-time'
                            value={(issueDetail.meta && issueDetail.meta.visitTime) ? issueDetail.meta.visitTime : ""}
                            onChange={(e) => setIssueDetail({...issueDetail, meta: {...issueDetail.meta, visitTime: e.target.value}})}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div
                    className="mt-1"
                >   
                <div
                    className="flex justify-between"
                >
                    <label
                      htmlFor='request-id'
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      相關工作單號
                    </label>
                    {editId &&
                      <button
                        className="text-sm font-semibold  text-indigo-600 hover:text-indigo-500 flex items-center cursor-pointer rounded duration-300"
                        onClick={() => {

                          let jobData = {
                            "issueID": editId,
                            "locationName": selectedLocation ? selectedLocation.label : "",
                            "assignedTo": localStorage.getItem("userid"),
                            "meta": {
                              "clientRemark": remarks + " (工單編號: " + editItemInfo.humanReadableId + ")",
                              "estimatedDate": GetTodayDateStr(),
                            }
                          }

                          if (selectedLocation) {
                            jobData["locationIDs"] = [selectedLocation.value];
                          }

                          setJobDetail(jobData);

                          setNewJobForm(true);
                        }}
                      >
                        <PlusIcon className="h-5 w-5 inline-block mr-1" />
                        生成工作
                      </button>
                    }
                </div>
                    <div className="mt-2">
                        <input
                            type="text"
                            name='request-id'
                            value={requestId}
                            onChange={(e) => setRequestId(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {/* support text */}
                        {requestId !== "" && 
                          <>
                            {correctRequestId ? (
                              <div
                                className="flex items-center justify-between w-full cursor-pointer"
                              >
                                <p className="mt-2 text-sm text-green-500">工作單號正確</p>
                                <a
                                  href={"/jobs/" + requestId}
                                  target="_blank"
                                  className="mt-2 text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                                >查看工作</a>
                              </div>
                            ):( 
                              <p className="mt-2 text-sm text-red-500">工作單號錯誤</p>
                            )}
                          </>
                        }
                    </div>
                </div>
                {/* supporting documents */}
                <div className="col-span-full">
                  <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                    補充檔案 (如適用)
                  </label>
                  <div 
                    className={`mt-2 flex justify-center rounded-lg border border-dashed ${dragOver ? 'border-indigo-800' :'border-[#cbcbcbde]'} px-6 py-10 duration-300`}
                    onDragOver={handleDragOver}
                    onDrop={handleFileDrop}
                    onDragLeave={handleDragLeave}
                  >
                    <div className="text-center flex flex-col items-center">
                      <DocumentIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>上傳檔案</span>
                          <input 
                            id="file-upload" 
                            name="file-upload" 
                            type="file" 
                            className="sr-only" 
                            multiple 
                            onChange={handleFileUpload}
                          />
                        </label>
                        <p className="pl-1">或拖放</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">PNG, JPG, PDF, DOCX, XLSX, CSV up to 10MB</p>
                    </div>
                  </div>
                  {/* list out selected file by file name */}
                  {fileRecognized && 
                    <div className="mt-2">
                      <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                        {Array.from(files).map((file, index) => (
                          <li key={index} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                            <div className="w-0 flex-1 flex items-center">
                              <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <span className="ml-2 flex-1 w-0 truncate">{file.name}</span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a 
                                className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                onClick={() => removeFile(index)}
                              >
                                移除
                              </a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                </div>
                {/* show current files  */}
                {currentFiles.length > 0 && 
                  <div className="col-span-full">
                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                      已上傳檔案
                    </label>
                    <div className="mt-2">
                      <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                        {currentFiles.map((file, index) => (
                          <li key={index} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                            <div className="w-0 flex-1 flex items-center">
                              <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <span className="ml-2 flex-1 w-0 truncate">{file.fileName}</span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a 
                                className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                onClick={() => window.open(file.url, "_blank")}
                              >
                                下載
                              </a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                }
                <hr></hr>
                <h2
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  聯絡資料
                </h2>
                <h3
                  className="text-sm font-semibold  text-gray-900"
                >
                  查詢工作的客戶資料
                </h3>
                <div>
                    <label
                    htmlFor='customer-name'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    客戶姓名
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            name='customer-name'
                            value={(issueDetail.meta && issueDetail.meta.name) ? issueDetail.meta.name : ""}
                            onChange={(e) => setIssueDetail({...issueDetail, meta: {...issueDetail.meta, name: e.target.value}})}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <label
                    htmlFor='customer-phone'
                    className="mt-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                    客戶電話
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            name='customer-phone'
                            value={issueDetail.customerPhoneForEnquiry ? issueDetail.customerPhoneForEnquiry : ""}
                            onChange={(e) => setIssueDetail({...issueDetail, customerPhoneForEnquiry: e.target.value})}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                {/* a selector for client preferred contact method (sms or phone) */}
                <h3
                  className="text-sm font-semibold text-gray-900"
                >工單完成時的通知</h3>
                <div
                  className='mt-2'
                >
                    <label
                    htmlFor='contact-method'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    聯絡方式
                    </label>
                    <div className="mt-2">
                        <select
                            name='contact-method'
                            value={(issueDetail.contactInfo && issueDetail.contactInfo.medium) ? issueDetail.contactInfo.medium : ""}
                            onChange={(e) => setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, medium: e.target.value}})}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="sms">短訊</option>
                          <option value="phone">電話</option>
                          <option value="whatsapp">WhatsApp</option>
                        </select>
                    </div>
                    {issueDetail.contactInfo && issueDetail.contactInfo.medium === "phone" &&
                      <p
                        className="text-sm text-gray-500 mt-1"
                      >
                        若選擇電話為聯絡方式，系統將會自動使用客戶提供的聯絡電話進行通知. 同事無需手動通知撥打電話. 
                      </p>
                    }
                </div>
                {/* a text input display message body */}
                <div>
                    <label
                    htmlFor='message-body'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    訊息內容
                    </label>
                    <div className="mt-2">
                        <textarea
                            type="text"
                            name='message-body'
                            value={(issueDetail.contactInfo && issueDetail.contactInfo.content) ? issueDetail.contactInfo.content : ""}
                            onChange={(e) => setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, content: e.target.value}})}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                {/* a list of number input listing client's phone number */}
                <div>
                    <label
                      htmlFor='contact-method'
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    聯絡電話
                    </label>
                    {(issueDetail.contactInfo && issueDetail.contactInfo.contactList) ? (
                      issueDetail.contactInfo.contactList.map((contact, index) => (
                        <div key={index} className="mt-2">
                          <input
                              type="text"
                              name='contact-method'
                              value={contact}
                              onChange={(e) => {
                                let newContactList = [...issueDetail.contactInfo.contactList];
                                newContactList[index] = e.target.value;
                                setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, contactList: newContactList}});
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      ))
                    ) : (
                      null
                    )}
                    {/* button to add more number or delete last number */}
                    <div className="mt-2">
                      <button 
                        className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 mr-2"
                        onClick={() => {
                          console.log(issueDetail.contactInfo.contactList)
                          let newContactList = [...issueDetail.contactInfo.contactList];
                          newContactList.push("");
                          setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, contactList: newContactList}});
                        }}
                      >
                        新增聯絡電話
                      </button>
                      <button 
                        className="text-sm font-semibold text-red-600 hover:text-red-500"
                        onClick={() => {
                          let newContactList = [...issueDetail.contactInfo.contactList];
                          newContactList.pop();
                          setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, contactList: newContactList}});
                        }}
                      >
                        刪除最後一個
                      </button>
                    </div>
                </div>
                      
                <hr></hr>
                <h2
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  內部聯絡
                </h2>
                {/* responsible person */}
                <div>
                    <label
                    htmlFor='responsible-person'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    負責同事
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            name='responsible-person'
                            value={staffName}
                            disabled
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                {/* notify person */}
                <div>
                    <label
                    htmlFor='notify-person'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    通知同事
                    </label>
                    <div className="mt-2">
                        <MultiSelect
                          options={userList}
                          value={selectedNotifyUser}
                          onChange={setSelectedNotifyUser}
                          labelledBy="Select"
                          className="w-full"
                          hasSelectAll={false}
                        />
                    </div>
                </div>
                {/* manual input of phone nubmers */}
                {/* <div>
                    <label
                    htmlFor='manual-notify-person'
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    手動輸入通知人
                    </label>
                    <div className="mt-2">
                        <textarea
                            type="text"
                            name='manual-notify-person'
                            value={manualInputList}
                            onChange={(e) => setManualInputList(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div> */}
            </div>
        </>
    )
}

// page headings 
function PageHeading({
  setOpen, 
  setIsEdit, 
  setTitle, 
  ClearForm
}) {
    return (
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            事件紀錄
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          {/* <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Edit
          </button> */}
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
                setOpen(true)
                setIsEdit(false);
                setTitle("新加事件");
                
                // clear form
                ClearForm();
            }}
          >
            新加
          </button>
        </div>
      </div>
    )
  }


// issue list 
function IssueList({
  setIsEdit, 
  setEditId, 
  setOpen, 
  issueList, 
  setIssueList, 
  setNewJobForm, 
  setJobDetail, 
  setEditItemInfo, 
  filterObj, 
  setPromptCompleteIssueRelatedJob,
  setRelatedJobId
}) {
  

    // handle issue resolve 
    const handleIssueResolve = (e, item) => {
        let issueID = e.target.dataset.id;

        // create POST request to submit issue
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/status";
        let body = {
            "issueID": issueID,
            "status": "Resolved",
            "staffID": localStorage.getItem("userid"),
        };

        if (item.status == 'Pending') {
          body["status"] = "In Progress";
        }
        
        let headers = {
          "Content-Type": "application/json"
        };
        fetch(url, 
            {
                method: "PUT",
                body: JSON.stringify(body),
                headers: headers
            }
        ).then(response => {
            if (response.status === 200) {
                if (body["status"] == "Resolved") {
                  alert("工單狀態已更新")

                  return response.json();

                } else {
                  
                  if (window.confirm("工單狀態已更新, 需要生成工作嗎?")) {

                    let jobData = {
                      "issueID": issueID,
                      "locationName": item.locationName,
                      "assignedTo": localStorage.getItem("userid"),
                      "meta": {
                        "clientRemark": item.remarks + " (工單編號: " + item.humanReadableId + ")",
                        "estimatedDate": GetTodayDateStr(),
                      }
                    }

                    if (item.locationID) {
                      jobData["locationIDs"] = [item.locationID];
                    }
                    
                    // set job detail
                    setJobDetail(jobData)
                    setNewJobForm(true);
                  }
                }
                
                // refetch issue list
                retrieveIssueList({setIssueList});
                
            } else {
                alert("事件解決失敗");
            }
        }).then(data => {
          console.log(data);
          // check if jobID is available then prompt user to complete job
          if (data && data.jobID) {
            setPromptCompleteIssueRelatedJob(true);
            setRelatedJobId(data.jobID);
          }

          // refetch issue list
          retrieveIssueList({setIssueList});
        }).catch(error => {
            console.log(error);
            alert("事件解決失敗");
        });
    }

    // handle job creation 
    const handleJobCreation = (e, item) => {
    
      let jobData = {
        "issueID": item._id,
        "locationName": item.locationName,
        "assignedTo": localStorage.getItem("userid"),
        "meta": {
          "clientRemark": item.remarks + " (工單編號: " + item.humanReadableId + ")",
          "estimatedDate": GetTodayDateStr(),
        }
      }

      if (item.locationID) {
        jobData["locationIDs"] = [item.locationID];
      }
      
      // set job detail
      setJobDetail(jobData)
      setNewJobForm(true);

    }

    // handle issue cancellation
    const handleIssueCancel = (e) => {
        let issueID = e.target.dataset.id;
        
        // create POST request to submit issue
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/status";
        let body = {
            "issueID": issueID,
            "status": "Canceled"
        };
        let headers = {
          "Content-Type": "application/json"
        };
        fetch(url, 
            {
                method: "PUT",
                body: JSON.stringify(body),
                headers: headers
            }
        ).then(response => {
            if (response.status === 200) {
                alert("事件已取消");
                // refetch issue list
                retrieveIssueList({setIssueList});
            } else {
                alert("事件取消失敗");
            }
        }).catch(error => {
            console.log(error);
            alert("事件取消失敗");
        });
    }

    // handle reopening of issue
    const handleIssueReopen = (e) => {
        let issueID = e.target.dataset.id;
        

        // create POST request to submit issue
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/status";
        let body = {
            "issueID": issueID,
            "status": "Pending"
        };
        let headers = {
          "Content-Type": "application/json"
        };
        fetch(url, 
            {
                method: "PUT",
                body: JSON.stringify(body),
                headers: headers
            }
        ).then(response => {
            if (response.status === 200) {
                alert("事件已重開");
                // refetch issue list
                retrieveIssueList({setIssueList});
                
            } else {
                alert("事件重開失敗");
            }
        }).catch(error => {
            console.log(error);
            alert("事件重開失敗");
        });
    }

    // check if users in mobile view
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (window.innerWidth < 640) {
            setIsMobile(true);
        }
    }, []);


    return (
        <ul role="list" className="divide-y divide-gray-100">
          {issueList.map((item) => (
            <li key={item.id} className="flex items-center justify-between gap-x-6 py-5">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900 skip-trans">{item.locationName}</p>
                  <p
                    className={classNames(
                      statuses[item.status],
                      'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                    )}
                  >
                    {statusDisplayNamePair[item.status]}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  {/* <p className="whitespace-nowrap">
                    Due on <time dateTime={item.dueDateTime}>{item.dueDate}</time>
                  </p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg> */}
                  <p className="sm:truncate whitespace-nowrap">創建日期 {item.createdAt}</p>
                  <p className="sm:truncate text-white hidden"> {item._id}</p>
                </div>
                <div
                  className="flex mt-1 sm:flex-row flex-col"
                >
                  {item.humanReadableId && (
                    <div
                      className="mr-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                    >
                      <span
                        className="inline-block rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                      >
                        工單編號: {item.humanReadableId}
                      </span>
                    </div>
                  )}
                  {item.name ? (
                    <div
                      className="mr-1 sm:mt-0 mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                    >
                      <span
                        className="skip-trans inline-block max-w-[250px] truncate whitespace-nowrap rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                      >
                        創建: {item.name}
                      </span>
                    </div>
                  ):(
                    item.assignerEmail && (
                      <div
                        className="mr-1 sm:mt-0 mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                      >
                        <span
                          className="skip-trans inline-block max-w-[250px] truncate whitespace-nowrap rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                        >
                          創建: {item.assignerEmail}
                        </span>
                      </div>
                    )
                  )}
                  {item.staffID && (
                    <div
                      className="mr-1 sm:mt-0 mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                    >
                      <span
                        className="inline-block max-w-[250px] truncate whitespace-nowrap rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                      >
                        負責: {item.staffID}
                      </span>
                    </div>
                  )}
                  
                </div>
                {filterObj?.description && (
                  <div
                    className="mt-1 sm:flex-row flex-col"
                  >
                    <div
                      className="mr-1 sm:mt-0 mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                    >
                      <span
                        className="inline-block max-w-[100%] truncate whitespace-nowrap rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                        id="issue-list-description"
                      >
                        描述: {item.remarks}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <a
                    data-id={item._id}
                    onClick={() => {
                        setIsEdit(true);
                        setEditId(item._id);
                        setOpen(true);
                        setEditItemInfo(item);
                        
                    }}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block cursor-pointer"
                >
                  查看<span className="sr-only">, {item.name}</span>
                </a>
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {isMobile && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                                data-id={item._id}
                                onClick={() => {
                                    setIsEdit(true);
                                    setEditId(item._id);
                                    setOpen(true);
                                    setEditItemInfo(item);
                                }}
                                className={classNames(
                                  active ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                )}
                            >
                              查看<span className="sr-only">, {item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      {item.status != "Resolved" && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={(e) => handleIssueResolve(e, item)}
                            data-id={item._id}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                            )}
                          >
                            {item.status == "Pending" && item.status != "Resolved" ? "接受工單" : "完成工單"}<span className="sr-only">, {item.name}</span>
                          </a>
                        )}
                      </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={handleIssueCancel}
                            data-id={item._id}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                            )}
                          >
                            取消<span className="sr-only">, {item.name}</span>
                          </a>
                        )}
                      </Menu.Item>
                      {item.status !== "Pending" && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={handleIssueReopen}
                              data-id={item._id}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900, cursor-pointer'
                              )}
                            >
                              重開<span className="sr-only">, {item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={(e) => handleJobCreation(e, item)}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900, cursor-pointer'
                              )}
                            >
                              生成工作<span className="sr-only">, {item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </li>
          ))}
        </ul>
    )
}

// fetch issue list
const retrieveIssueList = ({setIssueList, page=1, filterObj={}}) => {
  // create POST request to submit issue
  let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issues?organizationID=" + localStorage.getItem("organizationId") + "&page=" + page;
  let headers = {
    "Content-Type": "application/json"
  };

  // check if filterObj has description
  if (filterObj.hasOwnProperty("description") && filterObj.description !== "") {
    url += "&description=" + filterObj.description;
  }

  // check if filterObj has location
  if (filterObj.hasOwnProperty("location")) {
    if (filterObj['location'] && filterObj['location']["_id"] !== "all") {
      url += "&location=" + filterObj['location']["_id"];
    }
  }

  // check if productType is available
  if (filterObj.hasOwnProperty("productType")) {
    if (filterObj['productType'] && filterObj['productType']["value"] !== "") {
      url += "&productType=" + filterObj['productType']["value"];
    }
  }

  // check if filterObj has status
  if (filterObj.hasOwnProperty("status") && filterObj.status !== "") {
    url += "&status=" + filterObj.status;
  }

  // check if filterObj has humanReadableId
  if (filterObj.hasOwnProperty("humanReadableId") && filterObj.humanReadableId !== "") {
    url += "&humanReadableId=" + filterObj.humanReadableId;
  }

  // create fetch request
  fetch(url, 
      {
          method: "GET",
          headers: headers
      }
  ).then(response => {
      if (response.status === 200) {
          response.json().then(rawData => {
              let data = rawData['issues'];
              setIssueList(data);
          });
      } else {
          console.log("Error: " + response.status);
      }
  }).catch(error => {
      console.log(error);
  });
}

// get today's date in YYYY-MM-DD format
function GetTodayDateStr() {
  // get today's date in YYYY-MM-DD format
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  let todayString = year + "-" + month + "-" + day;

  return todayString;
}

// a search bar container 
function SearchBarContainer({filterObj, setFilterObj}) {
  

  return (
    <div
      className='mt-4'
    >
      <div>
        <h3
          className='text-sm font-semibold leading-6 text-gray-900 mb-2'
        >
          篩選
        </h3>
        <div
          className='flex sm:items-center sm:gap-x-2 gap-y-1 flex-col sm:flex-row flex-wrap'
        >
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="whitespace-nowrap sm:max-w-[180px] text-left inline-flex  justify-between w-full gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {filterObj.description ? filterObj.description : "事件描述"}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute left-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <div className="m-2">
                      <textarea
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={filterObj.description}
                        rows={"5"}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          setFilterObj({...filterObj, description: e.target.value});
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    </div>
                    )}
                  </MenuItem>
                  
                </div>
              </MenuItems>
            </Transition>
          </Menu>
          <div className="sm:mt-0 mt-2">
            <select
              name="status"
              value={filterObj.status}
              onChange={(e) => setFilterObj({...filterObj, status: e.target.value})}
              className="skip-trans font-bold block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
            >
              <option value="">所有狀態</option>
              <option value="Pending">待處理</option>
              <option value="In Progress">處理中</option>
              <option value="Resolved">已解決</option>
              <option value="Canceled">已取消</option>
              <option value="Cancel Requested">客戶提出工單取消</option>
            </select>
          </div>
          <Menu as="div" className="relative inline-block text-left mt-1 sm:mt-0">
            <div>
              <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap">
                {filterObj.humanReadableId ? filterObj.humanReadableId : "工單編號"}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <div className="m-2">
                      <input
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={filterObj.humanReadableId}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          setFilterObj({...filterObj, humanReadableId: e.target.value});
                        }}
                      />
                    </div>
                    )}
                  </Menu.Item>
                  
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <LocationSearchMenu filterObj={filterObj} setFilterObj={setFilterObj} />
          <ProductSearchMenu filterObj={filterObj} setFilterObj={setFilterObj} />
          <button
            className={`${Object.keys(filterObj).length > 0 ? 'bg-red-400 cursor-pointer hover:bg-red-500' : 'bg-gray-300'} text-white rounded-md px-2 py-2 mt-1 sm:mt-0 text-sm duration-300  `}
            onClick={() => setFilterObj({
              "status": "",
              "description": "",
              "location": {"_id": "all", "name": "所有地點"},
              "productType": {"label": "所有產品", "value": ""},
            })}
          >
            清除篩選設定
          </button>
        </div>
      </div>

    </div>
  )
}


// location search menu
function LocationSearchMenu({filterObj, setFilterObj}) {
  const [query, setQuery] = useState('')
  // const [selectedPerson, setSelectedPerson] = useState(null)
  const [location, setLocation] = useState([])
  // fetch existing location list 
  useEffect(() => {
    // create GET Request for existing location list
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/locations?organizationID=" + localStorage.getItem("organizationId") + "&status=active";
    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            // add 所有地點
            data['locations'].unshift({"id": "all", "name": "所有地點"});

            // add traditional chinese name to name if langVar exists and langVar.tc exist
            data['locations'].forEach((location) => {
              if (location.hasOwnProperty("langVar")) {
                if (location.langVar.hasOwnProperty("tc")) {
                  location.name = location.name + "(" + location.langVar.tc + ")";
                }
              }
            });
            
            setLocation(data['locations']);
          });
        } else {
          console.log("Error: " + response.status);
        }
      }
    ).catch(error => {
        console.log(error);
    });
  }, []);


  const filteredPeople =
    query === ''
      ? location
      : location.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        })

  

  return (
    <Combobox
      as="div"
      value={filterObj.location}
      onChange={(item) => {
        setQuery('')
        setFilterObj({...filterObj, location: item})
      }}
    >
      {/* <Label className="block text-sm font-medium leading-6 text-gray-900">Assigned to</Label> */}
      <div className="relative skip-trans">
        <ComboboxInput
          className="mt-1 sm:mt-0  w-full font-semibold rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          displayValue={(item) => item?.name}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredPeople.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((item) => (
              <ComboboxOption
                key={item.id}
                value={item}
                className={({ focus }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ focus, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{item.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          focus ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  )
}


// prompt users to complete issue related job 
function PromptCompleteIssueRelatedJob({show, setShow, relatedJobId}) {
  // setShow to false after a set time
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  // handle job progress change
  const handleJobProgressChange = (e) => {
    // if relatedJobId is "" return error and close the prompt
    if (!relatedJobId) {
      alert("工作編號不存在");
      setShow(false);
      return;
    }

    // create PUT request to update job status
    var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress";
    var data = {
      jobID: relatedJobId,
      status: "完成",
      assignedTo: localStorage.getItem('userid'),
      userID: localStorage.getItem('userid')
    };
    
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('idToken')
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (response.status === 200) {
        alert("工作狀態已更新");
        setShow(false);
      } else {
        // set error
        alert("工作狀態更新失敗");
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert("工作狀態更新失敗");
    });
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition show={show}>
            <div className="z-50 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <BriefcaseIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">更改工作狀態</p>
                    <p className="mt-1 text-sm text-gray-500">
                      你的工作狀態未完成, 需要我們為你更改工作狀態嗎?
                    </p>
                    <div className="mt-3 flex space-x-7">
                      <button
                        type="button"
                        className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={handleJobProgressChange}
                      >
                        是
                      </button>
                      <button
                        type="button"
                        className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setShow(false)}
                      >
                        否
                      </button>
                    </div>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false)
                      }}
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

// Search menu for product type
function ProductSearchMenu({filterObj, setFilterObj}) {
  // useEffect(() => {
  //   setFilterObj({...filterObj,
  //     productType: {"label": "所有產品", "value": ""}
  //   });
  // }, []);

  const [prodList, setProdList] = useState([
    { "label": "所有產品", "value": "" },
    { "label": "CABD 公共天線系統", "value": "CABD" },
    { "label": "CCTV 閉路電視", "value": "CCTV" },
    { "label": "Data Switch 數據交換機", "value": "Data Switch" },
    { "label": "Door Lock 門鎖", "value": "Door Lock" },
    { "label": "E-Casual 電子便捷", "value": "E-Casual" },
    { "label": "Email 電子郵件", "value": "Email" },
    { "label": "Firewall 防火牆", "value": "Firewall" },
    { "label": "Front Office/Property Management System (PMS) 酒店前台管理系統", "value": "Front Office System/Property Management System (PMS)" },
    { "label": "Internet Gateway 網絡閘道", "value": "Internet Gateway" },
    { "label": "Kiosk 自助服務機", "value": "Kiosk" },
    { "label": "Network 網絡", "value": "Network" },
    { "label": "NSS Server 通知系統", "value": "NSS Server" },
    { "label": "POS 餐飲系統", "value": "POS" },
    { "label": "PC 個人電腦", "value": "PC" },
    { "label": "PABX 電話系統", "value": "PABX" },
    { "label": "Telephone Set 電話機", "value": "Telephone Set" },
    { "label": "TV 電視", "value": "TV" },
    { "label": "UPS 不間斷電源", "value": "UPS" },
    { "label": "Vending Machine 自助售貨機", "value": "Vending Machine" },
    { "label": "Voice Logger 語音記錄器", "value": "Voice Logger" },
    { "label": "Website 網站", "value": "Website" },
    { "label": "WiFi/AP 無線網絡", "value": "WiFi/AP" },
    { "label": "Voice Mail System 語音信箱系統", "value": "i-Message Voice Mail System" },
    { "label": "iPanel/iSignage 電子廣告板", "value": "i-Panel" },
    { "label": "Call Accounting System 計費系統", "value": "i-interface Call Accounting System" },
    { "label": "Other Products & Services 其他產品及服務", "value": "Other Products & Services" }
  ])
  const [query, setQuery] = useState('')

  const filteredList = 
    query === ''
      ? prodList
      : prodList.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox
      as="div"
      value={filterObj.productType}
      onChange={(item) => {
        setQuery('')
        setFilterObj({...filterObj, productType: item})
      }}
      className="skip-trans"
    >
      <div className="relative">
        <ComboboxInput
          className="mt-1 sm:mt-0 w-full font-semibold rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          displayValue={(item) => item?.label}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredList.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredList.map((item) => (
              <ComboboxOption
                key={item.value}
                value={item}
                className={({ focus }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ focus, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{item.label}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          focus ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  )

}

// a list of chips to display suggested replies for ths issue status update
function IssueStatusUpdateSuggestion({setIssueUpdateDesc, remarks, productType, locationID}) {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  
  // Create a memoized version of the fetch function
  const fetchSuggestions = useCallback(
    debounce(async (remarks, productType, locationID) => {
      if (!remarks || !productType || !locationID) {
        return;
      }

      setLoading(true);

      try {
        const url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/job/issue-status-update-assistant";
        const body = {
          clientDescription: remarks,
          productType,
          locationID,
          userID: localStorage.getItem("userid")
        };

        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (response.status === 200) {
          const data = await response.json();
          setSuggestions(data);
        } else {
          console.log("Error: " + response.status);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 500),  // 500ms debounce
    []  // This function never needs to be re-created
  );

  // Use a single effect with a combined dependency
  useEffect(() => {
    fetchSuggestions(remarks, productType, locationID);
  }, [fetchSuggestions, remarks, productType, locationID]);



  return (
    <>
      
        <div className="mt-2">
          <h3 className="text-sm font-semibold leading-6 text-gray-900">建議回覆:</h3>
          {suggestions.length > 0 ? (
          <div className="mt-1 space-y-2">
            {suggestions.map((suggestion) => (
              <button
                type="button"
                className="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-semibold text-gray-900 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 duration-300 text-left"
                onClick={() => setIssueUpdateDesc(suggestion)}
              >
                {suggestion}
              </button>
            ))}
          </div>
          ) : (
            <div className="mt-1 space-y-2">
              <p className="text-sm text-gray-500 animate-pulse">
                正在生成建議回覆...
              </p>
            </div>
          )}
        </div>
      
    </>
  )
}

