import { useState } from "react";
import { ReactGrid, Column, Row } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import GlobalVars from "../../Config";

/* global NDEFReader */
export default function TestPage() {
  const [numRows, setNumRows] = useState(7);
  const [numColumns, setNumColumns] = useState(2);

  // Define the data structure for the people (name and surname)
  const getData = () => {
    const data = [];
    for (let i = 0; i < numRows; i++) {
      const row = {};
      for (let j = 1; j <= numColumns; j++) {
        row[`column${j}`] = "";
      }
      data.push(row);
    }
    return data;
  };

  // Define the columns for the grid
  const getColumns = () => {
    const columns = [];
    for (let i = 1; i <= numColumns; i++) {
      columns.push({ columnId: `column${i}`, width: 150 });
    }
    return columns;
  };

  // Generate the rows for the grid from the array of people
  const getRows = (data) => [
    // headerRow,
    ...data.map((item, idx) => ({
      rowId: idx,
      cells: Array.from({length: numColumns}, (_, i) => ({
        type: "text",
        text: item[`column${i + 1}`]
      }))
    }))
  ];

  // Use React's useState hook to manage the people data
  const [data, setData] = useState(getData());
  
  // Get the rows and columns for the grid
  const rows = getRows(data);
  const columns = getColumns();

  const applyChangesToData = (changes, prevData) => {
    changes.forEach((change) => {
      const itemIndex = change.rowId;
      const fieldName = change.columnId;
      prevData[itemIndex][fieldName] = change.newCell.text;
    });
    return [...prevData];
  };

  const handleChanges = (changes) => {
    setData((prevData) => applyChangesToData(changes, prevData));
  };

  const handleFillData = () => {
    // fill in randow numbers based on numRows and numColumns using Math.random()
    let newData = Array(numRows).fill().map(() => ({}));
    for (let i = 0; i < numRows; i++) {
      for (let j = 1; j <= numColumns; j++) {
        newData[i][`column${j}`] = Math.random().toString();
      }
    }
    setData(newData);
  };

  const handleClearData = () => {
    setData(getData());
  };

  const [smartFillLoading, setSmartFillLoading] = useState(false);
  const handleSmartFill = () => {
    let url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/smart-spreadsheet/fill-missing-data";
    let submitData = {
      data: data,
      userID: localStorage.getItem("userid")
    };
    setSmartFillLoading(true);
    fetch(url, {
      method: "POST",
      body: JSON.stringify(submitData),
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.response);
      setData(data.response);
      setSmartFillLoading(false);
    })
    .catch(error => console.error(error));
  };

  return (
    <>
      <button onClick={handleFillData} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">Fill Data</button>
      <button onClick={handleClearData} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Clear Data</button>
      <button onClick={handleSmartFill} className="ml-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
        {smartFillLoading ? "Loading..." : "Smart fill"}
      </button>
      <ReactGrid
        rows={rows}
        columns={columns}
        onCellsChanged={handleChanges}
        enableFiltering={true}
        enableFillHandle={true}
      />
    </>
  )
}