import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { 
    ChevronDownIcon 
} from '@heroicons/react/20/solid'
import { useRef, useState, useEffect } from 'react'

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

export default function EventPopover({ event, needHeight=false }) {
    const menuRef = useRef(null);
    const menuItemsRef = useRef(null);
    const [alignment, setAlignment] = useState('right-0');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
          const checkOverflow = () => {
            if (menuRef.current && menuItemsRef.current) {
              const buttonRect = menuRef.current.getBoundingClientRect();
              const menuItemsRect = menuItemsRef.current.getBoundingClientRect();
    
              let horizontal = buttonRect.left + menuItemsRect.width > window.innerWidth ? 'right-0' : 'left-0';
              let vertical = buttonRect.bottom + menuItemsRect.height > window.innerHeight ? 'bottom-full' : 'top-0';
    
              setAlignment({ horizontal, vertical });

              // scroll to the view of the menuItems
              menuItemsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          };
    
          // Delay to ensure MenuItems are rendered
          setTimeout(checkOverflow, 0);
        }
    }, [isOpen]);

    const [dateDisplay, setDateDisplay] = useState()
    useEffect(() => {

        // return if undefined
        if (!event.startDateTime || !event.endDateTime) {
            return
        }

        // check if same date for startDateTime and endDateTime
        if (event.startDateTime.split('T')[0] === event.endDateTime.split('T')[0]) {
            setDateDisplay(event.startDateTime.split('T')[0] + ' ' + event.startTime + ' - ' + event.endTime)
        } else {
            setDateDisplay(event.startDateTime.split('T')[0] + ' ' + event.startTime + ' - ' + event.endDateTime.split('T')[0] + ' ' + event.endTime)
        }
    }, [event])

    const [openDialog, setOpenDialog] = useState(false);
    useEffect(() => {
        if (isOpen) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }
    }, [isOpen])

    useEffect(() => {
        console.log(openDialog);
    }, [openDialog])
    
    return (
        <>        
            <Menu as="div" className="relative inline-block text-left w-full" ref={menuRef}>
                {({open}) => {
                    // Update isOpen state when open changes
                    if (open !== isOpen) {
                        setIsOpen(open);
                    }
                return (
                    <>
                        <div>
                            <MenuButton className={`inline-flex w-full justify-start gap-x-1.5 text-left ${needHeight ? "h-[32px]" : ""}`} >
                                <a className="group absolute inset-1 flex flex-col overflow-hidden rounded-lg bg-indigo-100 px-1 py-1 text-xs leading-5 hover:bg-indigo-200 cursor-pointer border border-white">
                                    <p className="order-1 font-semibold text-gray-700">{event.locationName === "Multiple Locations" ? "多個工作地點" : event.locationName}</p>
                                    {!event?.allDay && (
                                        <p className="text-gray-500 group-hover:text-gray-700">
                                            {event.date === event.startDateTime.split('T')[0] && (
                                                <time dateTime={event.startDateTime}>{event.startDateTime.split('T')[1]}</time>
                                            )}
                                        </p>
                                    )}
                                </a>
                            </MenuButton>
                        </div>
                        <MenuItems
                            transition
                            className={`hidden sm:block absolute px-4 py-2 z-50 mt-2 w-[350px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in ${alignment.horizontal} ${alignment.vertical}`}
                            ref={menuItemsRef}
                        >
                            <div className="py-1 flex flex-col">
                                <span className="text-gray-600 font-bold">
                                    {event.locationName}
                                </span>
                                <span className="text-gray-500 text-sm">
                                    {event.assignedToName}
                                </span>
                                <hr
                                    className="my-1"
                                ></hr>
                                {!event?.allDay &&(
                                <>
                                    <span className="text-gray-500 text-sm">
                                        {dateDisplay}
                                    </span>
                                    <hr
                                        className="my-1"
                                    ></hr>
                                </>
                                )}
                                <span className="text-gray-500 text-sm">{event.typeName}</span>
                                <hr
                                    className="my-1"
                                ></hr>
                                <div
                                    className="flex justify-end"
                                >
                                    {event.type === "job" && (
                                        <a
                                            href={`/jobs/${event._id}`}
                                            className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            查看詳細
                                        </a>
                                    )}
                                </div>
                            </div>
                        </MenuItems>
                    </>
                );
                }}
            </Menu> 
            <DetailDialog open={openDialog} event={event} setOpen={() => setOpenDialog(false)} />
        </>
    )

}


function DetailDialog({ open, setOpen, event }) {

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50 block sm:hidden ">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mt-3">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  {event.locationName}
                </DialogTitle>
                <div className="mt-2 space-y-2">
                  <span className="text-gray-500 text-sm block">
                    {event.startTime} - {event.endTime}
                  </span>
                  {event.date && (
                    <>
                      <span className="text-gray-500 text-sm block">
                        {event.date}
                      </span>
                      <hr className="my-1"></hr>
                    </>
                  )}
                  <span className="text-gray-500 text-sm block">{event.typeName}</span>
                  <hr className="my-1"></hr>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <a
                href={`/jobs/${event._id}`}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                查看詳細
              </a>
              <button
                onClick={() => setOpen(false)}
                className="mt-1 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                關閉
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}


