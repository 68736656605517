import { useEffect, useState } from 'react'
import {
  XMarkIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import GlobalVars from '../../../../Config';

export default function MinibarPosting({
  saveTriggered,
  setSaveTriggered,
  setOpenModuleNotification,
  jobID
}) {

  const [productList, setProductList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('')

  const [total, setTotal] = useState(0)
  const [count, setCount] = useState(0)

  const [isPosted, setIsPosted] = useState(false)  // if the posting is submitted
  
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/minibar-posting-item" + "?organizationID=" + sessionStorage.getItem('organizationId')
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data)
      setProductList(data)
    })
    .catch((error) => {
      console.error('Error:', error)
    })
  }, [])

  const [filteredProductList, setFilteredProductList] = useState(productList)
  useEffect(() => {
    // update filteredProductList
    setFilteredProductList(productList.filter(product =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    ))
  }, [productList, searchQuery])

  useEffect(() => {
    // calculate total
    let sum = 0
    productList.forEach(product => {
      sum += product.price * product.quantity
    })
    setTotal(sum)

    // calculate count
    let c = 0
    productList.forEach(product => {
      c += product.quantity
    })
    setCount(c)
  }, [productList])

  useEffect(() => {
    if (saveTriggered && !isPosted) {
      setSaveTriggered(false);

      setOpenModuleNotification({
        "display": true,
        "type": "loading",
      })

      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/minibar-posting"

      let data = {
        "productList": productList.filter(product => product.quantity > 0),
        "organizationID": sessionStorage.getItem('organizationId'),
        "userID": localStorage.getItem('userid'),
        "jobID": jobID,
      }

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('idToken')
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data)
        setOpenModuleNotification({
          "display": true,
          "type": "success",
        })
      })
      .catch((error) => {
        console.error('Error:', error)
        setOpenModuleNotification({
          "display": true,
          "type": "fail",
        })
      })
    }
  }, [saveTriggered])

  useEffect(() => {
    // query the jobID and check if the posting is submitted
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/minibar-posting" + "?jobID=" + jobID
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
    .then(response => response.json())
    .then(data => {
      setIsPosted(!isNaN(data.message) || data.message === "completed")
    })

  }, [])

  return (
    <div className="">
      {isPosted ? (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                報Bar已提交, 不能再次提交
              </p>
            </div>
          </div>
        </div>
      ):(
        <>
          <div
            className="flex items-center justify-between mb-4"
          >
            <input
              type="text"
              placeholder="搜尋產品..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="p-2 border rounded-md w-full text-sm transition duration-300"
            />
            {searchQuery && (
              <button
                onClick={() => {
                  setSearchQuery('')
                }}
                className="ml-2 px-2 py-2 text-lg font-semibold text-gray-700 rounded cursor-pointer"
              >
                <XMarkIcon 
                  className="h-5 w-5"
                />
              </button>
            )}
          </div>
          <div
            className='mb-20'
          >
            {filteredProductList.map((product, index) => (
              <div key={product.id} className="flex items-center justify-between mb-4 ">
                <div>
                  <h3 className="font-semibold">{product.name}</h3>
                  <p className="text-sm text-gray-500">${product.price}</p>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => {
                      const newProductList = productList.map(product => {
                        if (product.id === productList[index].id && product.quantity > 0) {
                          return { ...product, quantity: product.quantity - 1 }
                        }
                        return product
                      })
                      setProductList(newProductList)
                    }}
                    className={`px-4 py-2 text-lg font-semibold text-gray-700 rounded-xl ${product.quantity === 0 ? 'bg-white' : 'bg-gray-200'}`}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={product.quantity}
                    onChange={(e) => {
                      const newProductList = productList.map(p => {
                        if (p.id === product.id) {
                          return { ...p, quantity: parseInt(e.target.value, 10) || 0 }
                        }
                        return p
                      })
                      setProductList(newProductList)
                    }}
                    className="mx-2 w-16 text-center border rounded-md"
                  />
                  <button
                    onClick={() => {
                      const newProductList = productList.map(p => {
                        if (p.id === product.id) {
                          return { ...p, quantity: p.quantity + 1 }
                        }
                        return p
                      })
                      setProductList(newProductList)
                    }}
                    className="px-4 py-2 text-lg font-semibold text-white bg-black rounded-xl"
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* display total at the bottom of the component as a floating bar */}
          <div className="fixed bottom-16 left-0 right-0 p-4">
            <div
              className='bg-gray-50 border-gray-200 p-4 rounded-md shadow-xs flex items-center justify-between shadow-md'
            >
              <h3 className="font-semibold text-sm">總數: ${total}  數量: {count}</h3>
              <button
                onClick={() => {
                  setProductList(productList.map(product => ({ ...product, quantity: 0 })))
                }}
                className="px-4 py-2 border border-red-500 text-red-500 rounded-md text-sm hover:bg-red-500 hover:text-white duration-300"
              >清除所有</button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const SubmitPosting = () => {
  console.log('submit posting')
}

