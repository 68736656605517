import React, { createContext, useState, useEffect, useMemo } from 'react';
import GlobalVars from './Config';

// Create the context
export const UserContext = createContext();

// fetch request to get user role
const getUserInfo = async (userID, organizationID, setGlobalUserRole, setGlobalUserPlan) => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?userID=" + userID + "&organizationID=" + organizationID;
    fetch(url)
    .then(response => response.json())
    .then(data => {
        if (data.user['role']) {
            // store user role in context api
            setGlobalUserRole(data.user['role']);
        }
        if (data.user['billing']) {
            setGlobalUserPlan(data.user['billing']);
        } else {
            setGlobalUserPlan("basic");
        }
    });
};


// Create a provider component
export const UserContextProvider = ({ children }) => {
    const [tempGlobalUserRole, setGlobalUserRole] = useState(null);
    const [tempGlobalUserPlan, setGlobalUserPlan] = useState(null);
    useEffect(() => {
        // return if userRole is already set
        if (tempGlobalUserRole !== null) {
            return;
        }

        const userID = localStorage.getItem("userid");
        const organizationID = localStorage.getItem("organizationId");

        // return error if userID is not set
        if (!userID) {
            console.error("User ID not set");
            return;
        }

        // return error if organizationID is not set
        if (!organizationID) {
            console.error("Organization ID not set");
            return;
        }

        // update user role in context api
        getUserInfo(userID, organizationID, setGlobalUserRole, setGlobalUserPlan);
    }, [tempGlobalUserRole, tempGlobalUserPlan]);

    const globalUserRole = useMemo(() => tempGlobalUserRole, [tempGlobalUserRole]);
    const globalUserPlan = useMemo(() => tempGlobalUserPlan, [tempGlobalUserPlan]);

    return (
        <UserContext.Provider value={{globalUserRole, globalUserPlan}}>
        {children}
        </UserContext.Provider>
    );
};