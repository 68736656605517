import { useState } from 'react'


export default function Leave() {
    // pre fill start date and end date as today
    let today = new Date();
    let todayDate = today.getDate();
    let todayMonth = today.getMonth() + 1;
    let todayYear = today.getFullYear();
    let todayString = todayYear + "-" + todayMonth + "-" + todayDate;

    const [startDate, setStartDate] = useState(todayString);
    const [endDate, setEndDate] = useState(todayString);

    return (
        <div className="flex flex-1 flex-col justify-between">
            <div className="space-y-6">
                <div>
                    <label
                        htmlFor="reasons"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        原因
                    </label>
                    <div className="mt-2">
                        <select
                            name="reasons"
                            id="reasons"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value="sick_leave">病假</option>
                            <option value="annual_leave">年假</option>
                            <option value="maternity_leave">產假</option>
                            <option value="no_pay_leave">無薪假</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label
                    htmlFor="remarks"
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    補充
                    </label>
                    <div className="mt-2">
                    <textarea
                        type="text"
                        name="remarks"
                        id="remarks"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>
                <div>
                    <label
                    htmlFor="start-date"
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    開始日期
                    </label>
                    <div className="mt-2">
                    <input
                        type="date"
                        name="start-date"
                        id="start-date"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                    />
                    </div>
                </div>
                <div>
                    <label
                    htmlFor="start-date"
                    className="block text-sm font-medium leading-6 text-gray-900"
                    >
                    結束日期
                    </label>
                    <div className="mt-2">
                    <input
                        type="date"
                        name="end-date"
                        id="end-date"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={endDate}
                        onChange={(event) => setEndDate(event.target.value)}
                    />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="half-day-leave"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        半日假
                    </label>
                    <div className="mt-2">
                        <select
                            name="half-day-leave"
                            id="half-day-leave"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value="morning">早上</option>
                            <option value="afternoon">下午</option>
                            <option value="full-day" selected>全日</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}