import { Transition } from '@headlessui/react'
import { 
  CheckCircleIcon,
  ArrowPathIcon,
  FaceFrownIcon,
} from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

export default function ModuleNotification({show, setShow}) {
  const renderComponent = () => {
    switch (show.type) {
      case 'success':
        return <SuccessComponent />;
      case 'fail':
        return <FailComponent />;
      case 'loading':
        return <LoadingComponent />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition show={show.display}>
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
              <div className="p-4">
                <div className="flex items-start">
                  <div
                    className="flex-shrink-0 flex-grow"
                  >
                    {renderComponent()}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(prevState => ({ ...prevState, display: false }))
                      }}
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

function SuccessComponent() {
  return (
    <div
      className="flex items-start"
    >
      <div>
        <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-400" />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900 text-nowrap">成功儲存!</p>
        {/* <p className="mt-1 text-sm text-gray-500 text-nowrap">Anyone with a link can now view this file.</p> */}
      </div>
    </div>
  )
}

// fail component
function FailComponent() {
  return (
    <div className="flex items-start">
      <div>
        <FaceFrownIcon aria-hidden="true" className="h-6 w-6 text-red-400" />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900 text-nowrap">儲存失敗!</p>
      </div>
    </div>
  )
}

// loading component
function LoadingComponent() {
  return (
    <div className="flex items-start">
      <div>
        <ArrowPathIcon aria-hidden="true" className="h-6 w-6 text-indigo-400 animate-spin" />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900 text-nowrap">儲存中...</p>
      </div>
    </div>
  )
}
