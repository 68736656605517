import { Fragment } from 'react'
import { 
    Menu, 
    Transition, 
    Dialog,
    MenuButton,
    MenuItems,
    MenuItem,
} from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import ApplicationShell from '../../Components/ApplicationShell'
import { useState, useEffect, useRef } from 'react'
import GlobalVars from '../../Config'
import SlideOvers from '../../Components/SlideOvers'
import {MultiSelect} from "react-multi-select-component";
import React from 'react'
import SubModuleSelection from './Components/SubModuleSelection'

const availableStatus = [
    {"label": "新工作", "value": "New Job"},
    {"label": "待分配", "value": "Awaiting Assignment"},
    {"label": "待確認", "value": "Awaiting Confirmation"},
    {"label": "未開始", "value": "Not Started"},
    {"label": "進行中", "value": "In Progress"},
    {"label": "中斷", "value": "Interrupted"},
    {"label": "待續", "value": "Temporarily Suspended"},
    {"label": "待檢查", "value": "Awaiting Inspection"},
    {"label": "待批准", "value": "Awaiting Approval"},
    {"label": "完成", "value": "Completed"},
    {"label": "拒絕", "value": "Rejected"},
    {"label": "取消", "value": "Cancelled"},
    {"label": "延期", "value": "Postponed"},
    {"label": "回公司", "value": "Returned to Office"},
    {"label": "緊急", "value": "Emergency"},
    {"label": "維修中", "value": "Under Repair"},
    {"label": "需要額外部件", "value": "Additional Parts Required"},
    {"label": "需要專家幫助", "value": "Expert Assistance Required"},
    {"label": "已轉移", "value": "Transferred"}
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TypeList() {
    // set document title 
    document.title = "類別列表";

    // set states
    const [typeList, setTypeList] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [pageHeadingTitle, setPageHeadingTitle] = useState("工作要求類別");
    const [tabs, setTabs] = useState([
        { name: '主類別', href: '#', current: true },
        { name: '工作要求', href: '#', current: false },
        { name: '提醒', href: '#', current: false}
      ]);
    const [selectedTab, setSelectedTab] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editID, setEditID] = useState(null);
    const [openEscalationSlideOver, setOpenEscalationSlideOver] = useState(false);

    // check for url argument for current tabs (t)
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedTab = urlParams.get('t');
        if (selectedTab && selectedTab !== "" && selectedTab !== "undefined") {
            let newTabs = tabs.map((tab) => {
                if (tab.name === selectedTab) {
                    return {
                        name: tab.name,
                        href: tab.href,
                        current: true,
                    }
                } else {
                    return {
                        name: tab.name,
                        href: tab.href,
                        current: false,
                    }
                }
            });

            // fallback to main type if the tab is not found
            if (newTabs.every((tab) => tab.current === false)) {
                newTabs[0].current = true;
            }

            setTabs(newTabs);
        }
    }, []);

    // states for reminder inputs 
    const [reminderInputs, setReminderInputs] = useState([{time: "", targets: []}]);    

    // form content states
    const [formContent, setFormContent] = useState({
        "chiName": "", 
        "engName": "",
        "type": "",
        "itemCode": "",
    });

    
    // fetch request to /api/v1/jobtypes
    useEffect(() => {
        // get current tabs 
        let currentTab = tabs.find((tab) => tab.current);

        setSelectedTab(currentTab['name']);

        let url;
        if (currentTab['name'] == "工作要求") {
            url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes?organizationID=${sessionStorage.getItem("organizationId")}&status=active`;
            fetch(url, {
                headers: {
                  "Authorization": sessionStorage.getItem("idToken")
                }
              })
            .then((response) => response.json())
            .then((rawData) => {
                let data = rawData['jobTypes'];
                setTypeList(data);
            }).catch(error => {
                console.log(error);
                alert("獲取類別失敗");
            });

            setPageHeadingTitle("工作要求類別");

        } else {  // /api/v1/maintypes
            url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/maintypes?organizationID=${sessionStorage.getItem("organizationId")}`;
            fetch(url)
            .then((response) => response.json())
            .then((rawData) => {
                let data = rawData['types'];
                setTypeList(data);
            }).catch(error => {
                console.log(error);
                alert("獲取類別失敗");
            });

            setPageHeadingTitle("主類別");
        }
        
    }, [tabs]);

    // handle edit mode
    const handleEditClick = (e) => {
        // open form 
        setOpenForm(true);

        // set edit mode to true
        setEditMode(true);

        // get current tabs
        let currentTab = tabs.find((tab) => tab.current);

        // fetch data from backend and update form content
        let url;
        if (currentTab['name'] == "主類別") {
            url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/maintypes?typeID=${e.target.dataset.id}`;
        } else {
            url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes?typeID=${e.target.dataset.id}`;
        }

        setEditID(e.target.dataset.id);

        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => response.json())
        .then((rawData) => {
            let data = rawData['type'];
            
            let formContent = {
                "chiName": data['langVar']['tc'], 
                "engName": data['name']
            };

            // update reminder inputs
            if (data.hasOwnProperty("reminder")) {
                setReminderInputs(data['reminder']);
            } else {
                setReminderInputs([{time: "", targets: []}]);
            }

            // update main type id
            if (currentTab['name'] == "工作要求") {
                formContent['mainType'] = data['mainTypeID'];
            }

            // set item code
            formContent['itemCode'] = data['itemCode'];

            // update form content
            setFormContent(formContent);

        }).catch(error => {
            console.log(error);
            alert("獲取類別失敗");
        });

    };

    // check url argument for id of job type and trigger handleEditClick
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const jobTypeID = urlParams.get('id');
        if (jobTypeID && jobTypeID !== "") {
            // set current tab to job type
            let newTabs = tabs.map((tab) => {
                if (tab.name === "工作要求") {
                    return {
                        name: tab.name,
                        href: tab.href,
                        current: true,
                    }
                } else {
                    return {
                        name: tab.name,
                        href: tab.href,
                        current: false,
                    }
                }
            });

            setTabs(newTabs);

            // open form 
            setOpenForm(true);

            // set edit mode to true
            setEditMode(true);

            // get current tabs
            let currentTab = tabs.find((tab) => tab.current);

            // fetch data from backend and update form content
            let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes?typeID=${jobTypeID}`;

            setEditID(jobTypeID);

            fetch(url, {
                headers: {
                  "Authorization": sessionStorage.getItem("idToken")
                }
              })
            .then((response) => response.json())
            .then((rawData) => {
                let data = rawData['type'];
                
                let formContent = {
                    "chiName": data['langVar']['tc'], 
                    "engName": data['name']
                };

                // update reminder inputs
                if (data.hasOwnProperty("reminder")) {
                    setReminderInputs(data['reminder']);
                } else {
                    setReminderInputs([{time: "", targets: []}]);
                }

                // update main type id
                if (currentTab['name'] == "工作要求") {
                    formContent['mainType'] = data['mainTypeID'];
                }

                // set item code
                formContent['itemCode'] = data['itemCode'];

                // update form content
                setFormContent(formContent);

            }).catch(error => {
                console.log(error);
                alert("獲取類別失敗");
            });
        }
    }, []);

    // handle delete click
    const handleDeleteClick = (e) => {
        // prompt user for confirmation
        let confirmation = window.confirm("確定要刪除類別嗎?");
        if (!confirmation) {
            return;
        }

        // get current tabs
        let currentTab = tabs.find((tab) => tab.current);

        // fetch data from backend and update form content
        let url;
        if (currentTab['name'] == "主類別") {
            url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/maintypes`;
        } else {
            url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes`;
        }

        let submitData = {
            "typeID": e.target.dataset.id,
        }

        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": sessionStorage.getItem("idToken")
            },
            body: JSON.stringify(submitData)
        }).then(response => {
            if (response.status === 200) {
                // alert
                alert("成功刪除類別");
                // reload the page
                window.location.reload();
            } else {
                console.log("Error: " + response.status);
                alert("刪除類別失敗");
            }
        }).catch(error => {
            console.log(error);
            alert("刪除類別失敗");
        });

    };

    return (
        <>
            <ApplicationShell >
            <PageTabs tabs={tabs} setTabs={setTabs} />
            {selectedTab === '提醒' ? <EscalationTabContent setOpenSlide={setOpenEscalationSlideOver} /> :
            <>
                <PageHeadings 
                    openForm={openForm} 
                    setOpenForm={setOpenForm} 
                    pageHeadingTitle={pageHeadingTitle} 
                    setEditMode={setEditMode} 
                    setFormContent={setFormContent}
                    setReminderInputs={setReminderInputs}
                    />
                
                <ul role="list" className="divide-y divide-gray-100">
                {typeList.map((type) => (
                    <li key={type._id} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{type.langVar.tc}</p>
                                {/* <p
                                    className={classNames(
                                    statuses[type.status],
                                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                    )}
                                >
                                    {type.status}
                                </p> */}
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="whitespace-nowrap">
                                    {type.name}
                                </p>
                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                    <circle cx={1} cy={1} r={1} />
                                </svg>
                                <p className="truncate">Item Code: {type.itemCode}</p>
                            </div>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                            {/* <a
                            href={type.href}
                            className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                            View project<span className="sr-only">, {type.name}</span>
                            </a> */}
                            <Menu as="div" className="relative flex-none">
                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                    {({ active }) => (
                                    <a
                                        onClick={(e) => {handleEditClick(e)}}
                                        data-id={type._id}
                                        className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                        )}
                                    >
                                        修改<span className="sr-only">, {type.name}</span>
                                    </a>
                                    )}
                                </Menu.Item>
                                {/* <Menu.Item>
                                    {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                        )}
                                    >
                                        Move<span className="sr-only">, {type.name}</span>
                                    </a>
                                    )}
                                </Menu.Item> */}
                                <Menu.Item>
                                    {({ active }) => (
                                    <a
                                        onClick={(e) => {handleDeleteClick(e)}}
                                        data-id={type._id}
                                        className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                        )}
                                    >
                                        刪除<span className="sr-only">, {type.name}</span>
                                    </a>
                                    )}
                                </Menu.Item>
                                </Menu.Items>
                            </Transition>
                            </Menu>
                        </div>
                    </li>
                ))}
                </ul>
            </>
            }
            <NewTypeFormContainer 
                openForm={openForm} 
                setOpenForm={setOpenForm} 
                tabs={tabs} 
                editMode={editMode}
                formContent={formContent}
                setFormContent={setFormContent}
                editID={editID}
                reminderInputs={reminderInputs}
                setReminderInputs={setReminderInputs}
            
            />
            </ApplicationShell>
            <EscalationCreateSlideOver open={openEscalationSlideOver} setOpen={setOpenEscalationSlideOver} />
        </>
    )
}

// page headings 
function PageHeadings({openForm, setOpenForm, pageHeadingTitle, setEditMode, setFormContent, setReminderInputs}) {
    return (
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {pageHeadingTitle}
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          {/* <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Edit
          </button> */}
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
                setEditMode(false);
                setOpenForm(true);
                // clear form content
                setFormContent({
                    "chiName": "", 
                    "engName": "",
                    "type": "",
                });
                // clear reminder inputs
                setReminderInputs([{time: "", targets: []}]);
            }}
          >
            新加
          </button>
        </div>
      </div>
    )
  }


  // New Job Type form container 
function NewTypeFormContainer({openForm, setOpenForm, tabs, editMode, formContent, setFormContent, editID, reminderInputs, setReminderInputs}) {
    

    const handleUploadNewType = (e) => {
        // check for current tabs 
        let currentTab = tabs.find((tab) => tab.current);

        // create a post request to /api/v1/jobtypes
        let url;
        if (currentTab['name'] == "工作要求") {  // handle job types
            url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobtypes";
        } else {  // handle main types
            url = GlobalVars.BACKEND_DOMAIN + "/api/v1/maintypes";
        }

        let body = {
            "organizationID": sessionStorage.getItem("organizationId"),
            'name': document.getElementById("eng-name").value,
            "langVar": {
                "tc": document.getElementById("name").value,
            },
            "reminder": reminderInputs,
            "itemCode": formContent['itemCode']
        };

        // if it's job type, add main type id   
        if (currentTab['name'] == "工作要求") {
            body['mainTypeID'] = document.getElementById("main-type").value;
        }

        let headers = {
            "Content-Type": "application/json",
            "Authorization": sessionStorage.getItem("idToken")
        };

        // check if users are in edit mode
        let apiMethod = 'POST';
        if (editMode) {
            apiMethod = "PUT";
            body['_id'] = editID;
        } 

        fetch(url, {
            method: apiMethod,
            body: JSON.stringify(body),
            headers: headers
        }).then(response => {
            if (response.status === 200) {
                // alert
                if (editMode) {
                    alert("成功修改類別");
                } else {
                    alert("成功新增類別");
                }
                // close the form
                setOpenForm(false);
                
                // reload the page
                window.location.href = window.location.origin + window.location.pathname + "?t=" + currentTab['name'];

            } else {
                console.log("Error: " + response.status);
                if (editMode) {
                    alert("修改類別失敗");
                } else {
                    alert("新增類別失敗");
                }
            }
        }).catch(error => {
            console.log(error);
            alert("新增類別失敗");
        });
    };

    // if edit mode is true, set the form title
    const formTitle = editMode ? "修改類別" : "新加類別";

    return (
        <SlideOvers 
            open={openForm} 
            setOpen={setOpenForm} 
            title={formTitle}
            submitAction={handleUploadNewType}
            content={<NewTypeFormContent 
                        setFormContent={setFormContent} 
                        formContent={formContent} 
                        reminderInputs={reminderInputs} 
                        setReminderInputs={setReminderInputs} 
                        tabs={tabs} 
                        editMode={editMode} 
                        editID={editID}
                    />} 
            extraBtn={<DeleteTypeButton typeID={editID} setOpen={setOpenForm} />}
        />
    )
}

// delete type button 
function DeleteTypeButton({typeID, setOpen}) {
    const handleDeleteType = (e) => {
        // prompt confirmation
        if (!window.confirm("確認刪除？")) {
            return;
        }

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes`;
        fetch(url, {
            method: "DELETE",
            headers: {
                "Authorization": sessionStorage.getItem("idToken"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "typeID": typeID
            })
        }).then(response => {
            if (response.status === 200) {
                alert("刪除成功");
                setOpen(false);
            } else {
                throw new Error("刪除失敗");
            }
        }).catch(error => {
            console.log(error);
            alert("刪除失敗");
        });
    }

    // check if the type already deleted
    const [isDeleted, setIsDeleted] = useState(false);
    useEffect(() => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes?typeID=${typeID}`;
        fetch(url, {
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error("刪除失敗");
            }
        }).then(data => {
            if (data['type'].hasOwnProperty("deletedAt")) {
                setIsDeleted(true);
            }
        }).catch(error => {
            console.log(error);
        });
    }, [typeID]);


    return (
        <button
            type="button"
            className={`inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ${isDeleted ? "opacity-50 cursor-not-allowed bg-red-400" : "bg-red-600"}`}
            onClick={handleDeleteType}
            disabled={isDeleted}
        >
            {isDeleted ? "已刪除" : "刪除"}
        </button>
    )
}

// New Job Type form content
function NewTypeFormContent({formContent, setFormContent, reminderInputs, setReminderInputs, tabs, editMode, editID}) {
    const [userList, setUserList] = useState([]);

    const [currentTabName, setCurrentTabName] = useState("工作要求");

    useEffect(() => {
        // get current tabs
        let currentTab = tabs.find((tab) => tab.current);
        setCurrentTabName(currentTab['name']);
    }, [tabs]);
    
    // fetch user list from backend
    useEffect(() => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/users?organizationID=${sessionStorage.getItem("organizationId")}`;
        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => response.json())
        .then((rawData) => {
            let data = rawData['users'];
            // convert displayNames to name
            data = [
                {
                    "_id": "assignedTo",
                    "name": "施工人員",
                },
                {
                    "_id": "assignedBy",
                    "name": "接單人員",
                },
                ...data.map((user) => {
                    let name = user.displayName;
                    if (!user.hasOwnProperty("displayName") || name === "") {
                        name = user['meta']['email'].substring(0, user['meta']['email'].indexOf("@"));
                    }
                    return {
                        "_id": user._id,
                        "name": name
                    }
                })
            ];

            // convert to {label: name, value: _id} format
            data = data.map((user) => {
                return {
                    "label": user.name,
                    "value": user._id
                }
            });

            setUserList(data);
        }).catch(error => {
            console.log(error);
            alert("獲取用戶列表失敗");
        });
    }, []);

    // handle adding another set of reminder
    const handleAddAnotherReminder = (e) => {
        e.preventDefault();
        setReminderInputs([...reminderInputs, {time: "", targets: []}]);
    };

    // handle removing last set of reminder
    const handleRemoveLastReminder = (e) => {
        e.preventDefault();
        setReminderInputs(reminderInputs.slice(0, -1));
    };

    // handle input change for reminder inputs
    const handleReminderInputChange = (index, e) => {
        const values = [...reminderInputs];
        values[index][e.target.name] = e.target.value;
        setReminderInputs(values);
    };

    // handle input change when users interact with multiselect
    const handleMultiUserSelect = (ind, selectedOptions) => {
        const values = [...reminderInputs];
        
        values[ind]['targets'] = selectedOptions;
        setReminderInputs(values);
    }

    // maintype List dropdown for associating with job types
    const [mainTypeList, setMainTypeList] = useState([]);
    useEffect(() => {
        if (currentTabName !== "工作要求") {
            return;
        }

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/maintypes?organizationID=${sessionStorage.getItem("organizationId")}`;
        fetch(url)
        .then((response) => response.json())
        .then((rawData) => {
            let data = rawData['types'];

            // use langVar to display the name
            data = data.map((type) => {
                return {
                    "_id": type._id,
                    "name": type.langVar.tc
                }
            });

            setMainTypeList(data);
        }).catch(error => {
            console.log(error);
            alert("獲取主類別列表失敗");
        });

    }, [currentTabName]);

    // check if 工作代號 is not duplicated
    const [isDuplicatedCode, setIsDuplicatedCode] = useState(false);
    const [codeSuggest, setCodeSuggest] = useState("");
    useEffect(() => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes?organizationID=${sessionStorage.getItem("organizationId")}&itemCode=${formContent['itemCode']}`;
        fetch(url, {
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => {
            if (response.status === 200) {
                setIsDuplicatedCode(true);
            } else {
                setIsDuplicatedCode(false);
            }
        }).catch(error => {
            console.log(error);
        });
    }, [formContent['itemCode']]);


    return (
        <>
            <div className="mt-1">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    中文名稱
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        value={formContent['chiName']}
                        onChange={(e) => setFormContent({...formContent, "chiName": e.target.value})}
                    />
                </div>
            </div>
            <div className="mt-5">
                <label htmlFor="eng-name" className="block text-sm font-medium text-gray-700">
                    英文名稱
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="eng-name"
                        id="eng-name"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        value={formContent['engName']}
                        onChange={(e) => setFormContent({...formContent, "engName": e.target.value})}
                    />
                </div>
            </div>
            <div className="mt-5">
                <label htmlFor="item-code" className="block text-sm font-medium text-gray-700">
                    工作代號
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="item-code"
                        id="item-code"
                        className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${(isDuplicatedCode && !editMode)? "border-red-500" : ""}`}
                        placeholder=""
                        value={formContent['itemCode']}
                        onChange={(e) => setFormContent({...formContent, "itemCode": e.target.value})}
                    />
                    {/* input alert in red font */}
                    <div
                        className="mt-2 flex items-center justify-between"
                    >
                    {(isDuplicatedCode && !editMode) ?
                        <p className=" text-sm text-red-600" id="email-error">
                            這個工作代號已經存在
                        </p>
                        :
                        <p
                            className="text-sm text-gray-500"
                        >建議代號: {codeSuggest}</p>
                    }
                    <ViewPastJobCode tabs={tabs} setCodeSuggest={setCodeSuggest} />
                    </div>
                </div>
            </div>
            
            {currentTabName === "工作要求" && 
                <div>
                    <hr className="my-5"></hr>
                    <label htmlFor="main-type" className="block text-sm font-medium text-gray-700">
                        主類別
                    </label>
                    <div className="mt-1">
                        <select
                            id="main-type"
                            name="main-type"
                            value={formContent['mainType']}
                            onChange={(e) => setFormContent({...formContent, "mainType": e.target.value})}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                            <option value="">請選擇</option>
                            {mainTypeList.map((type) => (
                                <option key={type._id} value={type._id}>{type.name}</option>
                            ))}
                        </select>
                    </div>
                    
                    {/* <hr className="my-5"></hr>
                    <JobIndividualEscalationSettings 
                        reminderInputs={reminderInputs}
                        userList={userList}
                        handleReminderInputChange={handleReminderInputChange}
                        handleMultiUserSelect={handleMultiUserSelect}
                        handleAddAnotherReminder={handleAddAnotherReminder}
                        handleRemoveLastReminder={handleRemoveLastReminder}
                    /> */}

                    <hr className='my-5'></hr>
                    <SubModuleSelection 
                        typeID={editID}
                    />

                </div>
            }
        </>
    )
}

// Job individual escalation settings
function JobIndividualEscalationSettings({
    reminderInputs, 
    userList, 
    handleReminderInputChange,
    handleMultiUserSelect,
    handleAddAnotherReminder,
    handleRemoveLastReminder
}) {
    return (
        <div>
            <h3 className="leading-6 font-medium text-gray-900">工作逾時提醒設定</h3>
            <p className="mt-1 text-sm text-gray-500">每個提醒是按順序, 而非同時計時. </p>
            {/* create initial reminder sets */}
            {reminderInputs.map((reminder, index) => (
                <div key={index} className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            時間 (分鐘)
                        </label>
                        <div className="mt-2">
                            <input
                            onChange={event => handleReminderInputChange(index, event)}
                            value={reminder.time}
                            name="time"
                            type="number"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                            目標提醒用戶
                        </label>
                        <div className="mt-2">
                            {/* <select
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={reminder.target}
                                name="target"
                                onChange={event => handleReminderInputChange(index, event)}
                            >   
                                <option value="">請選擇</option>
                                {userList.map((user) => (
                                    <option key={user._id} value={user._id}>{user.name}</option>
                                ))}
                            </select> */}
                            <MultiSelect 
                                options={userList}
                                value={reminder.targets}
                                data-id={index}
                                labelledBy={"Select"}
                                onChange={(e) => {
                                    handleMultiUserSelect(index, e);
                                }}
                            />
                        </div>
                    </div>
                </div>
            ))}
            
            <button
                onClick={handleAddAnotherReminder}
                className='mt-2 inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700'
            >
                加上另一個逾時提醒
            </button>
            {/* a secondary button to remove the last reminder */}
            <button
                onClick={handleRemoveLastReminder}
                className='ml-2 mt-2 inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-indigo ring-1 ring-outset ring-indigo-300 hover:bg-indigo-600 hover:text-white'
            >
                移除上一個逾時提醒
            </button>
        </div>
    )
}

// page tabs 
function PageTabs({tabs, setTabs}) {
    
    const handleTabSelection = (e) => {
        // check if the target is a select element or not
        let selectedOption = e.target.dataset.name;
        // if (e.target.tagName !== 'SELECT') {
        //     selectedOption = e.target.innerHTML;
        // } else {
        //     selectedOption = e.target.value;
        // }

        // update the current tab
        let newTabs = tabs.map((tab) => {
            if (tab.name === selectedOption) {
                return {
                    name: tab.name,
                    href: tab.href,
                    current: true,
                }
            } else {
                return {
                    name: tab.name,
                    href: tab.href,
                    current: false,
                }
            }
        });

        setTabs(newTabs);
    }

    return (
        <div className="mb-10">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).name}
                onChange={handleTabSelection}   
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    data-name={tab.name}
                    onClick={handleTabSelection}
                    className={classNames(
                      tab.current
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      )
}

// escalation tab content 
function EscalationTabContent({setOpenSlide}) {
    const [escalationList, setEscalationList] = useState([]);

    // query for existing escalations
    useEffect(() => {
        EscalationSetting({setEscalationList});
    }, []);

    // handle delete settings 
    const handleDeleteSetting = (e) => {
        // obtain the id of the setting
        let settingID = e.target.parentElement.parentElement.dataset.id;

        // prompt user for confirmation
        let confirmation = window.confirm("確定要刪除提醒設定嗎?");

        if (!confirmation) {
            return;
        }

        // create a delete request to /api/v1/escalations
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/escalation-setting`;
        let body = {
            "settingID": settingID
        }

        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }).then(response => {
            if (response.status === 200) {
                // alert
                alert("成功刪除提醒設定");
                // reload the page
                EscalationSetting({setEscalationList});
            } else {
                console.log("Error: " + response.status);
                alert("刪除提醒設定失敗");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <>
            <EscalationTabHeader setOpenSlide={setOpenSlide} />
            <div className="mt-8">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    工作名稱
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    狀態
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    目標
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    時間
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span className="sr-only">刪除</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {escalationList.map((item) => (
                                <tr key={item.email} data-id={item._id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {item.jobTypeName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.escalation}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.target}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.time}</td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <a  className="text-indigo-600 hover:text-indigo-900 cursor-pointer" onClick={handleDeleteSetting}>
                                        刪除
                                    </a>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

// escalation tab header
function EscalationTabHeader({setOpenSlide}) {
    return (<div className="md:flex md:items-center md:justify-between">
    <div className="min-w-0 flex-1">
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        提醒
      </h2>
    </div>
    <div className="mt-4 flex md:ml-4 md:mt-0">
      {/* <button
        type="button"
        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Edit
      </button> */}
      <button
        type="button"
        onClick={() => setOpenSlide(true)}
        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        新增
      </button>
    </div>
  </div>)
}

// escatlation create slide over
function EscalationCreateSlideOver({open, setOpen}) {
    // states
    const [settingObject, setSettingObject] = useState({
        "jobType": [],
        "escalation": [],
        "target": [],
        "time": null
    });

    // get existing available job type 
    const [jobTypeList, setJobTypeList] = useState([]);
    useEffect(() => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes?organizationID=${sessionStorage.getItem("organizationId")}`;
        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => response.json())
        .then((rawData) => {
            let data = rawData['jobTypes'];

            // create label and value 
            data = data.map((type) => {
                return {
                    "label": type.itemCode + "-" + type.langVar.tc + "(" + type.name + ")",
                    "value": type._id
                }
            });
            setJobTypeList(data);
        }).catch(error => {
            console.log(error);
            alert("獲取工作類別失敗");
        });
    }, []);

    // get existing users 
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/users?organizationID=${sessionStorage.getItem("organizationId")}`;
        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => response.json())
        .then((rawData) => {
            let data = rawData['users'];
            // convert displayNames to name
            data = [
                {
                    "_id": "assignedTo",
                    "name": "施工人員",
                },
                {
                    "_id": "assignedBy",
                    "name": "接單人員",
                },
                ...data.map((user) => {
                    let name = user.displayName;
                    if (!user.hasOwnProperty("displayName") || name === "") {
                        name = user['meta']['email'].substring(0, user['meta']['email'].indexOf("@"));
                    }
                    return {
                        "_id": user._id,
                        "name": name
                    }
                })
            ];

            // convert to {label: name, value: _id} format
            data = data.map((user) => {
                return {
                    "label": user.name,
                    "value": user._id
                }
            });

            setUserList(data);
        }).catch(error => {
            console.log(error);
            alert("獲取用戶列表失敗");
        });
    }, []);

    const [submitBtnText, setSubmitBtnText] = useState("新增");
    const submitRef = useRef();
    const handleSubmission = (e) => {
        e.preventDefault();
        setSubmitBtnText("新增中...");
        submitRef.current.disabled = true;

        // if jobType, escalation and target is empty, return
        if (settingObject['jobType'].length === 0 || settingObject['escalation'].length === 0 || settingObject['target'].length === 0) {
            alert("請填寫所有欄位");
            return;
        }

        // if time is 0, return
        if (parseInt(settingObject['time']) === 0) {
            alert("時間不能為0");
            return;
        }

        let submitData = settingObject;
        submitData['organizationID'] = sessionStorage.getItem("organizationId");

        let postUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/escalation-setting";
        fetch(postUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(submitData)
        }).then(response => {
            if (response.status === 200) {
                // alert
                alert("成功新增提醒");
                // close the slide over
                setOpen(false);

                setSubmitBtnText("新增");
                submitRef.current.disabled = false;
                
            } else {
                console.log("Error: " + response.status);
                alert("新增提醒失敗");
                setSubmitBtnText("新增");
                submitRef.current.disabled = false;
            }
        }).catch(error => {
            console.log(error);
            alert("新增提醒失敗");
            setSubmitBtnText("新增");
            submitRef.current.disabled = false;
        });

    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                        <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                            <div className="px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                    提醒設定
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                    <button
                                    type="button"
                                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    onClick={() => setOpen(false)}
                                    >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                </div>
                            </div>
                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                <EscalationSlideOverContent 
                                    jobTypeList={jobTypeList} 
                                    userList={userList}
                                    settingObject={settingObject} 
                                    setSettingObject={setSettingObject} 
                                    
                                />
                                </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                                type="button"
                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                onClick={() => setOpen(false)}
                            >
                                取消
                            </button>
                            <button
                                type="button"
                                onClick={handleSubmission}
                                ref={submitRef}
                                className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                {submitBtnText}
                            </button>
                            </div>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </div>
            </Dialog>
            </Transition.Root>
    )
}

// escalation slide over content 
function EscalationSlideOverContent({jobTypeList, userList, settingObject, setSettingObject}) {

    return (
        <>
            {/* a selector using react-select and show jobtypelist as options  */}
            <div>
                <label htmlFor="job-type" className="block text-sm font-medium text-gray-700">
                    工作類別
                </label>
                <div className="mt-1">
                    <MultiSelect
                        options={jobTypeList}
                        onChange={(e) => setSettingObject({...settingObject, "jobType": e})}
                        value={settingObject['jobType']}
                    />
                </div>
            </div>
            {/* a selector for users */}
            <div className="mt-5">
                <label htmlFor="user" className="block text-sm font-medium text-gray-700">
                    通知對象
                </label>
                <div className="mt-1">
                    <MultiSelect
                        options={userList}
                        onChange={(e) => setSettingObject({...settingObject, "target": e})}
                        value={settingObject['target']}
                    />
                </div>
            </div>
            {/* a selector for status */}
            <div className="mt-5">
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                    提醒狀態
                </label>
                <div className="mt-1">
                    <MultiSelect
                        options={availableStatus}
                        onChange={(e) => setSettingObject({...settingObject, "escalation": e})}
                        value={settingObject['escalation']}
                    />
                </div>
            </div>
            <div className='mt-5'>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    時間 (分鐘)
                </label>
                <div className="mt-2">
                    <input
                    defaultValue={0}
                    type="number"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setSettingObject({...settingObject, "time": e.target.value})}
                    value={settingObject['time']}
                    />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="email-description">
                    上列時間過後, 狀態還沒有超過指定提醒狀態, 會發送提醒
                </p>
            </div>
        </>
    )
}

// retrieve escalation setting
function EscalationSetting({setEscalationList}) {
    let getUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/escalation-setting?organizationID=" + sessionStorage.getItem("organizationId");
    fetch(getUrl)
    .then((response) => response.json())
    .then((rawData) => {
        
        let data = rawData['escalationSetting'];

        // go through target list within each item in the setting, if assignedTo or assignedBy, replace with the 施工人士 or 接單人士. Join the name together and separated by comma
        data = data.map((item) => {
            let targetList = item.target.map((target) => {
                if (target === "assignedTo") {
                    return "施工人士";
                } else if (target === "assignedBy") {
                    return "接單人士";
                } else {
                    return target;
                }
            });

            // convert status to chinese by going through availableStatus
            let status = availableStatus.find((status) => status.value === item.escalation);
            item.escalation = status.label;

            item.target = targetList.join(", ");

            // if the length of the target is over 20, truncate it
            if (item.target.length > 20) {
                item.target = item.target.substring(0, 20) + "...";
            }

            return item;
        });
    
        setEscalationList(data);
    }).catch(error => {
        console.log(error);
        console.log("獲取提醒列表失敗");
    });

    return 
}


// view past job code 
function ViewPastJobCode({tabs, setCodeSuggest}) {
    const [pastJobCodes, setPastJobCodes] = useState([]);
    // query for past job codes
    useEffect(() => {
        let selectedTab = tabs.find((tab) => tab.current)['name'];
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes/latest-three?organizationID=${sessionStorage.getItem("organizationId")}&type=${selectedTab == "工作要求" ? "job" : "main"}`;
        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => response.json())
        .then((rawData) => {
            setPastJobCodes(rawData['jobTypeCode']);

            // get the first item code and suggest the next one
            let firstItemCode = rawData['jobTypeCode'][0]['itemCode'];

            // use regex to detect any integer within the string
            let regex = /\d+/;
            let match = firstItemCode.match(regex);
            let number = parseInt(match[0]);
            let nextNumber = number + 1;
            let nextItemCode = firstItemCode.replace(regex, nextNumber.toString());
            setCodeSuggest(nextItemCode);

        }).catch(error => {
            console.log(error);
        });
    }, [])

    return (
        <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 px-2">
          {/* <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" /> */}
          <span
            className="text-sm"
          >
            查看過去工作代號
          </span>
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="max-h-[200px] overflow-y-scroll absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {pastJobCodes.map((code) => (
            <MenuButton as="button" key={code} className="w-full flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
              <p>{code.name.length > 14 ?  code.name.slice(0, 14) + "..." : code.name}</p>
              <p>{code.itemCode}</p>
            </MenuButton>
          ))}
        </div>
      </MenuItems>
    </Menu>
    )
}
