import { useEffect, useState, useRef } from "react";
import { useWeglot } from "react-weglot";
import GlobalVars from "../../../../Config";
export default function LanguageSwitcher() {
    // const [currLang, setCurrLang] = useState("tw");
    const [lang, setLang] = useWeglot(GlobalVars.weglot.key, "tc");
    const prevLangRef = useRef(lang);

    // refresh page if lang changed
    useEffect(() => {
        if (prevLangRef.current !== lang) {
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/lang-preference";
            fetch(url, {
                method: "PUT",
                body: JSON.stringify({
                    "lang": lang,
                    "userID": localStorage.getItem("userid")
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.message == "User updated") {
                    prevLangRef.current = lang;
                    window.location.reload();
                } else {
                    console.error("Error:", data);
                }
            })
            .catch(error => {
                console.error("Error:", error);
                alert("更新語言失敗, 請稍後再試");
            });
        }
    }, [lang]);

    const [langs, setLangs] = useState([
        {"name": "English", "value": "en"},
        {"name": "Traditional Chinese (繁體中文)", "value": "tw"},
        {"name": "Simplified Chinese (簡體中文)", "value": "zh"},
    ]);

    useEffect(() => {
        // query for lang preference
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/lang-preference?userID=" + localStorage.getItem("userid");
        fetch(url)
        .then(response => response.json())
        .then(data => {
            setLang(data.lang);
            console.log(data.lang);
            localStorage.setItem("lang", data.lang);
        });
    }, []);

    return (
        <div
            className="flex sm:items-center justify-between w-full sm:flex-row flex-col"
        >
            <div
                className="flex flex-col"
            >
                <label
                    className="text-sm font-medium text-gray-700"
                >語言</label>
                <p
                    className="text-sm text-gray-500"
                >用戶介面使用的語言</p>
            </div>
            <select
                className="lang-switcher mt-1 block w-full sm:w-[50%] py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e) => {
                    localStorage.setItem("language", e.target.value);
                    setLang(e.target.value);
                    console.log(e.target.value);
                }}
                value={lang}
            >
                {langs.map((lang) => (
                    <option key={lang.value} value={lang.value} className="text-sm text-gray-500">{lang.name}</option>
                ))}
            </select>
            {/* <button onClick={() => {
                setLang("en");
            }}>en</button>
            <button onClick={() => {
                setLang("tw");
            }}>tw</button>
            <button onClick={() => {
                setLang("zh");
            }}>zh</button> */}
        </div>
    )
}