// filter component using react-select and return the selected value as object

import { useState } from "react";
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/react'
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid'

export default function FilterComponent({data, filter, setFilter, filterKey}) {
    const [query, setQuery] = useState('')
  
    const filteredList =
      query === ''
        ? data
        : data.filter((item) => {
            return item.name.toLowerCase().includes(query.toLowerCase())
          })
  
    const handleInputSelection = (event) => {
      // look for input element within the event target
      let inputEl = event.target.closest(".filter-btn").getElementsByTagName("input")[0];
      if (inputEl) {
        inputEl.focus();
        inputEl.select();
      }
    }
  
  
    return (
      <Combobox as="div" value={filter[filterKey] || null} 
      onChange={(newValue) => setFilter(prevFilter => ({
        ...prevFilter,
        [filterKey]: newValue
      }))}>
        <div className="relative filter-btn">
          <ComboboxInput
            className="text-[12px] w-full rounded-md border-0 bg-white h-[36px] pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(item) => item?.name || ''}
            onClick={handleInputSelection}
          />
          <ComboboxButton 
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            onClick={handleInputSelection}
          >
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
  
          {filteredList.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full min-w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredList.map((item) => (
                <ComboboxOption
                  key={item.name}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{item.name}</span>
  
                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
    )
}

function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}
