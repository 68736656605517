import {
    DataSheetGrid,
    textColumn,
    keyColumn, 
    createAddRowsComponent
} from 'react-datasheet-grid';
import {
    ArrowLongLeftIcon,
    ArrowLongRightIcon
} from '@heroicons/react/20/solid';
import { useEffect, useState, useRef } from 'react';

export default function MinibarPosting () {
    const initialProducts = Array.from({ length: 20 }, (_, i) => ({
        id: i + 1,
        name: `Product ${i + 1}`,
        price: (i + 1) * 10,
    }));
    const [productList, setProductList] = useState(initialProducts);
    const [searchQuery, setSearchQuery] = useState('');
    const [matchedItems, setMatchedItems] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [styledCells, setStyledCells] = useState([]);
    const columns = [
        {...keyColumn('id', textColumn), title: '產品編號', disabled: true},
        {...keyColumn('name', textColumn), title: '名稱', disabled: true, render: ({ rowData, rowIndex }) => (
            <div classname="bg-red-800">
                {rowData.name}
            </div>
        )},
        {...keyColumn('price', textColumn), title: '價錢', disabled: true}
        
    ];

    const AddRows = createAddRowsComponent({
        button: '新增', // Add
        unit: '行', // rows
      })

    const datasheetRef = useRef(null);

    // handle search query 1) update matched items 2) active the cell
    useEffect(() => {
        if (searchQuery === '') {
            return;
        }
        // update matched items
        const matchedItems = productList.filter(product => product.name.toLowerCase().includes(searchQuery.toLowerCase()));
        setMatchedItems(matchedItems);

        // active the first matched item
        if (matchedItems.length > 0) {
            datasheetRef.current?.setActiveCell({col: 1, row: matchedItems[0].id - 1});
        }

        // set active index to 0
        setActiveIndex(0);
    }, [searchQuery]);

    // highlight next matched item when activeIndex changes
    useEffect(() => {
        if (matchedItems.length === 0) {
            return;
        }
        // set active cell
        datasheetRef.current?.setActiveCell({col: 1, row: matchedItems[activeIndex].id - 1});
    }, [activeIndex]);


    return (
        <>
            <h3
                className="text-base font-semibold leading-6 text-gray-900"
            >
                產品列表
            </h3>
            <div
                className="my-2"
            >
                <Searchbar 
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    matchedItems={matchedItems}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                />
            </div>
            <DataSheetGrid 
                value={productList}
                columns={columns}
                onChange={setProductList}
                addRowsComponent={AddRows}
                lockRows
                ref={datasheetRef}
            />
        </>
    )
}

// search bar
function Searchbar({searchQuery, setSearchQuery, matchedItems, activeIndex, setActiveIndex}) {
    const [searchValue, setSearchValue] = useState("");

    return (
        <div
            className="flex items-center justify-between"
        >
            <input
                type="text"
                placeholder="搜尋產品"
                className="p-2 border rounded-md w-full text-sm transition duration-300"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <button
                onClick={() => {
                    setSearchQuery(searchValue);
                }}
                className="ml-2 px-2 py-2 text-sm font-semibold text-gray-700 rounded cursor-pointer text-nowrap hover:bg-gray-200 duration-300"
            >搜尋</button>
            {searchQuery != '' && (
                // two buttons to navigate to the next matched item
                <div
                    className="flex justify-end ml-2"
                >
                    <button
                        onClick={() => {
                            // prevent negative index
                            if (activeIndex === 0) return;

                            // set active index
                            setActiveIndex(activeIndex - 1);
                        }}
                        className='mr-2 text-sm'
                    >
                        <ArrowLongLeftIcon className="h-5 w-5" />
                    </button>
                    <span
                        className="text-sm text-gray-500 text-nowrap"
                    >{activeIndex + 1} / {matchedItems.length}</span>
                    <button
                        onClick={() => {
                            // prevent out of index
                            if (activeIndex === matchedItems.length - 1) return;

                            // set active index
                            setActiveIndex(activeIndex + 1);
                        }}
                        className='ml-2 text-sm'
                    >
                        <ArrowLongRightIcon className="h-5 w-5" />
                    </button>
                </div>
                
            )}
        </div>
    )
}

