// with the given filter parameter, fetch the data from the backend and display the data in a line graph

import GlobalVars from "../../../../Config";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import { useEffect, useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker'
import FilterComponent from '../../../../Components/FilterComponent'

export default function JobTypeByDay() {
    const [filter, setFilter] = useState({
        start_date: "",
        end_date: "",
        location: "",
        job_type: "",
        type: "daily",
    });
    const [displayData, setDisplayData] = useState([]);
    const [dateValue, setDateValue] = useState(() => {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 6);
        return {
            startDate: sevenDaysAgo.toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
        };
    });

    // update filter when dateValue changes
    useEffect(() => {
        
        setFilter({
            ...filter,
            start_date: dateValue.startDate,
            end_date: dateValue.endDate,
        });
    }, [dateValue]);

    useEffect(() => {

        let url = `${GlobalVars.ANALYTIC_DOMAIN}/api/v1/dashboards/job-type-analysis`;

        // Check if start_date and end_date are available
        if (filter.start_date && filter.end_date) {
            url += `?startDate=${filter.start_date}&endDate=${filter.end_date}`;
        } else {
            // Use last 7 days if dates are not available
            const today = new Date();
            const sevenDaysAgo = new Date(today);
            sevenDaysAgo.setDate(today.getDate() - 6);
            url += `?startDate=${sevenDaysAgo.toISOString().split('T')[0]}&endDate=${today.toISOString().split('T')[0]}`;
        }

        // add type parameter to url
        url += `&type=${filter.type}`;

        // add job_type parameter to url
        if (filter.job_type) {
            url += `&typeId=${filter.job_type['value']}`;
        }


        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${sessionStorage.getItem("idToken")}`,
            },
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Failed to fetch data");
            }
        })
        .then(rawData => {
            console.log(rawData);

            // Process and set the display data
            let processedData = processDataForChart(rawData['data']);
            setDisplayData(processedData);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });
    
    }, [filter]);

    // Function to process data for the chart
    const processDataForChart = (rawData) => {

        console.log(rawData);
        
        // Transform into array of objects for LineChart
        if (filter.type === "hourly" || filter.type === "daily") {
            // Count occurrences of each date
            const dateCounts = rawData.reduce((acc, item) => {
                const date = item.createdAt; // Assuming createdAt is in ISO format
                acc[date] = (acc[date] || 0) + 1;
                return acc;
            }, {});




            return Object.entries(dateCounts).map(([date, count]) => ({
                x: date,
                count
            })).sort((a, b) => a.x.localeCompare(b.x));

        } else if (filter.type === "hourly-median") {
            // console.log(rawData);
            // return data in the format of {x: hour, count: median} where rawData format is an array of object with the format of {createdAt: YYYY-MM-DD HH:00}

            // create an empty array with 24 elements in this format {x: hour, count: 0}
            let medianJobCountsByHour = Array.from({ length: 24 }, (v, i) => {
                const hourString = i.toString().padStart(2, '0') + ":00";
                return { x: hourString, count: 0 };
              });
            console.log(medianJobCountsByHour);

            // add data to each hour and calculate median
            // Group data by hour
            const hourlyData = rawData.reduce((acc, item) => {
                const hour = item.createdAt.split(' ')[1].split(':')[0];
                if (!acc[hour]) acc[hour] = [];
                acc[hour].push(item);
                return acc;
            }, {});


            // Calculate median for each hour
            Object.entries(hourlyData).forEach(([hour, items]) => {
                // count occurance for each day within each hour
                let counts = {};
                for (let i = 0; i < items.length; i++) {
                    let date = items[i].createdAt.split(' ')[0];

                    // add to counts if date is not present and add 1 to the count
                    if (!counts[date]) {
                        counts[date] = 1;
                    } else {
                        counts[date] += 1;
                    }
                    
                }

                // get all value into a list 
                let countsList = [];
                for (let date in counts) {
                    countsList.push(counts[date]);
                }

                // sort the list
                countsList.sort((a, b) => a - b);

                // get the median
                let medianValue;
                if (countsList.length % 2 === 0) {
                    let medianIndex = countsList.length / 2;
                    medianValue = (countsList[medianIndex - 1] + countsList[medianIndex]) / 2;
                } else if (countsList.length === 1) {
                    medianValue = countsList[0];
                } else {
                    let medianIndex = Math.floor(countsList.length / 2);
                    medianValue = countsList[medianIndex];
                }

                // add to medianJobCountsByHour
                for (let i = 0; i < medianJobCountsByHour.length; i++) {
                    if (medianJobCountsByHour[i].x === hour + ":00") {
                        medianJobCountsByHour[i].count = medianValue;
                    }
                }

            });

            return medianJobCountsByHour;
        }
    };

    const [jobTypeList, setJobTypeList] = useState([]);
    useEffect(() => {
        // fetch task list
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/jobtypes?organizationID=" + sessionStorage.getItem("organizationId"), {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
            .then((res) => res.json())
            .then((rawData) => {
                let data = rawData['jobTypes'];

                // add item code to name, e.g. <item code> - <item name>
                data.forEach((item) => {
                    item["name"] = item["itemCode"].toString() + " - " + item["name"].trim();

                    // add english name to item if it exists
                    if (item['langVar']) {
                        if (item['langVar']['tc']) {
                            item["name"] += " (" + item["langVar"]["tc"].trim() + ")";
                        }
                    }

                    item['value'] = item['_id'];
                });

                // sort by name
                data.sort((a, b) => {
                    return a["name"].localeCompare(b["name"]);
                });

                data.unshift({"name": "所有要求", 'value': 'all'});
                setJobTypeList(data);

                // set default selectionio to 所有要求
                setFilter({
                    ...filter,
                    job_type: data[0],
                });
            })
            .catch((err) => {
                console.log(err);
            }
        );
    }, []);
    
    return (
        <ResponsiveContainer width="100%" height={450}>
            <h1>各工作類別數量分析</h1>
            {/* add filter sets here */}
            <div
                className="flex flex-row gap-4 mt-4 w-full "
            >
                {/* type selector with two options (Daily, Hourly) */}
                <div
                    className="flex flex-col gap-2"
                >
                    <label htmlFor="type-selector" className="block text-sm font-medium text-gray-700">類型</label>
                    <select className="rounded-md text-sm dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full"
                        value={filter.type}
                        onChange={(e) => {
                            setFilter({
                                ...filter,
                                type: e.target.value,
                            });
                        }}
                    >
                        <option value="daily">每日</option>
                        <option value="hourly">每小時總數</option>
                        <option value="hourly-median">每小時中位數</option>
                    </select>
                </div>
                {/* date range */}
                <div
                    className="flex flex-col gap-2 w-[40%]"
                >
                    <label htmlFor="date-range" className="block text-sm font-medium text-gray-700">日期</label>
                    <Datepicker
                        showShortcuts={true}
                        value={dateValue}
                        onChange={(date) => {
                            setDateValue({
                                startDate: date.startDate,
                                endDate: date.endDate,
                            });
                        }}
                        placeholder='選擇接單日期'
                        inputClassName="rounded-md text-sm dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full"
                        configs={{
                            shortcuts: {
                                today: "今天",
                                yesterday: "昨日",
                                past: (period) => `過去 ${period} 天`,
                                currentMonth: "本月",
                                pastMonth: "上月",
                            }
                        }}
                    />
                </div>
                <div
                    className="flex flex-col gap-2"
                >
                    <label htmlFor="job-type-filter" className="block text-sm font-medium text-gray-700">工作類別</label>
                    <FilterComponent
                        data={jobTypeList}
                        filter={filter}
                        setFilter={setFilter}
                        filterKey="job_type"
                    />
                </div>
                
            </div>
            <LineChart width={500} height={300} data={displayData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                    dataKey="x" 
                    angle={300}
                    textAnchor="end"
                    height={60}
                    className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
                />
                <YAxis label={{ value: '數量', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" />
            </LineChart>
        </ResponsiveContainer>
    )
}
