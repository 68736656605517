import React, { useEffect, useState, Fragment, useRef, useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";
import ApplicationShell from "../../Components/ApplicationShell";
import { 
  DocumentIcon,
  PhotoIcon,
  TableCellsIcon,
  VideoCameraIcon,
  EllipsisVerticalIcon,
  PlayIcon,
  PauseIcon,
  BellAlertIcon,
  UsersIcon,
} from '@heroicons/react/20/solid'
import {
  ArrowsRightLeftIcon,
  ArchiveBoxXMarkIcon,
  DocumentDuplicateIcon,
  BellAlertIcon as BellAlertIconOutline,
} from '@heroicons/react/24/outline'
import GlobalVars from "../../Config";
import JobEditForm from '../../Components/JobEditForm/JobEditForm';
import { Menu, Transition, Dialog } from '@headlessui/react'
import NewJobForm from "../../Components/NewJobForm/NewJobForm";
import FileViewer from "../../Components/FileViewer";
import { MultiSelect } from "react-multi-select-component";
import "./JobDetail.css";
import Select from 'react-select';
import SelfDisappearNotification from "../../Components/SelfDisappearNotification";
import ConfettiExplosion from 'react-confetti-explosion';
import { UserContext } from '../../Context';
import ModuleSection from "./SubComponents/ModuleSection";
import ModuleDrawer from "./SubComponents/ModuleDrawer";
import ModuleNotification from "./SubComponents/ModuleNotification";


const statuses = {
  "新工作": 'text-white-600 bg-white-50 ring-white-500/10',
  "待確認": "text-pink-600 bg-pink-50 ring-pink-500/10",
  "未開始": "text-gray-600 bg-gray-50 ring-gray-500/10",
  "進行中": "text-green-600 bg-green-50 ring-green-500/10",
  "中斷": "text-red-600 bg-red-50 ring-red-500/10",
  "待續": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "待檢查": "text-blue-600 bg-blue-50 ring-blue-500/10",
  "待批准": "text-blue-600 bg-blue-50 ring-blue-500/10",
  "完成": "text-orange-600 bg-orange-50 ring-orange-500/10",
  "拒絕": "text-red-600 bg-red-50 ring-red-500/10",
  "取消": "text-red-600 bg-red-50 ring-red-500/10",
  "延期": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "回公司": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "緊急": "text-red-600 bg-red-50 ring-red-500/10",
  "維修中": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "需要額外部件": "",
  "需要專家幫助": "",
  "已轉移": "text-gray-600 bg-gray-50 ring-gray-500/10",
  "通知": "text-lime-600 bg-lime-50 ring-lime-500/10",
}


// Job Detail Display
function JobDetailDisplay({
  job, 
  attachments, 
  setAttachments, 
  setSelectedPreviewAttachment,
  setModuleName,
  setModuleOpen,
}) {

    const [timeline, setTimeLine] = useState([{
      "status": "N/A",
      "changedAt": "N/A",
      "changedBy": ""
    }]);

    // set title to be 工作詳細
    useEffect(() => {
        document.title = "工作詳細";
    }, []);

    // if job is not empty update action accordingly
    useEffect(() => {
        if (Object.keys(job).length > 0) {
          // go through each job status
          let timeline = [];
          for (var i = 0; i < job.status.length; i++) {
            // create a timepoint object
            let timepoint = {
              "status": job.status[i].status,
              "changedAt": job.status[i].changedAt,
            }

            // add changedByName if it exists
            if (job.status[i].changedByName) {
              timepoint['changedBy'] = "由" + job.status[i].changedByName + "更改";
            } else {
              timepoint['changedBy'] = "由未知更改";
            }

            // add the timepoint to the timeline
            timeline.push(timepoint);
          }

          // update the timeline
          setTimeLine(timeline);

          // return if job.alertOnly is true
          if (job.alertOnly) {
            return;
          }

          // update the action button
          if (job.latestStatus.status == '新工作' || job.latestStatus.status == '待確認' || job.latestStatus.status == '已轉移') {
            if (job.assignedTo == sessionStorage.getItem('userid')) {
              // primary action change to 接單  
              document.getElementById('prim-action').innerHTML = '接單';
            } 

          } else if (job.latestStatus.status == '未開始') {
            // primary action change to 開始工作
            document.getElementById('prim-action').innerHTML = '開始工作';

          } else if (job.latestStatus.status == '進行中') {
            if (job.meta.hasOwnProperty('requiredInspection') && job.meta.requiredInspection == true) {
              // primary action change to 待檢查
              document.getElementById('prim-action').innerHTML = '待檢查';
            } else {
              // primary action change to 完成工作
              document.getElementById('prim-action').innerHTML = '完成工作';
            }
          } else if (job.latestStatus.status == '待檢查') {
            // primary action change to 完成工作
            document.getElementById('prim-action').innerHTML = '完成工作';
          } else if (job.latestStatus.status == '完成') {
            // primary action change to 重新開始
            document.getElementById('prim-action').innerHTML = '重新開始';
          } else {
            document.getElementById('prim-action').innerHTML = '繼續工作';
          }

        }
    }, [job]);

    const handleFileUpload = (e) => {
      // deactivate fileupload button
      document.getElementById('file-upload').disabled = true;

      // change button text to 上載中...
      document.getElementById('file-upload-label').innerHTML = '上載中...';

      // fetch request to retrieve signed url to upload to cloud storage (endpoint: /api/v1/signed-url)
      var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/signed-url";
      var data = {
        fileName: e.target.files[0].name,
        fileType: e.target.files[0].type,
        organizationID: sessionStorage.getItem('organizationId'),
        jobID: job._id
      };
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('idToken')
        },
        body: JSON.stringify(data)
      }).then(response => response.json())
      .then(data => {
        // upload file to cloud storage
        var signedUrl = data['url'];
        
        var file = e.target.files[0];
        fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type
          },
          body: file
        }).then(response => {
          if (response.ok) {
            alert("檔案上載成功");

            // reload the page 
            window.location.reload();
          } 
          // reactivate fileupload button
          document.getElementById('file-upload').disabled = false;

          // change button text to 上傳檔案
          document.getElementById('file-upload-label').innerHTML = '上傳檔案';
        })
      })

    }

    // display attachments based on job id 
    const attachmentIcons = {
      "application/pdf": "DocumentIcon",
      "image/png": "PhotoIcon",
      "image/jpeg": "PhotoIcon",
      "image/jpg": "PhotoIcon",
      "image/gif": "PhotoIcon",
      "text/csv": "TableCellsIcon",
      "text/plain": "DocumentIcon",
      "video/mp4": "VideoCameraIcon",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "TableCellsIcon",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DocumentIcon",
    }

    // fetch attachments based on job id
    useEffect(() => {
      if (Object.keys(job).length == 0) {
        return;
      }
      
      var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/files?jobID=" + job._id;
      fetch(url,{
        headers: {
          'Authorization': sessionStorage.getItem('idToken')
        }
      })
      .then(response => response.json())
      .then(data => {
        let attachmentList = data['attachments'];
        attachmentList.forEach(attachment => {
          attachment['icon'] = attachmentIcons[attachment['fileType']];
        });

        setAttachments(data['attachments']);
      })
    }, [job]);
    
    // create a signed url for downloading the file
    const downloadFile = (e) => {
      // deactivate fileupload button
      document.getElementById(e.target.id).disabled = true;

      // change button text to 下載中...
      document.getElementById(e.target.id).innerHTML = '下載中...';

      // fetch request to retrieve signed url to upload to cloud storage (endpoint: /api/v1/signed-url)
      var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/signed-url?fileID=" + e.target.id;
      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': sessionStorage.getItem('idToken')
        }
      },).then(response => response.json())
      .then(data => {
        
        // upload file to cloud storage
        var signedUrl = data['url'];
        window.open(signedUrl);

        // reactivate fileupload button
        document.getElementById(e.target.id).disabled = false;

        // change button text to 下載
        document.getElementById(e.target.id).innerHTML = '下載';
      })
    }

    // audio notes 
    const [audioNotes, setAudioNotes] = useState([]);
    useEffect(() => {
      if (Object.keys(job).length == 0) {
        return;
      }

      // fetch signed url for audio notes
      var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/audio-note?jobID=" + job._id;
      fetch(url,{
        headers: {
          'Authorization': sessionStorage.getItem('idToken')
        }
      })
      .then(response => response.json())
      .then(data => {
        setAudioNotes(data['audio']);
      })
    }, [job]);

    const clientRemarkRef = useRef(null);
    const jobRemarkRef = useRef(null);
    const clientRequestedTimeRef = useRef(null);

    useEffect(() => {
      // skip if both refs have not been rendered yet
      if (clientRemarkRef.current) {
        clientRemarkRef.current.classList.add('animate-pulse', 'text-red-800', 'bg-yellow-200');
      }

      if (jobRemarkRef.current) {
        jobRemarkRef.current.classList.add('animate-pulse', 'text-red-800', 'bg-yellow-200');
      }

      if (clientRequestedTimeRef.current && job.requestedTime) {
        clientRequestedTimeRef.current.classList.add('animate-pulse', 'text-red-800', 'bg-yellow-200');
      }

      const timer = setTimeout(() => {
        if (clientRemarkRef.current) {
          clientRemarkRef.current.classList.remove('animate-pulse', 'text-red-800', 'bg-yellow-200');
        }

        if (jobRemarkRef.current) {
          jobRemarkRef.current.classList.remove('animate-pulse', 'text-red-800', 'bg-yellow-200');
        }

        if (clientRequestedTimeRef.current) {
          clientRequestedTimeRef.current.classList.remove('animate-pulse', 'text-red-800', 'bg-yellow-200');
        }
      }, 5000);

      return () => clearTimeout(timer);
    }, [job]);

    // job priority setting 
    const invPriorityMapping = { 0: '無', 1: '低', 2: '中', 3: '高' };

    // get subTask detail
    const [subTaskState, setSubTaskState] = useState({});
    const [completeRate, setCompleteRate] = useState(0);
    function CalculateCompletionRate(subJobs) {
      
      // calculate complete rate by counting the number of true values in the subTaskState
      let completeCount = 0;
      for (var key in subJobs) {
        if (subJobs[key] == true) {
          completeCount++;
        }
      }
      
      // get larger number between length of locationIDs and typeIDs
      let max = Math.max(job.locationIDs.length, job.typeIDs.length);
      
      setCompleteRate(Math.round((completeCount / max) * 100));
    }
    
    useEffect(() => {
      if (Object.keys(job).length == 0) {
        return;
      }

      var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/subjobs?jobID=" + job._id;
      fetch(url, {
        headers: {
          'Authorization': sessionStorage.getItem('idToken')
        }
      }).then(response => response.json())
      .then(data => {
        if (data.hasOwnProperty('subJobs')) {
          
          setSubTaskState(data['subJobs']);

          CalculateCompletionRate(data['subJobs'])
        }
      })

      // process multilanguage locationName
      if (job.locationName.length == 1) {
        
        // convert to tc if langVar is available
        if (job.locationName[0].hasOwnProperty('langVar')) {
          if (job.locationName[0]['langVar'].hasOwnProperty('tc')) {
            job.locationName[0]['name'] = job.locationName[0]['langVar']['tc'];
          }
        }
      }

    }, [job]);

    // update subTask detail 
    useEffect(() => {
      if (Object.keys(subTaskState).length == 0) {
        return;
      }

      var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/subjobs";
      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('idToken')
        },
        body: JSON.stringify({
          jobID: job._id,
          subJobs: subTaskState
        })
      }).then(response => response.json())
      .then(data => {
        console.log(data);
      })

      CalculateCompletionRate(subTaskState);

    }, [subTaskState]);

    // 

    return (
      <>
        {Object.keys(job).length > 0 && (
          <div className={`px-0 flex justify-between`}>
            <div>
              <h1 className={`text-base font-semibold leading-7 text-gray-900 max-w-${window.innerWidth < 640 ? 'full' : '[85%]'} ${window.innerWidth < 640 ? 'whitespace-normal' : 'whitespace-nowrap'}`}>
                {job.locationName.length > 1 ? "多個工作地點" : job.locationName[0]['name']}
              </h1>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{job.meta.jobRemark}</p> */}
              <p
                className={`mt-1 max-w-${window.innerWidth < 640 ? 'full' : '2xl'} text-sm leading-6 text-gray-500`}
              >優先: {invPriorityMapping[job.jobPriority]}</p>
            </div>
            <span className={`text-center min-w-[60px] min-h-[60px] inline-flex items-center justify-center rounded-md ring-1 ring-inset text-xs font-medium px-2 py-1 ${statuses[job.alertOnly && job.latestStatus.status != "取消" ? "通知" : job.latestStatus.status]}`}>
              {job.alertOnly && job.latestStatus.status != "取消" ? "通知" : job.latestStatus.status}
            </span>
          </div>
        )}
        {(Object.keys(job).length > 0 && (job.locationName.length > 1 || job.typeName.length > 1)) && (
          <div
            className="mt-6"
          >
            <div
              className="flex justify-between mt-6 mb-2 items-center"
            >
              <h3 className="text-base font-medium leading-6 text-gray-900">工作進度</h3>
              <span
                className="text-xs font-semibold text-gray-500"
              >{completeRate}%完成</span>
            </div>
            {/* if locationName length more than one list one by one with name on one end and check box at the other end that indicate completion*/}
            <div className="mt-2">
              <ul role="list" className="divide-y divide-gray-200 border-t overflow-y-auto max-h-[250px]">
                {/* locations */}
                {job.locationName.length > 1 && job.locationName.map((location, index) => (
                  <li className="flex py-5 justify-between w-full" key={location.name}>
                    <div className="w-full flex items-center px-1">
                      <label className="truncate text-xs leading-5 text-gray-500">{location.name}</label>
                      <input 
                        type="checkbox" 
                        className="ml-auto h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" 
                        checked={subTaskState[job.locationIDs[index]]}
                        onChange={() => {
                          let newSubTaskState = {...subTaskState};
                          newSubTaskState[job.locationIDs[index]] = !newSubTaskState[job.locationIDs[index]];
                          setSubTaskState(newSubTaskState);
                        }}
                      />
                    </div>
                  </li>
                ))}
                {/* types */}
                {job.typeName.length > 1 && job.typeName.map((type, index) => (
                  <li className="flex py-5 justify-between w-full" key={type}>
                    <div className="w-full flex items-center px-1">
                      <label className="truncate text-xs leading-5 text-gray-500">{type}</label>
                      <input 
                        type="checkbox" 
                        className="ml-auto h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" 
                        checked={subTaskState[job.typeIDs[index]]}
                        onChange={() => {
                          let newSubTaskState = {...subTaskState};
                          newSubTaskState[job.typeIDs[index]] = !newSubTaskState[job.typeIDs[index]];
                          setSubTaskState(newSubTaskState);
                        }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className="mt-6">
          <h3 className="mt-6 mb-2 text-base font-medium leading-6 text-gray-900">工作資料</h3>
          <dl className="grid grid-cols-1 sm:grid-cols-2">
            {job && Object.keys(job).length > 0 && job.meta.clientRemark && (
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0" ref={clientRemarkRef}>  
                  <div>
                      <dt className="text-sm font-medium leading-6 text-red-500">客戶需求</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.meta.clientRemark}</dd>
                  </div>
              </div>
            )}
            {job && Object.keys(job).length > 0 && job.meta.jobRemark && (
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0" ref={jobRemarkRef}>
                  <div>
                      <dt className="text-sm font-medium leading-6 text-gray-500">工作備忘</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.meta.jobRemark}</dd>
                  </div>
                  <div
                    className="flex items-center mt-1 overflow-x-auto"
                  >
                    {audioNotes && audioNotes.map((audioNote) => (
                      <div className="">
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2 bg-slate-600 rounded-[3000px] mr-1 p-1 flex px-2">
                          {/* <audio controls src={audioNote['url']} /> */}
                          <AudioPlayer url={audioNote['url']} />
                          {/* create index */}
                          <span
                            className="text-white text-sm whitespace-nowrap"
                          >錄音 {audioNotes.indexOf(audioNote) + 1}</span>
                        </dd>
                      </div>
                    ))}
                  </div>
              </div>
            )}
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-500">工作類別</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.typeName && job.typeName.length > 1 ? "多個類別" : job.typeName}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-500">主類別</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.mainTypeName || "N/A"}</dd>
            </div>
            
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0" ref={clientRequestedTimeRef}>
              <dt className="text-sm font-medium leading-6 text-gray-500">客戶指定時間</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.requestedTime || "N/A"}</dd>
            </div>
            {job && Object.keys(job).length > 0 && job.meta.estimatedDate && (
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-500">預計工作日期</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.meta.estimatedDate}</dd>
              </div>
            )}
          </dl>
        </div>
        <ModuleSection 
          jobTypes={job['typeIDs']}
          setModuleOpen={setModuleOpen}
          setModuleName={setModuleName}
          jobID={job._id}
        />
        <div className="mt-6">
          <h3 className="mt-6 mb-2 mt-text-base font-medium leading-6 text-gray-900">時間線</h3>  
          <ul role="list" className="divide-y divide-gray-100 border-t overflow-y-auto max-h-[250px]">
            {timeline.map((timepoint) => (
              <li className="flex gap-x-4 py-5" key={timepoint.changedAt}>
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
                <div className="min-w-0 flex items-center">
                  <p className={`text-nowrap text-xs font-semibold leading-6 mr-2 ${statuses[timepoint.status]} `}>{timepoint.status}</p>
                  <p className=" truncate text-xs leading-5 text-gray-500">{timepoint.changedAt}</p>
                  <p className="ml-1 truncate text-xs leading-5 text-gray-500">{timepoint.changedBy}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-6">
          <h3 className="mt-6 mb-2 text-base font-medium leading-6 text-gray-900">人員資料</h3>
          <dl className="grid grid-cols-1 sm:grid-cols-2">
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-500">施工人員</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.assignedToName ? job.assignedToName : "未有分配"}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-500">接單人員</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.assignedByName}</dd>
            </div>
          </dl>
        </div>
        
        <div className="mt-6">
          {job && Object.keys(job).length > 0 && job.meta.contactPerson && (
            <div>
              <h3 className="mt-6 text-base font-medium leading-6 text-gray-900">客戶資料</h3>
              <div className="mt-2">
                <dl className="grid grid-cols-1 sm:grid-cols-2">
                  <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-500">名稱</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.meta.contactPerson}</dd>
                  </div>
                  <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-500">電話/電郵</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{job.meta.contactDetail}</dd>
                  </div>
                </dl>
              </div>
            </div>
          )}
        </div>
        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
            <div className="flex justify-between items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">檔案</dt>
              <label
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 cursor-pointer"
                for="file-upload"
                id="file-upload-label"
              >
                上傳檔案
              </label>
              <input 
              id="file-upload" 
              name="file-upload" 
              type="file" 
              className="hidden"
              onChange={handleFileUpload} />
            </div>
            <dd className="mt-2 text-sm text-gray-900">
              <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                {attachments.map((attachment) => {
                  return (
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        {/* <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                        <Icon type={attachment.fileType} />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">{attachment.fileName}</span>
                          {/* <span className="flex-shrink-0 text-gray-400">2.4mb</span> */}
                        </div>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <button  
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                          onClick={() => {
                            setSelectedPreviewAttachment(attachment._id);
                          }}
                          id={attachment._id}
                        >
                          預覽
                        </button>
                        <button  
                        className="font-medium text-indigo-600 hover:text-indigo-500 ml-2"
                        onClick={downloadFile}
                        id={attachment._id}
                        >
                          下載
                        </button>
                      </div>
                    </li>
                )})}
              </ul>
            </dd>
        </div>
        
      </>
    )
}

// create an icon component for each attachment type
function Icon({type}) {
  if (type == 'application/pdf') {
    return <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
  } else if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg' || type == 'image/gif') {
    return <PhotoIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
  } else if (type == 'text/csv' || type == 'text/plain') {
    return <TableCellsIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
  } else if (type == 'video/mp4') {
    return <VideoCameraIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
  } else if (type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return <TableCellsIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
  } else if (type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
  } else {
    return <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
  }
}

// job detail further action buttons
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function FurtherAction({cancelJob, copyJob, setOpenManualUserAlert, jobID, setOpenJobTransferForm}) {
  return (
    <Menu as="div" className="relative inline-block text-left ml-3">
      <div>
        <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => {
                    setOpenJobTransferForm(true);
                  }}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-2 text-sm flex items-center cursor-pointer'
                  )}
                >
                  <ArrowsRightLeftIcon className="h-5 w-5 flex-shrink-0 mr-2 text-gray-400" aria-hidden="true" />
                  轉移到另一個人員
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={cancelJob}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-2 text-sm cursor-pointer flex items-center '
                  )}
                >
                  <ArchiveBoxXMarkIcon className="h-5 w-5 flex-shrink-0 mr-2 text-gray-400" aria-hidden="true" />
                  取消
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={copyJob}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-2 text-sm cursor-pointer flex items-center '
                  )}
                >
                  <DocumentDuplicateIcon className="h-5 w-5 flex-shrink-0 mr-2 text-gray-400" aria-hidden="true" />
                  複製工作
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => setOpenManualUserAlert(true)}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-2 text-sm cursor-pointer flex items-center '
                  )}
                >
                  <BellAlertIconOutline className="h-5 w-5 flex-shrink-0 mr-2 text-gray-400" aria-hidden="true" />
                  手動發送通知
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => {
                    navigator.clipboard.writeText(jobID).then(function() {
                        console.log('Copying to clipboard was successful!');
                        alert("工作單號已複製")
                    }, function(err) {
                        console.error('Could not copy text: ', err);
                    });
                  }}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-2 text-sm cursor-pointer flex items-center '
                  )}
                >
                  <DocumentDuplicateIcon className="h-5 w-5 flex-shrink-0 mr-2 text-gray-400" aria-hidden="true" />
                  複製工作單號
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

// custom audio player for audio notes
function AudioPlayer({url}) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
    audioRef.current.play();
  }

  const handlePause = () => {
    setIsPlaying(false);
    audioRef.current.pause();
  }

  // check if audio has ended
  const handleEnded = () => {
    setIsPlaying(false);
  }

  const audioRef = React.useRef(null);

  return (
    <>
      <div className="flex items-center">
        {isPlaying ? (
          <button onClick={handlePause}
          >
            <PauseIcon className="h-5 w-5 flex-shrink-0 text-gray-400 " aria-hidden="true" />
          </button>
        ) : (
          <button onClick={handlePlay}>
            <PlayIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
          </button>
        )}
        <audio 
          ref={audioRef} 
          controls 
          src={url} 
          className="hidden" 
          onEnded={handleEnded}
          />
      </div>
    </>
  )
}

// manual user alert which allows users to manually create push notification to users
function ManualUserAlert({open, setOpen, job}) {
  // states
  // const [open, setOpen] = useState(false)
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [url, setUrl] = useState('');

  const cancelButtonRef = useRef(null)

  // query for users
  useEffect(() => {
    var fetchUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + sessionStorage.getItem('organizationId');
    fetch(fetchUrl,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
    .then(response => response.json())
    .then(rawData => {
      let data = rawData['users'];

      // format data into a list of objects with label and value
      data = data.map((user) => {
        return {
          label: user.displayName,
          value: user._id
        }
      });
      setUsers(data);
    })
  }, []);

  // pre populate assignedTo if job is not empty
  useEffect(() => {
    if (Object.keys(job).length > 0) {
      // check if assignedTo is not empty
      if (job.assignedTo) {
        // find the user object
        let assignedTo = users.find((user) => user.value == job.assignedTo);
        
        if (assignedTo) {
          setSelectedUsers([assignedTo]);
        }
        
      }
    }
  }, [job, users]);

  // handle send notification
  const handleSendNotification = (job) => {
    // check if body is empty
    if (body == '') {
      alert("內容不能為空");
      return;
    }

    // check if there's at least one user selected
    if (selectedUsers.length == 0) {
      alert("請選擇最少一個接收者");
      return;
    }

    // create a submit data object
    let submitData = {
      title: title,
      body: body,
      url: url,
      userID: selectedUsers.map((user) => user.value),
      type: "manual",
      jobID: job["_id"],
      organizationID: sessionStorage.getItem('organizationId')
    }

    // prompt users confirmation 
    var r = window.confirm("確定要發送通知嗎？");
    if (!r) {
      return;
    }

    let fetchUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/notification/push";
    fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('idToken')
      },
      body: JSON.stringify(submitData)
    }).then(response => response.json())
    .then(data => {
      alert("通知已發送");
      setOpen(false);
    }).catch(error => {
      console.log(error);
      alert("發送通知時出現問題");
    });

  }


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-[10%] flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <BellAlertIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[90%]">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      手動發送通知
                    </Dialog.Title>
                    <div className="mt-2">
                      {/* an input field for title which is optional */}
                      <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                        標題
                      </label>
                      <input 
                        type="text" 
                        name="title" 
                        id="title" 
                        placeholder="標題"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                      {/* a required input field for body */}
                      <label htmlFor="body" className="block text-sm font-medium text-gray-700 mt-2">
                        內容
                      </label>
                      <textarea 
                        type="text" 
                        name="body" 
                        id="body" 
                        placeholder="內容"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                        onChange={(e) => setBody(e.target.value)}
                        value={body}
                      />
                      {/* an optional field for url */}
                      <label htmlFor="url" className="block text-sm font-medium text-gray-700 mt-2">
                        連結
                      </label>
                      <div className="mt-2 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                          https://
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="www.example.com"
                          onChange={(e) => setUrl(e.target.value)}
                          value={url}
                        />
                      </div>
                      {/* a multi select field for selecting receiver */}
                      <label htmlFor="user" className="block text-sm font-medium text-gray-700 mt-2">
                        接收者
                      </label>
                      <MultiSelect
                        options={users}
                        value={selectedUsers}
                        onChange={setSelectedUsers}
                        labelledBy="Select"
                        className="mt-1"
                      />

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto duration-300"
                    onClick={() => handleSendNotification(job)}
                  >
                    發送
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    取消
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )

}

// assignedTo list component. 
function AssignedToList({assignedTo, mainType, staff, setStaff}) {
  // fetch existing list of staff
  const [staffList, setStaffList] = useState([]);
  const [staffListOptions, setStaffListOptions] = useState([]);
  const [originalStaffList, setOriginalStaffList] = useState([]);

  // create staff list options 
  useEffect(() => {
    // return if assignedTo is not defined
    if (assignedTo === undefined) {
      return;
    }

    let organizationID = sessionStorage.getItem("organizationId");
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/retrieve-with-main-type?organizationID=" + organizationID;
    
    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData["users"];

        // convert data to [{label: <name>, options: [{label: <name>, value: <_id>}, ...]}, ...]
        let newStaffListOptions = [];
        for (var key in data) {
          let staffList = data[key];
          let newStaffList = staffList.map((staff) => {
            let group = staff.group || ''; // handle undefined group
            return {
              label: staff.name + (group ? ` (${group})` : ''), // omit parenthesis if group is empty
              value: staff._id
            }
          });
          newStaffListOptions.push({
            label: key,
            options: newStaffList
          });
        }
        
        setStaffListOptions(newStaffListOptions);
        setOriginalStaffList(newStaffListOptions);

        // find the staff object from the list and set staff
        for (var i = 0; i < newStaffListOptions.length; i++) {
          let staffList = newStaffListOptions[i]["options"];

          // go through each staff in the list
          for (var j = 0; j < staffList.length; j++) {
            let staff = staffList[j];

            // if current user is found, set the main type and staff
            if (staff.value === assignedTo) {
              setStaff(staff);
              return;
            }
          }
        }

      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, [assignedTo]);

  // handle assignTo selection
  const handleAssignToSelection = (selectedOption) => {
    // return if selectedOption is empty
    if (selectedOption === "" || selectedOption === undefined || selectedOption === null) {
      return;
    }

    // find object containing the selectedOption
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // go through each staff in the list
      for (var j = 0; j < staffList.length; j++) {
        let staff = staffList[j];

        // if current user is found, set the main type and staff
        if (staff.value === selectedOption.value) {
          setStaff(staff);
          return;
        }
      }
    }
  };


  // filter staff list based on mainType
  useEffect(() => {
    if (mainType === "" || mainType === undefined || mainType === null) {
      return;
    }
    
    let mainTypeName = mainType['langVar']['tc'];
    // reorder the list so the mainType is at the top and the rest is sorted alphabetically
    let newStaffListOptions = [];
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // if the mainType is found, add to newStaffListOptions
      if (staffListOptions[i]["label"].includes(mainTypeName)) {
        newStaffListOptions.push({
          label: staffListOptions[i]["label"],
          options: staffList
        });
      }
    }

    // sort the rest of the list alphabetically
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // if the mainType is found, add to newStaffListOptions
      if (!staffListOptions[i]["label"].includes(mainTypeName)) {
        newStaffListOptions.push({
          label: staffListOptions[i]["label"],
          options: staffList
        });
      }
    }
    console.log(newStaffListOptions)
    // update staffListOptions
    setStaffListOptions(newStaffListOptions);

  }, [mainType, originalStaffList]);


  return(
    <>
      <label htmlFor="staff_input" className="block text-sm font-medium leading-6 text-gray-900">施工人員</label>     
      <Select 
        options={staffListOptions}
        value={staff}
        onChange={handleAssignToSelection}
      />
    </>
  )
}

// dialog window for transferring job to another users
function JobTransferDialog({job, open, setOpen}) {
  const [selectedStaff, setSelectedStaff] = useState(null);

  const confirmBtnRef = useRef(null)

  const cancelButtonRef = useRef(null)

  // get list of existing main types
  const [mainTypeList, setMainTypeList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/maintypes?lang=tc&organizationID=" + localStorage.getItem("organizationId");

    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(data => {
        // convert itemCode to string and update name (e.g. <itemCode>-<name>)
        for (var i = 0; i < data["types"].length; i++) {
          let itemCode = data["types"][i]["itemCode"];

          // zero pad the itemCode if it is less than 2 digits
          if (itemCode < 10) {
            itemCode = "0" + itemCode.toString();
          } else {
            itemCode = itemCode.toString();
          }

          data["types"][i]["name"] = itemCode + "-" + data["types"][i]["name"];
        }

        // sort the list by name
        data["types"].sort((a, b) => (a.name > b.name) ? 1 : -1);

        // set main type list
        setMainTypeList(data["types"]);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // extract mainType from job object 
  const [mainType, setMainType] = useState(null);
  useEffect(() => {
    if (job.hasOwnProperty("meta")) {
      
      if (job.meta.hasOwnProperty("mainType")) {
        // if mainTypeList length is not zero, match mainType obj
        let mainTypeObject = mainTypeList.find(item => item._id === job.meta.mainType);
        setMainType(mainTypeObject);
      }
    }
  }, [job, mainTypeList])

  // handle job transfer
  const handleJobTransfer = () => {

    // prompt users for confirmation
    if (!window.confirm("確定轉移工作嗎?")) {
      return
    }

    // disable button 
    confirmBtnRef.current.disabled = true;


    // create PUT request to update status and assignedTo
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/transfer-job";
    let data = {
      jobID: job._id,
      assignedTo: selectedStaff.value,
      userID: localStorage.getItem("userid")

    }

    // prompt users if they want to send phone notification
    if (window.confirm("是否發送電話通知?")) {
      data["sendPhoneNotification"] = true;
    }

    // create a fetch request
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('idToken')
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if (data["message"] == "Job transferred") {
        alert("工作已轉移");
        // close the window
        setOpen(false);

        // refresh the page
        window.location.reload();
      } else {
        alert("轉移工作時出現問題, 請再試一次");

        // re-enable the button
        confirmBtnRef.current.disabled = false;
      }
    })

  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-visible relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 w-[90vw]">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UsersIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      轉移工作
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        請選擇一個接收者
                      </p>
                    </div>
                  </div>
                </div>
                {/* a selector for selecting a set of users */}
                <div className="mt-4">
                  <AssignedToList assignedTo={job.assignedTo} mainType={mainType} staff={selectedStaff} setStaff={setSelectedStaff} />
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={handleJobTransfer}
                    ref={confirmBtnRef}
                  >
                    轉移
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    取消
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default function JobDetail() {
  const navigate = useNavigate();
  const [job, setJob] = useState({});
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openJobTransferForm, setOpenJobTransferForm] = useState(false);

  const [showSelfDisappearNotification, setShowSelfDisappearNotification] = useState(false);
  const [selfDisappearNotificiationTitle, setSelfDisappearNotificationTitle] = useState('');
  const [selfDisappearNotificationBody, setSelfDisappearNotificationBody] = useState('');

  const [isAdmin, setIsAdmin] = useState(false);

  // query current user role
  useEffect(() => {
    // get users info 
    var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?userID=" + localStorage.getItem("userid");
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data["user"].hasOwnProperty("type")) {
          if (data["user"]["type"] == "admin") {
            setIsAdmin(true);
          }
        }
      })
      .catch(error => {
        console.log(error);
      })
  }, []);



  // get the job id from the url
  var url = window.location.href;
  var id = url.substring(url.lastIndexOf('/') + 1);

  // fetch the job details from the api
  const fetchJobDetails = () => {
    var host = GlobalVars.BACKEND_DOMAIN;
    fetch(host + "/api/v1/jobs?jobID=" + id + "&lang=tc",{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
          .then(response => response.json())
          .then(data => {
            setJob(data["job"])
          })
          .catch(error => function() {
              console.log(error);
              // alert something wrong with fetching data
              alert("Something went wrong with fetching data");

              return (
                <>
                  <ApplicationShell>
                    <div className="flex justify-between mb-5 ">
                      <button
                          onClick={handleGoBack}
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                          返回
                      </button>
                    </div>
                    <div className="flex justify-center">
                      <p>未能找到此工作</p>
                    </div>
                  </ApplicationShell>
                </>
              )
          });
  
  };

  // initial fetch
  useEffect(() => {
    fetchJobDetails();    
      
  }, []);

  
  
  // go back button
  const handleGoBack = () => {
      // redirect users to '/'
      navigate("/");
  };

  // toggle edit form
  const handleToggleEditForm = () => {
      // console.log(openEditForm);
      if (openEditForm) {
          setOpenEditForm(false);
      } else {
          setOpenEditForm(true);
      }
  };

  // update job status
  const [showSuccessConfetti, setShowSuccessConfetti] = useState(false);
  const updateJobStatus = (e) => {
    // verify users right to update job status (Only admin and assignedTo can update job status)
    var uid = localStorage.getItem('userid');
    var assignedTo = job.assignedTo;
    var status = job.latestStatus.status;
  
    // check if users have the right to update job status
    if (status != '待確認' && uid != assignedTo && !isAdmin) {
      alert("只有負責人員或是管理者才能更新工作狀態");
      return;
    }

    // prompt confirmation
    if (status != '待確認' || (status == '待確認' && uid == assignedTo)) {
      let message = "確定要更新工作狀態嗎？";

      // check if there's a job remark or client remark, add them to the message
      if (job.meta.hasOwnProperty('jobRemark') && job.meta.jobRemark != '') {
        message += "\n工作備註: " + job.meta.jobRemark;
      }

      if (job.meta.hasOwnProperty('clientRemark') && job.meta.clientRemark != '') {
        message += "\n客戶備註: " + job.meta.clientRemark;
      }

      var r = window.confirm(message);  
    } else {
      let message = "此工作尚未分配。 您確認接受這份工作嗎？"

      // check if there's a job remark or client remark, add them to the message
      if (job.meta.hasOwnProperty('jobRemark') && job.meta.jobRemark != '') {
        message += "\n工作備註: " + job.meta.jobRemark;
      }

      if (job.meta.hasOwnProperty('clientRemark') && job.meta.clientRemark != '') {
        message += "\n客戶備註: " + job.meta.clientRemark;
      }


      var r = window.confirm(message);
    }
    if (!r) {
      return;
    }

    // based on status update the job status
    var newStatus = '';

    if (status == '新工作' ) {
      newStatus = '待確認';
    } else if (status == '待確認' || status == '已轉移') {
      newStatus = '未開始';
    } else if (status == '未開始') {
      newStatus = '進行中';
    } else if (status == '進行中') {
      if (job.meta.hasOwnProperty('requiredInspection') && job.meta.requiredInspection == true) {
        newStatus = '待檢查';
      } else {
        newStatus = '完成';
      }
    } else if (status == '待檢查') {
      newStatus = '完成';
    } else {
      newStatus = '進行中';
    }

    let jobID = job._id;

    // fetch request to Progress job (PUT)
    var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress";
    var data = {
      jobID: jobID,
      status: newStatus,
      assignedTo: uid,
      userID: localStorage.getItem('userid')
    };
    
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('idToken')
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if (newStatus == '完成') {
        setShowSuccessConfetti(true);
        setTimeout(() => {
          setShowSuccessConfetti(false);
        }, 1500);
      }

      // check if data contain issueID
      if (data.hasOwnProperty('issueID') && newStatus == '完成') {

        if (window.confirm("成功更新工作狀態. 但發現相關工單。您想將狀態更新為已解決嗎？")) {
          let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/status";
          let body = {
              "issueID": data["issueID"],
              "status": "Resolved",
              "staffID": localStorage.getItem("userid"),
          };
          let headers = {
            "Content-Type": "application/json"
          };

          fetch(url, 
            {
                method: "PUT",
                body: JSON.stringify(body),
                headers: headers
            }
            ).then(response => {
                if (response.status === 200) {
                    if (body["status"] == "Resolved") {
                      alert("工單狀態已更新")
                    }
                  }
            })
        }
      } else {
        setShowSelfDisappearNotification(true);
        setSelfDisappearNotificationTitle("工作狀態已更新");
        setSelfDisappearNotificationBody("工作內容將會更新, 此信息將在幾秒後消失");
      }     
      
      fetchJobDetails();
    })


  };

  // pause job
  const pauseJob = (e) => {
    // verify users right to update job status (Only admin and assignedTo can update job status)
    var uid = localStorage.getItem('userid');
    var assignedTo = job.assignedTo;

    // check if job is cancelled
    if (job.latestStatus.status == '取消') {
      alert("此工作已取消, 無法暫停, 請先按繼續工作");
      return;
    }

    if (uid != assignedTo && !isAdmin) {
      alert("只有負責人員或是管理者才能更新工作狀態");
      return;
    }
    
    // if the job has already been paused, prompt users to continue the job
    if (job.latestStatus.status == '待續') {
      var r = window.confirm("此工作已暫停, 確定要繼續嗎？");
      if (!r) {
        return;
      }
    }

    // prompt confirmation
    var r = window.confirm("確定要暫停現時工作嗎？");
    if (!r) {
      return;
    }

    // get current job status
    let status = job.latestStatus.status;
    console.log(status);
    if (status == '未開始') {
      r = window.confirm("此工作還未開始, 確定要暫停嗎？");
      if (!r) {
        return;
      }
    }

    let jobID = job._id;

    // fetch request to Progress job (PUT)
    var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress";
    var data = {
      jobID: jobID,
      status: "待續",
      assignedTo: localStorage.getItem('userid'),
      userID: localStorage.getItem('userid')
    };
    
    fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('idToken')
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        // console.log('Success:', data);
        // setJob(data['job']);
        setShowSelfDisappearNotification(true);
        setSelfDisappearNotificationTitle("工作已暫停");
        setSelfDisappearNotificationBody("工作內容將會更新, 此信息將在幾秒後消失");
        fetchJobDetails();
      })


  };

  // cancel job
  const cancelJob = (e) => {
    // verify users right to update job status (Only admin and assignedTo can update job status)
    var uid = localStorage.getItem('userid');
    var userType = localStorage.getItem('userType');
    var assignedTo = job.assignedTo;

    if (uid != assignedTo && !isAdmin) {
      alert("只有負責人員或是管理者才能更新工作狀態");
      return;
    }

    // prompt confirmation
    var r = window.confirm("確定要取消工作嗎？");
    if (!r) {
      return;
    }

    let jobID = job._id;

    // fetch request to Progress job (PUT)
    var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress";
    var data = {
      jobID: jobID,
      status: "取消",
      assignedTo: localStorage.getItem('userid')
    };
    
    fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('idToken')
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        
        // setJob(data['job']);
        setShowSelfDisappearNotification(true);
        setSelfDisappearNotificationTitle("工作已取消");
        setSelfDisappearNotificationBody("工作內容將會更新, 此信息將在幾秒後消失");

        // check if data contain issueID
        if (data.hasOwnProperty('issueID')) {
          if (window.confirm("已取消工作. 但發現相關工單。您想將狀態更新為取消嗎？")) {
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/status";
            let body = {
                "issueID": data["issueID"],
                "status": "Canceled",
                "staffID": localStorage.getItem("userid"),
            };
            let headers = {
              "Content-Type": "application/json"
            };

            fetch(url, 
              {
                  method: "PUT",
                  body: JSON.stringify(body),
                  headers: headers
              }
              ).then(response => {
                  if (response.status === 200) {
                      if (body["status"] == "Canceled") {
                        alert("工單狀態已取消")
                        window.location.reload();
                      }
                    }
              })
              .catch(error => {
                console.log(error);
              })

          }

        } else {
          window.location.reload();
        }

        
      })

    };
    
  // copy job by openinging new job form and prefilling the form with existing job details
  const [newFormOpenState, setNewFormOpenState] = useState(false);
  const copyJob = (e) => {
    setNewFormOpenState(true);
  };
  
  const [attachments, setAttachments] = useState([]);
  const [selectedPreviewAttachment, setSelectedPreviewAttachment] = useState(null);

  const [openManualUserAlert, setOpenManualUserAlert] = useState(false);

  // get global user role context
  const {globalUserRole} = useContext(UserContext);

  // module
  const [moduleOpen, setModuleOpen] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [openModuleNotification, setOpenModuleNotification] = useState({
    "type": "success",
    "display": false
  });


  return (
      <>
          
            <ApplicationShell>
                {showSuccessConfetti && <ConfettiExplosion />}
                <div className="flex justify-between mb-5 ">
                    <button
                        onClick={handleGoBack}
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        返回
                    </button>
                    <div className="flex items-center">
                    {globalUserRole !== "viewer" && (
                    <span className="isolate rounded-md shadow-sm">
                        {!job.alertOnly && (
                          <button
                              type="button"
                              className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                              onClick={pauseJob}
                          >
                              暫停工作
                          </button>
                          
                        )}
                        <button
                            onClick={handleToggleEditForm}
                            type="button"
                            className={`${(job.alertOnly) && ("rounded-md")}  relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                        >
                          工作詳細
                        </button>
                        {!job.alertOnly && (
                          <button
                              type="button"
                              id="prim-action"
                              className="relative -ml-px inline-flex items-center rounded-r-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                              onClick={updateJobStatus}
                          >
                              收到通知
                          </button>
                        
                        )}
                    </span>
                    )}
                    <FurtherAction cancelJob={cancelJob} copyJob={copyJob} setOpenManualUserAlert={setOpenManualUserAlert} jobID={job._id} setOpenJobTransferForm={setOpenJobTransferForm} />
                    </div>
                </div>
                <JobDetailDisplay 
                  job={job} 
                  attachments={attachments} 
                  setAttachments={setAttachments} 
                  setSelectedPreviewAttachment={setSelectedPreviewAttachment}
                  setModuleName={setModuleName}
                  setModuleOpen={setModuleOpen}
                />
                <JobEditForm state={openEditForm} setEditState={setOpenEditForm} jobDetail={job} />
            </ApplicationShell>
            <NewJobForm state={newFormOpenState} setEditState={setNewFormOpenState} jobPrefill={job} />
            <FileViewer selectedFile={selectedPreviewAttachment} setSelectedFile={setSelectedPreviewAttachment} attachments={attachments} />
            <ManualUserAlert open={openManualUserAlert} setOpen={setOpenManualUserAlert} job={job} />
            <JobTransferDialog job={job} open={openJobTransferForm} setOpen={setOpenJobTransferForm} />
            <SelfDisappearNotification 
              show={showSelfDisappearNotification} 
              setShow={setShowSelfDisappearNotification} 
              title={selfDisappearNotificiationTitle}
              body={selfDisappearNotificationBody}
            />
          <ModuleDrawer 
            open={moduleOpen}
            setOpen={setModuleOpen}
            moduleName={moduleName}
            setOpenModuleNotification={setOpenModuleNotification}
            jobID={job['_id']}
          />
          <ModuleNotification 
            show={openModuleNotification}
            setShow={setOpenModuleNotification}
          />
          
      </>
  );
};



