import ApplicationShell from "../../Components/ApplicationShell";
import { useState, useEffect } from "react";
import GlobalVars from "../../Config";
import { 
    PaperClipIcon, 
    DocumentIcon,
    PhotoIcon,
    TableCellsIcon,
    VideoCameraIcon,
    
  } from '@heroicons/react/20/solid';

const files = [
    {
      title: 'IMG_4985.HEIC',
      size: '3.9 MB',
      source:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
    },
    // More files...
  ]

export default function Documents() {
    // set document title
    document.title = "文件";

    // display attachments based on job id 
    const [attachments, setAttachments] = useState([]);
    const attachmentIcons = {
      "application/pdf": "DocumentIcon",
      "image/png": "PhotoIcon",
      "image/jpeg": "PhotoIcon",
      "image/jpg": "PhotoIcon",
      "image/gif": "PhotoIcon",
      "text/csv": "TableCellsIcon",
      "text/plain": "DocumentIcon",
      "video/mp4": "VideoCameraIcon",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "TableCellsIcon",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DocumentIcon",
    }

    // search query of files 
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearch = (value) => {
        setSearchQuery(value);
    };

    // get search query from url argument <search>
    const url = window.location.href;
    const urlSplit = url.split("?");
    const urlParams = new URLSearchParams(urlSplit[1]);

    // fetch document based on organization id 
    useEffect(() => {
        let organizationID = sessionStorage.getItem("organizationId");
        var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/files?organizationID=" + organizationID;

        // if search query is not empty, add search query to url
        if (urlParams.get("search") != null) {
            url += "&search=" + urlParams.get("search");
        }

        fetch(url)
        .then(response => response.json())
        .then(data => {
            let attachmentList = data['attachments'];
            attachmentList.forEach(attachment => {
            attachment['icon'] = attachmentIcons[attachment['fileType']];
            });

            setAttachments(data['attachments']);
        })
    }, []);
    
    // create a signed url for downloading the file
    const downloadFile = (e) => {
      // deactivate fileupload button
      document.getElementById(e.target.id).disabled = true;

      // change button text to 下載中...
      document.getElementById(e.target.id).innerHTML = '下載中...';

      // fetch request to retrieve signed url to upload to cloud storage (endpoint: /api/v1/signed-url)
      var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/signed-url?fileID=" + e.target.id;
      fetch(url, {
        method: 'GET',
      }).then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        // upload file to cloud storage
        var signedUrl = data['url'];
        window.open(signedUrl);

        // reactivate fileupload button
        document.getElementById(e.target.id).disabled = false;

        // change button text to 下載
        document.getElementById(e.target.id).innerHTML = '下載';
      })
    }
    return (
        <>
            <ApplicationShell queryAction={handleSearch} >
              <PageHeadings />
              <dd className="mt-4 text-sm text-gray-900">
                <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                  {attachments.map((attachment) => {
                    return (
                      <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                        <div className="flex w-0 flex-1 items-center">
                          <Icon type={attachment.fileType} />
                          <div className="ml-4 flex min-w-0 flex-1 gap-2 skip-trans">
                            <span className="truncate font-medium">{attachment.fileName}</span>
                            <span className="flex-shrink-0 text-gray-400">{attachment.createdAt}</span>
                          </div>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <button  
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                          onClick={downloadFile}
                          id={attachment._id}
                          >
                            下載
                          </button>
                        </div>
                      </li>
                  )})}
                </ul>
              </dd>
            </ApplicationShell>
        </>
    )
}

// create an icon component for each attachment type
function Icon({type}) {
    if (type == 'application/pdf') {
      return <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
    } else if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg' || type == 'image/gif') {
      return <PhotoIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
    } else if (type == 'text/csv' || type == 'text/plain') {
      return <TableCellsIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
    } else if (type == 'video/mp4') {
      return <VideoCameraIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
    } else if (type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return <TableCellsIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
    } else if (type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
    } else {
      return <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
    }
}

// page headings
function PageHeadings() {
  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          文件
        </h2>
        <p
          className="mt-1 text-sm text-gray-500"
        >
          在這裡你會找到過往工作中上傳的文件
        </p>
      </div>
      {/* <div className="mt-4 flex md:ml-4 md:mt-0">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Edit
        </button>
        <button
          type="button"
          className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Publish
        </button>
      </div> */}
    </div>
  )
}


