// Query current work status of the selected staff
import GlobalVars from "../../../../Config";
import { useEffect, useState } from "react";
import { InformationCircleIcon } from '@heroicons/react/20/solid'


export default function SelectedStaffCurrStatus({staff, setSendPhoneNotification}) {
    const [isWorking, setIsWorking] = useState(false);
    const [jobId, setJobId] = useState(null);
    const [isSameOrg, setIsSameOrg] = useState(false);

    useEffect(() => {
        // return if id is null
        if (!staff || staff === null) {
            setIsWorking(false);
            return;
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/latest-status?userId=" + staff['value'] + "&organizationId=" + localStorage.getItem("organizationId");

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                setIsWorking(false);
                throw new Error("Failed to fetch current status");
            }
        })
        .then(data => {
            for (let i = 0; i < data["status"].length; i++) {
                if (data["status"][i]['latestStatus']['status'] === "In Progress") {
                    setIsWorking(true);
                    setJobId(data["status"][i]["_id"]);

                    // check if the job is in the same org
                    if (data["status"][i]["organizationID"] === localStorage.getItem("organizationId")) {
                        setIsSameOrg(true);
                    }

                    break;
                } else {
                    setIsWorking(false);
                }
            }
        })
        .catch(error => console.error(error));
    }, [staff]);

    return (
        <div>
            {(isWorking && isSameOrg) && (
                <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                        <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-blue-700">同事
                            <a
                                href={`/jobs/${jobId}`}
                                target="_blank"
                                className="text-blue-700 hover:text-blue-600 underline duration-300"
                            >
                                正在工作
                            </a>
                            , 建議延遲發送電話通知.</p>
                        <p className="mt-3 text-sm md:ml-6 md:mt-0">
                            <a 
                                onClick={() => setSendPhoneNotification(false)}
                                className="duration-300 cursor-pointer whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                            >
                            取消電話通知
                            <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}