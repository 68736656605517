// This component is used to suggest a staff to assign to the job
// It is a series of buttons that the user can click to assign the job to the staff
import { useState, useEffect } from "react";
import GlobalVars from "../../../../Config";


export default function AssignedToAISuggestion({handleAssignToSelection, locationIDs, jobTypes}) {
    const [assignedToSuggesstion, setAssignedToSuggesstion] = useState([]);

    // fetch the suggestion from the assistant
    useEffect(() => {
        // return if both locationIDs and jobTypes are empty
        if (locationIDs.length === 0 && jobTypes.length === 0) {
            return;
        }

        fetch(`${GlobalVars.ASSISTANT_DOMAIN}/api/v1/job/find-staff`, {
            method: "POST",
            body: JSON.stringify({
                "locationIDs": locationIDs,
                "jobTypes": jobTypes
            }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": sessionStorage.getItem("idToken")
            }
        })
        .then(res => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Failed to fetch suggestion");
            }
        })
        .then(data => {
            console.log(data)
            setAssignedToSuggesstion(data)
        })
        .catch(error => console.error(error));
    }, [locationIDs, jobTypes]);

    const handleAssignToSuggestionClick = (ind) => {
        handleAssignToSelection(assignedToSuggesstion[ind])
    }

    return (
        <>
        {assignedToSuggesstion.length > 0 && (
            <div
                className=""
            >
                <span className="text-xs text-gray-500">建議選項</span>
                <div>
                    {assignedToSuggesstion.map((staff, ind) => (
                        <button 
                            key={ind} 
                            onClick={() => handleAssignToSuggestionClick(ind)}
                            className="text-left mt-2 mr-1 inline-flex items-center px-4 py-2 border border-indigo-600 text-xs font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-700 hover:text-white"
                        >
                            {staff.label}
                    </button>
                    ))}
                </div>
            </div>
        )}
        </>
    )
}