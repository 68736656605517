import GlobalVars from "../../../../../../Config";

export default function LeaveAction({setOpen}) {
    const handleSubmit = () => {
        // check if startDate is before endDate
        let startDate = document.getElementById("start-date").value;
        let endDate = document.getElementById("end-date").value;
        if (startDate > endDate) {
            alert("開始日期不可晚於結束日期");
            return;
        }

        // prompt users for confirmation
        if (window.confirm("確定要新增請假紀錄嗎？")) {
            // ensure users enter all required fields except remarks
            let reasons = document.getElementById("reasons").value;
            let startDate = document.getElementById("start-date").value;
            let endDate = document.getElementById("end-date").value;
            let halfDayLeave = document.getElementById("half-day-leave").value;

            if (reasons === "" || startDate === "" || endDate === "" || halfDayLeave === "") {
                alert("請填寫所有必填欄位" + "\n" + "請假原因、開始日期、結束日期");
                return;
            }

            let submitData = {
                "userID": localStorage.getItem("userid"),
                "startDate": startDate,
                "endDate": endDate,
                "reason": reasons,
                "type": halfDayLeave,
                "remarks": document.getElementById("remarks").value,
                "organizationID": sessionStorage.getItem("organizationId"),
            }
            
            // create post request to /api/v1/leaves
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/leaves";
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(submitData)
            }).then(response => {
                if (response.status != 200) {
                    throw new Error("新增請假紀錄失敗");
                }
                return response.json()
            })
            .then(data => {
                alert("請假紀錄新增成功");
                setOpen(false);
            }).catch(error => {
                console.log(error);
                alert("請假紀錄新增失敗");
                setOpen(false);
            });


        }
    }

    return (
        <button
            type="button"
            className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            onClick={handleSubmit}
        >
            儲存
        </button>
    )

}