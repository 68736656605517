import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition, MenuButton, MenuItems, MenuItem, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  BriefcaseIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  ListBulletIcon,
  MapPinIcon,
  SignalIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
  BugAntIcon,
  DocumentMagnifyingGlassIcon,
  ChatBubbleLeftRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PlusIcon,
  CheckIcon,
  CalendarDaysIcon,
  BuildingOfficeIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import GlobalVars from '../../Config'
import { useWeglot } from 'react-weglot'

// import components
import InfoTag from './Components/InfoTag'

// firebase
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// import components
import QuickJobSlideOver from '../QuickJobSlideOver'
import NotificationWithAction from '../NotificationWithAction'
import NotificationList from '../NotificationList'
import ChatInterfaceSlideOver from '../ChatInterfaceSlideOver';
import NotificationPermissionDialog from '../NotificationPermissionDialog'
import SearchPalettes from '../SearchPalettes/SearchPalettes'
import OrganizationSelection from './Components/OrganizationSelection'

// import functionality libraries
import PullToRefresh from 'react-simple-pull-to-refresh';

const navigation = [
  { name: '工作列表', href: '/job-list', icon: ListBulletIcon, current: true },
  { name: '儀表板', href: '/dashboard', icon: ChartBarIcon, current: false },
  { name: '我的工作', href: '/my-jobs', icon: BriefcaseIcon, current: false },
  { name: '事件記錄', href: '/issue-reporting', icon: BugAntIcon, current: false},
  { name: '用戶', href: '/user-list', icon: UsersIcon, current: false },
  { name: '地點', href: '/location-list', icon: MapPinIcon, current: false },
  { name: '文件', href: '/documents', icon: DocumentDuplicateIcon, current: false },
  { name: '工作類別', href: '/type-list', icon: WrenchScrewdriverIcon, current: false },
  { name: '日曆', href: '/calendar', icon: CalendarDaysIcon, current: false },
  // { name: ' AI助手', href: '/assistant-list', icon: SparklesIcon, current: false },
  { name: '知識庫', href: '/knowledge-base', icon: DocumentMagnifyingGlassIcon, current: false },
]
const sysSuggest = [
  // { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  // { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  // { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]
const userNavigation = [
  { name: '回到公司', href: '/attendance', style: "highlight"},
  { name: '個人資料', href: '/user-profile'},
  { name: '登出', href: '/signout'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// refresh idtoken based on the last time it was updated in sessionStorage. If it's more than 30 minutes, refresh it
let timeLimit = 1000 * 60 * 15; // 15 minutes
function RefreshIdToken() { 
  console.log("Checking and refreshing idtoken...")
  let lastUpdateTime = sessionStorage.getItem("lastIdTokenUpdateTime");
  if (!lastUpdateTime || (new Date().getTime() - lastUpdateTime) > timeLimit) { 
    console.log("Refreshing idToken...")
    const firebaseApp = initializeApp(GlobalVars.firebase);
    const auth = getAuth(firebaseApp);
    
    // get new idtoken and update it in sessionStorage
    const refreshToken = (user) => {
      return user.getIdToken(true)
        .then((idToken) => {
          console.log("idToken refreshed");
          sessionStorage.setItem("idToken", idToken);
          sessionStorage.setItem("lastIdTokenUpdateTime", new Date().getTime());
        })
        .catch((error) => {
          console.log("Error refreshing idToken:", error);
          window.location.href = "/login";
        });
    };
    
    if (auth.currentUser) {
      refreshToken(auth.currentUser);
    } else {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          refreshToken(user);
        } else {
          console.log("No user is signed in");
          window.location.href = "/login";
        }
      });
    }
  }
}
// let timeLimit = 1000 * 60 * 15; // 30 minutes
// function RefreshIdToken() { 
//   console.log("Checking and refreshing idtoken...")
//   let lastUpdateTime = sessionStorage.getItem("lastIdTokenUpdateTime");
//   if (!lastUpdateTime || (new Date().getTime() - lastUpdateTime) > timeLimit) { 
//     console.log("Refreshing idToken...")
//     const firebaseApp = initializeApp(GlobalVars.firebase);
//     const auth = getAuth(firebaseApp);
    
//     // get new idtoken and update it in sessionStorage
//     const refreshToken = async (user) => {
//       try {
//         const idToken = await user.getIdToken(true);
//         console.log("idToken refreshed");
//         sessionStorage.setItem("idToken", idToken);
//         sessionStorage.setItem("lastIdTokenUpdateTime", new Date().getTime());
//       } catch (error) {
//         console.log("Error refreshing idToken:", error);
//         window.location.href = "/login";
//       }
//     };
    
//     try {
//       if (auth.currentUser) {
//         await refreshToken(auth.currentUser);
//       } else {
//         onAuthStateChanged(auth, (user) => {
//           if (user) {
//             refreshToken(user);
//           } else {
//             console.log("No user is signed in");
//             window.location.href = "/login";
//           }
//         });
//       }
//     } catch (error) {
//       console.log("Error in token refresh flow:", error);
//       window.location.href = "/login";
//     }
//     // try {
//     //   auth.currentUser.getIdToken(true).then((idToken) => {
//     //     console.log("idToken refreshed");
//     //     sessionStorage.setItem("idToken", idToken);
//     //     sessionStorage.setItem("lastIdTokenUpdateTime", new Date().getTime());
//     //   }).catch((error) => {
//     //     // navigate users to login page
//     //     console.log("Error refreshing idToken: ", error);
//     //     window.location.href = "/login";
//     //   });
//     // } catch (error) {
//     //   console.log("Error refreshing idToken: ", error);
//     //   onAuthStateChanged(auth, (user) => {
//     //     if (user) {
//     //       user.getIdToken(true).then((idToken) => {
//     //         console.log("idToken refreshed");
//     //         sessionStorage.setItem("idToken", idToken);
//     //         sessionStorage.setItem("lastIdTokenUpdateTime", new Date().getTime());
//     //       }).catch((error) => {
//     //         // navigate users to login page
//     //         console.log("Error refreshing idToken: ", error);
//     //         window.location.href = "/login";
//     //       });
//     //     } else {
//     //       console.log("No user is signed in");
//     //       window.location.href = "/login";
//     //     }
//     //   });
//     // }
//   }
// }

export default function ApplicationShell({children, queryText, setQueryText, queryAction, fullHeight=false}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [openNotificationList, setOpenNotificationList] = useState(false);
  
  // Get the current URL
  const url = new URL(window.location.href);

  // adjust zoom level
  useEffect(() => {
    AdjustScreenZoomLevel();
  }, []);

  // retrieve displayName from sessionStorage and if it doesn't exist, fetch from backend
  const [displayName, setDisplayName] = useState("Loading...");
    
  useEffect(() => {
    // retrieve displayName from sessionStorage
    if (sessionStorage.getItem("displayName")) {
        setDisplayName(sessionStorage.getItem("displayName"));
    } else {
      
      // fetch data from backend
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?firebaseID=" + sessionStorage.getItem("firebaseUid");
      
      fetch(url,{
        headers: {
          'Authorization': sessionStorage.getItem('idToken')
        }
      })
          .then(response => response.json())
          .then(data => {
              sessionStorage.setItem("displayName", data['user']["displayName"]);
              sessionStorage.setItem("organizationId", data['user']["organizationID"]);

              // set displayName
              setDisplayName(data['user']["displayName"]);

          })
          .catch(error => function() {
              console.log(error);
              // alert something wrong with fetching data
              alert("Something went wrong with fetching data");
          });
    }
    
  }, []);

  
  // Get the pathname from the URL
  const pathname = url.pathname;

  // Iterate over the tabs
  for (let i = 0; i < navigation.length; i++) {
    // Check if the href property of the tab matches the pathname
    if (navigation[i].href === pathname) {
      // If it does, set the current property to true
      navigation[i].current = true;
    } else {
      // If it doesn't, set the current property to false
      navigation[i].current = false;
    }
  }

  // new notification alert
  const [newNotification, setHaveNewNotification] = useState(false);

  const openedNotifyFeed = sessionStorage.getItem("openedNotificationFeed");

  // control chat interface slide over
  const [displayChatInterface, setDisplayChatInterface] = useState(false);
  const [fixedChatInterface, setFixedChatInterface] = useState(false);
  const [chatMsgId, setChatMsgId] = useState(null);

  // collapse sidebar and display menu bar
  const [displayMenuBar, setDisplayMenuBar] = useState(true);
  useEffect(() => {
    // if it's a mobile device, set displayMenuBar to false
    if (window.innerWidth < 1024) {
      setDisplayMenuBar(false);
    }
  }, []);

  // store users' session preference for displaying chat interface
  useEffect(() => {
    sessionStorage.setItem("displayChatInterface", displayChatInterface);
  }, [displayChatInterface]);

  // manage search palettes
  const [openSearchPalettes, setOpenSearchPalettes] = useState(false);

  // check and update firebase token every time the page is loaded
  useEffect(() => {
    RefreshIdToken();
  }, []);
  // rerun refreshIdToken every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      RefreshIdToken();
    }, 15000);
    return () => clearInterval(interval);
  }, []);  

  // manage create new organization
  const [openNewOrgDialog, setOpenNewOrgDialog] = useState(false);

  return (
    <div id="main-app-shell">
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 " onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center justify-between">
                      <div
                        className='flex items-center gap-x-4'
                      >
                        <img
                          className="h-8 w-auto"
                          src="/logo.png"
                          alt="Golden News"
                        />
                        <OrganizationSelection 
                          setOpenNewOrgDialog={setOpenNewOrgDialog}
                        />
                      </div>
                      <button
                        onClick={() => {
                          setSidebarOpen(false)
                          setDisplayMenuBar(true);
                        }}
                        className='text-gray-400 hover:bg-slate-500 hover:text-white rounded-2xl duration-300 p-1 hidden lg:block'
                      >
                        <ChevronDoubleRightIcon className='h-6 w-6 text-gray-400 hover:bg-slate-500 hover:text-white rounded-2xl duration-300 p-1'/>
                      </button>
                    </div>
                    <nav className="flex flex-1 flex-col dynamic-comp">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        {/* <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? 'text-indigo-600 border-indigo-600'
                                        : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li> */}
                        <li className="mt-auto">
                          <a
                            href="/nfc-job-progress"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <SignalIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            NFC
                          </a>
                          <a
                            href="/settings"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            設定
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className={`hidden ${displayMenuBar && "lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"}`}>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center justify-between">
              <div
                className='flex items-center gap-x-4'
              >
                <img
                  className="h-8 w-auto"
                  src="/logo.png"
                  alt="Golden News"
                />
                <OrganizationSelection 
                  setOpenNewOrgDialog={setOpenNewOrgDialog}
                />
              </div>
              <button
                type="button"
                className=" text-gray-400 hover:bg-slate-500 hover:text-white rounded-2xl duration-300 p-1"
                onClick={() => {
                  setDisplayMenuBar(!displayMenuBar);
                  setSidebarOpen(false);
                }}
              >
                <ChevronDoubleLeftIcon className="h-6 w-6" aria-hidden="true" onClick={() => setSidebarOpen(false)} />
              </button>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400 flex items-center">
                    系統建議 
                    <div className='ml-1 flex items-center'>
                      <InfoTag 
                        content={"以下為我們根據你過往使用所提出的建議, 包含工作類別, 地點, 用戶等等."} 
                        type="info" 
                        alignLeft={true} 
                        />
                    </div>
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {sysSuggest.length == 0 ?
                      (<li className="text-gray-400 text-xs px-2">暫無</li>)
                    : (
                    sysSuggest.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? 'text-indigo-600 border-indigo-600'
                                : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                              'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    )))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                            href="/nfc-job-progress"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <SignalIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            NFC
                  </a>
                  <a
                    href="/settings"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                    設定
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className={`${displayMenuBar && "lg:pl-72"}`}>
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className={`-m-2.5 p-2.5 text-gray-700 lg:${displayMenuBar ? 'hidden' : 'block'}`} onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm outline-none"
                  placeholder="搜尋..."
                  type="search"
                  name="search"
                  onClick={() => setOpenSearchPalettes(true)}

                />
                {/* {window.innerWidth >= 800 && (
                  <div className="inset-y-0 right-0 flex py-1.5 pr-1.5 mr-10 h-[100%] items-center">
                    <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400 h-10 w-10 justify-center">
                      ⏎
                    </kbd>
                  </div>
                )} */}
              </form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <a
                  href="https://golden-news-job-management-syste.gitbook.io/jin-xun-wen-dang/"
                  target='_blank'
                  className="text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                </a>
                <button>
                  <ChatBubbleLeftRightIcon 
                    className="h-6 w-6 text-gray-400 hover:text-gray-500" 
                    aria-hidden="true" 
                    onClick={() => {
                      setDisplayChatInterface(true);
                      setChatMsgId(null);
                    }}
                  />
                </button>
                <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 relative" onClick={() => {
                    setOpenNotificationList(true)
                    sessionStorage.setItem("lastOpenNotificationTime", new Date().getTime());
                  }}>
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                  {/* red dot to indicate new notification */}
                  {newNotification && <span className="absolute block h-3 w-3 rounded-full text-white shadow-solid bg-red-400 top-2 left-2 animate-ping" />}
                </button>

                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://img.icons8.com/ios-filled/50/user-male-circle.png" 
                      alt="user-male-circle"
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true" id="user-display-name">
                        {displayName}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900',
                                item.style === "highlight" ? "font-semibold text-indigo-800" : ""
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-5 flex">
            <div
              className={`w-full`}
              id="primary-column"
              style={fixedChatInterface ? { overflow: 'scroll', height: '100vh' } : {}}
            >
              <PullToRefresh 
                onRefresh={() => window.location.reload()}
              >
                <div className={`px-4 sm:px-6 lg:px-8 ${fullHeight ? "" : "pb-[100px]"}`}>{children}</div>
              </PullToRefresh>
            </div>
            {(fixedChatInterface && displayChatInterface) && (
              <div
                className='w-[45%] min-w-[400px] overflow-auto h-[100vh] hidden lg:block border-l border-gray-200'
              >
                <div
                  className='pb-[100px] h-full'
                >
                  <ChatInterfaceSlideOver 
                    open={displayChatInterface} 
                    setOpen={setDisplayChatInterface}
                    msgId={chatMsgId}
                    setMsgId={setChatMsgId}
                    fixedChatInterface={fixedChatInterface}
                    setFixedChatInterface={setFixedChatInterface}
                  />
                </div>
              </div>
            )}
          </main>

        </div>

        {/* floating notification display */}
        <NotificationWithAction />
      </div>
      <NotificationList open={openNotificationList} setOpen={setOpenNotificationList} setHaveNewNotification={setHaveNewNotification} />
      {
        !fixedChatInterface &&(
          <ChatInterfaceSlideOver 
            open={displayChatInterface} 
            setOpen={setDisplayChatInterface}
            msgId={chatMsgId}
            setMsgId={setChatMsgId}
            fixedChatInterface={fixedChatInterface}
            setFixedChatInterface={setFixedChatInterface}
          />
        )
      }
      <NotificationPermissionDialog />
      <SearchPalettes open={openSearchPalettes} setOpen={setOpenSearchPalettes} />
      <CreateNewOrganizationDialog 
        open={openNewOrgDialog}
        setOpen={setOpenNewOrgDialog}
      />
    </div>
  )
}

// adjust screen zoom level 
function AdjustScreenZoomLevel() {
  // check if userSetZoomLevel exists in localStorage
  if (!localStorage.getItem("userSetZoomLevel")) {
    // if it doesn't exist, set it to 1
    localStorage.setItem("userSetZoomLevel", "100%");
    
  } else {
    // make sure zoom level form is XX%
    let zoomLevel = localStorage.getItem("userSetZoomLevel");

    // check if % in zoomLevel
    if (zoomLevel.indexOf("%") === -1) {
      // if it doesn't exist, add % to zoomLevel
      zoomLevel += "%";
    }
    
    // check if current zoom level is different from userSetZoomLevel
    if (document.body.style.zoom !== zoomLevel) {
      // set zoom level based on userSetZoomLevel
      document.body.style.zoom = zoomLevel;
    }
  }

  return;
}

// organization selection 


function CreateNewOrganizationDialog({open, setOpen}) {
  const [orgName, setOrgName] = useState("");
  // handle create a new organization
  const handleCreateNewOrganization = () => {
    // return if orgName is empty
    if (!orgName) {
      alert("請輸入組織名稱");
      return;
    }

    // prompt user to confirm
    if (!window.confirm("確定要新增新組織嗎？ " + orgName)) {
      return;
    }

    // fetch data from backend
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/organization";
    let data = {
      name: orgName,
      userID: localStorage.getItem("userid")
    }
    fetch(url, {
      method: "POST",
      headers: {
        'Authorization': sessionStorage.getItem('idToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          // if message existing in response.json() and it equals to Organization name already taken, alert the message
          response.json().then(data => {
            if (data.message === "Organization name already taken") {
              alert("組織名稱已被使用");
              throw new Error("組織名稱已被使用");
            }
          });
        }
        
      })
      .then(data => {
        alert("新增成功, 現在前往新組織");
        // set organizationId in sessionStorage
        sessionStorage.setItem("organizationId", data['organizationID']);
        // set organizationId in localStorage
        localStorage.setItem("organizationId", data['organizationID']);
        // close the dialog
        setOpen(false);
        // refresh the screen
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        // alert("Something went wrong with fetching data");
      });

  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <BuildingOfficeIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  新增組織
                </DialogTitle>
                <div className="mt-4 space-y-2 text-left">
                  {/* a new organization form */}
                  <div>
                    <label htmlFor="organization-name" className="block text-sm font-semibold text-gray-700">
                      組織名稱
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="organization-name"
                        id="organization-name"
                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>

                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={handleCreateNewOrganization}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              >
                前往新組織
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                取消
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

