import { initializeApp, getApps } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCZ6HpNl7kZwevmiwnEcgf4RrnHO51LDtA",
    authDomain: "otter-and-seal.firebaseapp.com",
    projectId: "otter-and-seal",
    storageBucket: "otter-and-seal.appspot.com",
    messagingSenderId: "433587317721",
    appId: "1:433587317721:web:dd41293420b7d7f19047c8"
};

// initialize firebase app if not already initialized
let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApps()[0];
}
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BFh0W_PFnkeAQmnBukFQ4vv-fG-Tx3fIHXQhpgL2pAbUhjfqLIHkMARWviL74iQ5gtKJG_hIrGCZOBt1ZP9Yi3s'}).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});